import {Component, inject, OnInit, QueryList, ViewChildren} from '@angular/core';
import {AideSagaService} from "../../services/aide-saga.service";
import {AideSaga} from "../../models/aide-saga";
import {NgbdSortableHeader, SortEvent} from "../../tools/ngbd-sortable-header.directive";
import {map, Observable, startWith} from "rxjs";
import {ToastTool} from "../../tools/toast.tool";
import {EtablissementClient} from "../../models/etablissement-client";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-saga-list',
  templateUrl: './saga-list.component.html',
  styleUrls: ['./saga-list.component.css']
})
export class SagaListComponent implements OnInit{

  private aideSagaService = inject(AideSagaService);
  aideSaga : Array<AideSaga> = [];
  aideSaga$ : Observable<AideSaga[]>;
  pageSize = 15;
  page = 5;
  filter = new FormControl('', {nonNullable: true});

  codeEtat=3

  loading = false;

  ngOnInit(): void {
    this.loadAideSaga();
  }

  search(text: string): AideSaga[] {
    return this.aideSaga.filter((aide) => {
      const term = text.toLowerCase();
      return (
        aide.idSaga.toString().includes(term) ||
        aide.nom.toLowerCase().includes(term) ||
        aide.prenom?.toLowerCase().includes(term) ||
        aide.crous.libelle?.toLowerCase().includes(term) ||
        aide.ine?.toLowerCase().includes(term) ||
        aide.montant?.toString().includes(term)
      );
    });
  }

  loadAideSaga() {
    this.loading = true;
    this.aideSagaService.getAides(this.codeEtat).subscribe({
      next: (data) => {
        this.aideSaga = data;

        this.filter.reset();
        this.aideSaga$ = this.filter.valueChanges.pipe(
          startWith(''),
          map((text) => this.search(text)),
        );
        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
        for (let error of err.error) {
          let description = error.error_description;
          ToastTool.danger(description);
        }
      }
    })
  }



  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    for (const header of this.headers) {
      if (header.sortable !== column) {
        header.direction = '';
      }
    }

    if (direction === '' || column === '') {
      this.loadAideSaga();
    } else {
      this.aideSaga$ = this.aideSaga$.pipe(map(arr => arr.sort((a, b) => {
        console.log(column)
        let res;
        if (column == 'crous.libelle') {
          res = NgbdSortableHeader.compare(a.crous ? a.crous.libelle : '', b.crous ? b.crous.libelle : '');
        } else {
          res = NgbdSortableHeader.compare(a[column], b[column]);
        }
        return direction === 'asc' ? res : -res;
      })));
    }
  }

  changeEtat() {
    this.loadAideSaga();
  }
}
