<div class="loading" [hidden]="!loading"></div>
<div class="mb-3 row">
  <form class="col-xs-3 col-sm-auto">
    <div class="filtre-rne mb-3 row">
      <label for="table-filtering-search" class="col-xs-3 col-sm-auto col-form-label">Recherche :</label>
      <div class="col-xs-3 col-sm-auto">
        <input id="table-filtering-search" type="text"  class="form-control" [formControl]="filter"/>
      </div>
    </div>
  </form>
</div>

<table class="table table-striped table-bordered table-hover">
  <thead>
  <tr>
    <th scope="col" sortable="idDemandeAyantDroit" (sort)="onSort($event)" >Id</th>
    <th scope="col" sortable="etablissementClient" (sort)="onSort($event)">Nom</th>
    <th scope="col" sortable="dateInsertion" (sort)="onSort($event)">Date Création</th>
    <th scope="col" sortable="dateDemande" (sort)="onSort($event)">Date dernier envoi</th>
    <th scope="col" sortable="demandeEtat" (sort)="onSort($event)">Etat</th>
    <th scope="col" sortable="idDemandeAyantDroitEnfant" (sort)="onSort($event)">Demande enfant</th>
    <th scope="col">Action</th>
  </tr>
  </thead>
  <tbody *ngIf="listeDemandeAyantDroit">
  <tr *ngFor="let demandeAyantDroit of listeDemandeAyantDroit$ | async | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
    <td><ngb-highlight [result]="demandeAyantDroit.idDemandeAyantDroit.toString()" [term]="filter.value"></ngb-highlight></td>
    <td><ngb-highlight [result]="demandeAyantDroit.etablissementClient.nom" [term]="filter.value"></ngb-highlight></td>
    <td><ngb-highlight [result]="demandeAyantDroit.dateInsertion | date : 'dd/MM/YYYY hh:mm:ss'" [term]="filter.value"></ngb-highlight></td>
    <td><ngb-highlight [result]="demandeAyantDroit.dateDemande | date : 'dd/MM/YYYY hh:mm:ss'" [term]="filter.value"></ngb-highlight></td>
    <td><ngb-highlight [result]="demandeAyantDroit.demandeEtat.libelle" [term]="filter.value"></ngb-highlight></td>
    <td><ngb-highlight [result]="(demandeAyantDroit.idDemandeAyantDroitEnfant ? demandeAyantDroit.idDemandeAyantDroitEnfant.toString() : '0')" [term]="filter.value"></ngb-highlight></td>
    <td>
      <a class="btn btn-success" (click)="voirSupports(demandeAyantDroit)"> Voir </a>
      &nbsp;
    </td>
  </tr>
  </tbody>
</table>
<div class="align-content-center">
  <ngb-pagination [pageSize]="pageSize" [collectionSize]="(listeDemandeAyantDroit$ | async) ? (listeDemandeAyantDroit$ | async).length : listeDemandeAyantDroit.length" [(page)]="page" aria-label="Default pagination"></ngb-pagination>
</div>
