import { Component } from '@angular/core';

@Component({
  selector: 'app-search-from-izly',
  templateUrl: './search-from-izly.component.html',
  styleUrls: ['./search-from-izly.component.css']
})
export class SearchFromIzlyComponent {

}
