<div class="container-fluid mt-3">
  <h4>Annonces</h4>
  <div class="mb-3 row">
    <div class="rne-actions col-xs-3 col-sm-auto">
      <button class="btn btn-primary" [routerLink]="['edit']">Ajouter</button>
    </div>
    <form class="col-xs-3 col-sm-auto">
      <div class="filtre-rne mb-3 row">
        <label for="table-filtering-search" class="col-xs-3 col-sm-auto col-form-label">Recherche multi-critères :</label>
        <div class="col-xs-3 col-sm-auto">
          <input id="table-filtering-search" type="text"  class="form-control" [formControl]="filter"/>
        </div>
      </div>
    </form>
  </div>

  <table class="table table-striped table-bordered table-hover">
    <thead>
    <tr>
      <th scope="col" sortable="id" (sort)="onSort($event)">#</th>
      <th scope="col" sortable="sujet" (sort)="onSort($event)">Nom</th>
      <th scope="col" sortable="description" (sort)="onSort($event)">Description</th>
      <th scope="col" sortable="dateDebut" (sort)="onSort($event)">Début</th>
      <th scope="col" sortable="dateFin" (sort)="onSort($event)">Fin</th>
      <th scope="col" sortable="label" (sort)="onSort($event)">Label</th>
      <th scope="col" sortable="poids" (sort)="onSort($event)">Poids</th>
      <th scope="col" sortable="user" (sort)="onSort($event)">Utilisateur</th>
      <th scope="col" sortable="actif" (sort)="onSort($event)">Actif</th>
      <th scope="col">&nbsp;</th>

    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of list$ | async | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
      <td>{{item.idInfo}}</td>
      <td><ngb-highlight [result]="item.sujet" [term]="filter.value"></ngb-highlight></td>
      <td><ngb-highlight [result]="item.description" [term]="filter.value"></ngb-highlight></td>
      <td><ngb-highlight [result]="cleanDate(item.dateDebut) | date : 'dd/MM/yyyy'" [term]="filter.value"></ngb-highlight></td>
      <td><ngb-highlight [result]="cleanDate(item.dateFin) | date : 'dd/MM/yyyy'" [term]="filter.value"></ngb-highlight></td>
      <td><ngb-highlight [result]="item.importance | label" [term]="filter.value"></ngb-highlight></td>
      <td><ngb-highlight [result]="item.poid+''" [term]="filter.value"></ngb-highlight></td>
      <td><ngb-highlight [result]="item.utilisateur.nom" [term]="filter.value"></ngb-highlight></td>
      <td>{{item.actif | booleanToOuiNon}}</td>
      <td><a class="btn btn-warning" (click)="modifier(item)"> Modifier </a></td>
    </tr>
    </tbody>
  </table>
  <div class="align-content-center">
    <ngb-pagination [pageSize]="pageSize" [collectionSize]="(list$ | async) ? (list$ | async).length : list.length" [(page)]="page" aria-label="Default pagination"></ngb-pagination>
  </div>


</div>
