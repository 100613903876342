import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Crous} from '../models/crous';
import {Observable} from "rxjs";
import {CrousPut} from "../models/crous-put";
import {HttpService} from "./http.service";
import {Tarification} from "../models/tarification";


@Injectable()
export class CrousService {


  constructor(private http: HttpClient,
              private httpService: HttpService) {
  }

  listCrousAutorises() {
    return this.http.get<Array<Crous>>(environment.apiB4Url + '/v1/crous', { withCredentials: true });
  }

  getXpollensDate(){
    return this.http.get<any>(environment.apiB4Url + '/v1/xpollens/alive',{ withCredentials: true });
  }

  getListeAllCrous(fields: string): Observable<Crous[]> {
    const params = this.httpService.buildParams({fields: fields})
    return this.http.get<Array<Crous>>(environment.apiB4Url + '/v1/listecrous', {params, withCredentials: true});
  }

  getTarifCrous(fields: string, crous: Crous): Observable<Tarification[]> {
    const params = this.httpService.buildParams({fields: fields})
    return this.http.get<Tarification[]>(environment.apiB4Url + '/v1/crous/' + crous.idCrous + '/tarifs', { params, withCredentials: true });
  }

  putCrous(crous: Crous): Observable<void> {
    return this.http.put<void>(environment.apiB4Url + '/v1/crous/' + crous.idCrous, new CrousPut(crous), { withCredentials: true });
  }

}
