import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Me} from '../models/me';
import {Eppn} from "../models/eppn";
import {EtablissementClient} from "../models/etablissement-client";
import {UtilisateurB4} from "../models/utilisateur-b4";
import {Droit} from "../models/droit";
import {UntypedFormGroup} from "@angular/forms";
import {Crous} from "../models/crous";
import {UserB4Datatable} from "../models/user-b4-datatable";
import {Observable} from "rxjs";



@Injectable()
export class UserService {


  utilisateurCourant: Me = null;

  constructor(private http: HttpClient) {
  }

  me(version = undefined) : Observable<Me> {
    let v = '';
    if (version) {
      v = '?v=' + version;
    }
    return this.http.get<Me>(environment.apiB4Url + '/v1/me' + v, { withCredentials: true });
  }

  getEppn(){
    return this.http.get<Array<Eppn>>(environment.apiB4Url + '/v1/utilisateurs/eppn', { withCredentials: true });
  }

  getEtablissement() {
    return this.http.get<Array<EtablissementClient>>(environment.apiB4Url + '/v1/utilisateurs/etablissements', { withCredentials: true });
  }

  getListCrousDeleguation() {
    return this.http.get<Array<Crous>>(environment.apiB4Url + '/v1/utilisateurs/crous-delegation', { withCredentials: true });
  }

  getUtilisateursB4(start, text, column, direction) {
    return this.http.get<UserB4Datatable>(environment.apiB4Url + '/v1/utilisateurs?start=' + start + '&search=' + text + '&column=' + column + '&direction=' + direction, { withCredentials: true });
  }

  getDemandeHabilitation(start, text, column, direction) {
    return this.http.get<UserB4Datatable>(environment.apiB4Url + '/v1/utilisateurs/demandes?start=' + start + '&search=' + text + '&column=' + column + '&direction=' + direction, { withCredentials: true });
  }

  getUtilisateursB4Xls(seachText: string) {
    return this.http
      .get(environment.apiB4Url + '/v1/utilisateurs/xlsx?search=' + seachText,
        { headers: new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
          , observe: 'response'
          , withCredentials: true
          , responseType: 'blob' })
  }

  getDroits(){
    return this.http.get<Array<Droit>>(environment.apiB4Url + '/v1/utilisateurs/droits', { withCredentials: true });
  }

  post(user: UntypedFormGroup) {
    return this.http.post(environment.apiB4Url + '/v1/utilisateurs', user, { withCredentials: true });
  }

  put(user: UntypedFormGroup, id: number) {
    return this.http.put(environment.apiB4Url + '/v1/utilisateurs/' + id, user, { withCredentials: true });
  }

  getUtilisateur(id: any) {
    return this.http.get<UtilisateurB4>(environment.apiB4Url + '/v1/utilisateurs/'+id, { withCredentials: true });

  }

  delete(idUtilisateur: number) {
    return this.http.delete(environment.apiB4Url + '/v1/utilisateurs/'+idUtilisateur, { withCredentials: true });

  }

  saveToken(idToken: any) {
    return this.http.post(environment.apiB4Url + '/v1/utilisateurs/idToken', {'idToken': idToken }, { withCredentials: true });
  }
}
