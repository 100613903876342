import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {Eppn} from "../../models/eppn";
import {map, Observable, startWith} from "rxjs";
import {FormControl} from "@angular/forms";
import {UserService} from "../../services/user.service";
import {ToastTool} from "../../tools/toast.tool";
import {NgbdSortableHeader, SortEvent} from "../../tools/ngbd-sortable-header.directive";
import {EtablissementClient} from "../../models/etablissement-client";

@Component({
  selector: 'app-utilisateur-etablissements',
  templateUrl: './utilisateur-etablissements.component.html',
  styleUrls: ['./utilisateur-etablissements.component.css']
})
export class UtilisateurEtablissementsComponent implements OnInit {

  list: Array<EtablissementClient> = [];
  list$ : Observable<EtablissementClient[]>;
  pageSize = 15;
  page = 5;
  filter = new FormControl('', {nonNullable: true});


  constructor(private userService: UserService) {
  }

  ngOnInit(): void {
    this.loadItem()
  }

  search(text: string): EtablissementClient[] {
    return this.list.filter((item) => {
      const term = text.toLowerCase();
      return (
        item?.nom?.toLowerCase().includes(term) || item?.rneInList?.toLowerCase().includes(term)
      );
    });
  }

  private loadItem() {
    this.userService.getEtablissement().subscribe({next: res => {
        this.list = res;
        this.filter.reset();
        this.list$ = this.filter.valueChanges.pipe(
          startWith(''),
          map((text) => this.search(text)),
        );
      },error: (err) => {
        ToastTool.dangerReponseApi(err);
      }})
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    for (const header of this.headers) {
      if (header.sortable !== column) {
        header.direction = '';
      }
    }

    if (direction === '' || column === '') {
      this.loadItem();
    } else {
      this.list$ = this.list$.pipe(map(arr => arr.sort((a, b) => {
        console.log(column)
        let res;
        if (column == 'crous.libelle') {
          res = NgbdSortableHeader.compare(a.crous ? a.crous.libelle : '', b.crous ? b.crous.libelle : '');
        } else {
          res = NgbdSortableHeader.compare(a[column], b[column]);
        }
        return direction === 'asc' ? res : -res;
      })));
    }
  }

}
