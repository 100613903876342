<div class="loading" [hidden]="!loading"></div>
<form (submit)="save()" [formGroup]="form">
  <div class="modal-header">
    <h4 *ngIf="etablissementSelectionne?.idEtablissementClient" class="modal-title" id="modal-modif-title">Modification du RNE {{ etablissementSelectionne.nom }}</h4>
    <h4 *ngIf="!etablissementSelectionne?.idEtablissementClient" class="modal-title" id="modal-ajout-title">Ajout d'un nouvel Etablissement</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <ul ngbNav (navChange)="onNavChange($event)" #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
      <li [ngbNavItem]="1">
        <button ngbNavLink>Fiche établissement</button>
        <ng-template ngbNavContent>
          <div class="mb-3">
            <label for="nom">Nom *</label>
            <input formControlName="nom" id="nom" type="text" maxlength="255" class="form-control" name="nom" required/>
            <label for="adresse">Adresse</label>
            <input formControlName="adresse" id="adresse" maxlength="1000" type="text" class="form-control" name="adresse" />
            <label for="crous">Crous *</label>
            <select  class="form-select form-control" formControlName="crous" id="crous" name="crous" required>
              <option *ngFor="let crous of listeCrous" [value]="toStr(crous)">{{ crous.libelle }}</option>
            </select>
          </div>
          <div class="d-flex flex-row-reverse">
            <button [disabled]="!form.valid || !form.dirty" type="submit" class="p-2 btn btn-primary">Enregistrer</button>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="2" [hidden]="!etablissementSelectionne.idEtablissementClient">
        <button ngbNavLink>RNE</button>
        <ng-template ngbNavContent>
          <div>
            <table class="table">
              <thead>
              <tr>
                <th scope="col">UIARNE</th>
                <th scope="col">Nom</th>
                <th scope="col">Ville</th>
                <th scope="col">Action</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let bce of listeBce">
                <td>{{ bce.numeroUai }}</td>
                <td>{{ bce.denominationPrincipale }}</td>
                <td>{{ bce.commune?.libelle }}</td>
                <td><button type="button" (click)="deleteRneFromEtablissement(bce)" class="toast-bottom-full-width btn btn-danger">Supprimer</button></td>
              </tr>
              </tbody>
            </table>
            <div class="d-flex">
              <button class="toast-bottom-full-width btn btn-primary" (click)="openRneWindows(content)" type="button">Ajouter</button>
              <ng-template #content let-modal>
                <div class="loading" [hidden]="!loading"></div>
                <div class="modal-header">
                  <h4>Affectation d'un RNE</h4>
                </div>
                <div class="modal-content">
                  <form [formGroup]="searchRneForm" class="form-group" #formElement> <label for="rne">Rechercher un RNE : </label>
                    <div class="input-group">
                      <input
                        formControlName="rne"
                        [resultTemplate]="rt"
                        [ngbTypeahead]="search"
                        [resultFormatter]="formatter"
                        [inputFormatter]="formatter" type="text" id="rne" class="form-control"/>
                      <button type="button" class="btn btn-outline-warning " (click)="formElement.reset()" >
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M290.7 57.4L57.4 290.7c-25 25-25 65.5 0 90.5l80 80c12 12 28.3 18.7 45.3 18.7H288h9.4H512c17.7 0 32-14.3 32-32s-14.3-32-32-32H387.9L518.6 285.3c25-25 25-65.5 0-90.5L381.3 57.4c-25-25-65.5-25-90.5 0zM297.4 416H288l-105.4 0-80-80L227.3 211.3 364.7 348.7 297.4 416z"/></svg>
                      </button>
                      <ng-template let-r="result" let-t="term" #rt>
                        <div><span>(<ngb-highlight [result]="r.numeroUai" [term]="t"></ngb-highlight>) <ngb-highlight [result]="r.denominationPrincipale" [term]="t"></ngb-highlight></span></div>
                        <div><span style="font-size:14px"><ngb-highlight *ngIf="r.appellationOfficielle !== 'A COMPLETER'" [result]="r.appellationOfficielle" [term]="t"></ngb-highlight> (<ngb-highlight [result]="r.commune.libelle" [term]="t"></ngb-highlight>)</span></div>
                      </ng-template>
                    </div>
                  </form>
                </div>
                <div class="modal-footer">
                  <button  type="button" (click)="addRne()" class=" btn btn-outline-secondary">Ajouter</button>
                  <button type="button" (click)="modal.close()" class=" btn btn-outline-secondary">Fermer</button>
                </div>
              </ng-template>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="3" [hidden]="!etablissementSelectionne.idEtablissementClient">
        <button ngbNavLink>Tarifs</button>
        <ng-template ngbNavContent>
          <div>
            <span [hidden]="listeTarif.length !== 0">Pas de tarifs pour cet établissement</span>
            <table class="table" [hidden]="listeTarif.length === 0">
              <thead>
              <tr>
                <th scope="col">Status</th>
                <th scope="col">Indice mini</th>
                <th scope="col">Indice maxi</th>
                <th scope="col">Code société</th>
                <th scope="col">Code tarif</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let tarif of listeTarif">
                <td>{{ tarif.tarificationStatut?.libelle }}</td>
                <td>{{ tarif.indiceMin }}</td>
                <td>{{ tarif.indiceMax }}</td>
                <td>{{ tarif.codeSociete }}</td>
                <td>{{ tarif.codeTarif }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
</form>
