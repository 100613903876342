import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Batch} from "../../../models/batch";
import {ToastTool} from "../../../tools/toast.tool";
import {HttpErrorResponse} from "@angular/common/http";
import {TaskService} from "../../../services/task.service";
import {NgbActiveModal, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {BatchType} from "../../../models/batch-type";
import {EmetteurZdc} from "../../../models/emetteur-zdc";
import {BatchFtpType} from "../../../models/batch-ftp-type";
import {Utils} from "../../../tools/Utils";
import {Crous} from "../../../models/crous";
import {CrousService} from "../../../services/crous.service";

@Component({
  selector: 'app-dialog-form-task',
  templateUrl: './dialog-form-task.component.html',
  styleUrls: ['./dialog-form-task.component.css']
})
export class DialogFormTaskComponent implements OnInit{

  @Input()  task: Batch;
  @Output() taskModifie: EventEmitter<Batch> = new EventEmitter<Batch>();

  idBatchType = 0;
  idBatchFtpType = 0;
  typeFTP = 'FTP';

  batchTypeList:Array<BatchType> = [];
  batchFtpTypeList:Array<BatchFtpType> = [];

  listeCrous: Crous[] = [];

  form: UntypedFormGroup;


  constructor(private fb: UntypedFormBuilder, public activeModal: NgbActiveModal,
              private taskService: TaskService, private crousService: CrousService){

  }

  ngOnInit(): void {
      this.loadListeCrous();
      this.loadListFtpType();
      this.createForm();
  }

  loadListeCrous() {
    this.crousService.listCrousAutorises().subscribe({
      next: (data) => {
        this.listeCrous = data;
      }
    });
  }

  loadListFtpType(){
    this.taskService.getFtpTypeList().subscribe({
      next: l => this.batchFtpTypeList = l,
      error: (err: HttpErrorResponse) => {
        for (let error of err.error) {
          let description = error.error_description;
          ToastTool.danger(description);
        }
      }
    })
  }

  compareBatchType(item1: BatchType, item2: BatchType) {
    return (!item1 && !item2) || (item1 && item2 && item1.idBatchType == item2.idBatchType);
  }

  compareBatchFtpType(item1: BatchFtpType, item2: BatchFtpType) {
    return (!item1 && !item2) || (item1 && item2 && item1.idBatchFtpType == item2.idBatchFtpType);
  }

  createForm(){
    if (!this.task) {
      this.task = new Batch();
    }

    console.log(this.task)

    const dateDebutObj = Utils.formatDateToNgDate(this.task.dateDebut);
    const dateFinObj= Utils.formatDateToNgDate(this.task.dateFin);

    this.form = this.fb.group({
      idBatch: this.fb.control(this.task.idBatch),
      batchType: this.fb.control(this.task.batchType, Validators.required),
      description: this.fb.control(this.task.description, Validators.required),
      cronSyntax: this.fb.control(this.task.cronSyntax, Validators.required),
      courrielEnvoiLogReport:this.fb.control(this.task.courrielEnvoiLogReport),
      sendLogReport:this.fb.control(this.task.sendLogReport),
      actif:this.fb.control(this.task.actif),

      batchFtpType:this.fb.control(this.task.batchFtpType),
      importAdresseServeur:this.fb.control(this.task.importAdresseServeur),
      importPort:this.fb.control(this.task.importPort),
      importIdentifiant:this.fb.control(this.task.importIdentifiant),
      importMotDePasse:this.fb.control(this.task.importMotDePasse),
      importChemin:this.fb.control(this.task.importChemin),
      importFichier:this.fb.control(this.task.importFichier),
      useSSHKey:this.fb.control(this.task.useSSHKey),
      typeFTP:this.fb.control(!this.task.typeFTP?'FTP':this.task.typeFTP),

      nombreMoisConservationAideSaga:this.fb.control(this.task.nombreMoisConservationAideSaga),

      dateDebutObj:this.fb.control(dateDebutObj),
      dateFinObj:this.fb.control(dateFinObj),
      nbJour:this.fb.control(this.task.nbJour),

      crous: this.fb.control(this.toStr(this.task.crous)),
    });

    this.typeFTP = this.task.typeFTP;
    this.idBatchFtpType = this.task.batchFtpType?.idBatchFtpType;
  }

  toStr(value: Crous): string {
    return JSON.stringify(value);
  }

  dateOrNbJour() {
    return (this.task.dateDebut && this.task.dateFin) || this.task.nbJour > 0;
  }

  save() {
    this.setValues()
    if (!this.form.valid) {
      Utils.validateAllFormFields(this.form);
      return;
    }
    if ((this.idBatchType === 5 || this.idBatchType === 8) && !this.dateOrNbJour()) {
      ToastTool.danger('NbJour ou dates obligatoire');
      return;
    }

    if (this.task.idBatch) {
      this.modify();
    } else {
      this.add();
    }
  }


  private modify() {

    this.taskService.update(this.task).subscribe({
      next:() => {
        ToastTool.success('Tâche modifiée');
        this.taskModifie.emit();
        this.activeModal.close();
      },
      error: (err: HttpErrorResponse) => {
        for (let error of err.error) {
          let description = error.error_description;
          ToastTool.danger(description);
        }
        this.taskModifie.emit();
      }
    });

  }

  private add() {

    this.taskService.add(this.task).subscribe({
      next:() => {
        ToastTool.success('Tâche crée');
        this.taskModifie.emit();
        this.activeModal.close();
      },
      error: (err: HttpErrorResponse) => {
        for (let error of err.error) {
          let description = error.error_description;
          ToastTool.danger(description);
        }
        this.taskModifie.emit();
      }
    });

  }

  setValues() {
    this.task.batchType = this.form.controls['batchType'].value;
    this.task.description = this.form.controls['description'].value;
    this.task.cronSyntax = this.form.controls['cronSyntax'].value;
    this.task.courrielEnvoiLogReport = this.form.controls['courrielEnvoiLogReport'].value;
    this.task.sendLogReport = !!this.form.controls['sendLogReport'].value;
    this.task.actif = !!this.form.controls['actif'].value;
    this.task.batchFtpType = this.form.controls['batchFtpType'].value;
    this.task.importAdresseServeur = this.form.controls['importAdresseServeur'].value;
    this.task.importPort = this.form.controls['importPort'].value;
    this.task.importIdentifiant = this.form.controls['importIdentifiant'].value;
    this.task.importMotDePasse = this.form.controls['importMotDePasse'].value;
    this.task.importChemin = this.form.controls['importChemin'].value;
    this.task.importFichier = this.form.controls['importFichier'].value;
    this.task.useSSHKey = this.form.controls['useSSHKey'].value;
    this.task.typeFTP = this.form.controls['typeFTP'].value;
    this.task.nombreMoisConservationAideSaga = this.form.controls['nombreMoisConservationAideSaga'].value;

    this.task.dateDebut = Utils.formatNgDateToUsDate(this.form.controls['dateDebutObj'].value);
    this.task.dateFin = Utils.formatNgDateToUsDate(this.form.controls['dateFinObj'].value);
    this.task.nbJour = this.form.controls['nbJour'].value;
    this.task.crous = JSON.parse(this.form.controls['crous'].value);
  }

  changeBatchType() {

    this.form.controls['batchFtpType'].clearValidators();
    this.form.controls['batchFtpType'].updateValueAndValidity();
    this.form.controls['importAdresseServeur'].clearValidators();
    this.form.controls['importPort'].clearValidators();
    this.form.controls['importIdentifiant'].clearValidators();
    this.form.controls['importMotDePasse'].clearValidators();
    this.form.controls['importChemin'].clearValidators();
    this.form.controls['nombreMoisConservationAideSaga'].clearValidators();
    this.form.controls['dateDebutObj'].clearValidators();
    this.form.controls['dateFinObj'].clearValidators();
    this.form.controls['nbJour'].clearValidators();
    this.form.controls['importFichier'].clearValidators();
    this.form.controls['typeFTP'].clearValidators();
    this.form.controls['importAdresseServeur'].updateValueAndValidity();
    this.form.controls['importPort'].updateValueAndValidity();
    this.form.controls['importIdentifiant'].updateValueAndValidity();
    this.form.controls['importMotDePasse'].updateValueAndValidity();
    this.form.controls['importChemin'].updateValueAndValidity();
    this.form.controls['nombreMoisConservationAideSaga'].updateValueAndValidity();
    this.form.controls['dateDebutObj'].updateValueAndValidity();
    this.form.controls['dateFinObj'].updateValueAndValidity();
    this.form.controls['nbJour'].updateValueAndValidity();
    this.form.controls['importFichier'].updateValueAndValidity();
    this.form.controls['typeFTP'].updateValueAndValidity();
    this.form.controls['crous'].updateValueAndValidity();

    this.idBatchType = this.form.controls['batchType'].value.idBatchType;
    if (this.idBatchType === 1) {
      this.form.controls['batchFtpType'].setValidators([Validators.required]);
      this.form.controls['importAdresseServeur'].setValidators([Validators.required]);
      this.form.controls['importPort'].setValidators([Validators.required]);
      this.form.controls['importIdentifiant'].setValidators([Validators.required]);
      this.form.controls['importMotDePasse'].setValidators([Validators.required]);
      this.form.controls['importChemin'].setValidators([Validators.required]);
      this.form.controls['importFichier'].setValidators([Validators.required]);
      this.form.controls['typeFTP'].setValidators([Validators.required]);
    }

    if (this.idBatchType === 11) {
      this.form.controls['nombreMoisConservationAideSaga'].setValidators([Validators.required]);
    }

  }

  selectTypeFTP() {
    this.typeFTP = this.form.controls['typeFTP'].value;
  }

  changeBatchFTPType() {
    this.idBatchFtpType = this.form.controls['batchFtpType'].value.idBatchFtpType;

    this.form.controls['batchFtpType'].clearValidators();
    this.form.controls['importAdresseServeur'].clearValidators();
    this.form.controls['importPort'].clearValidators();
    this.form.controls['importIdentifiant'].clearValidators();
    this.form.controls['importMotDePasse'].clearValidators();
    this.form.controls['importChemin'].clearValidators();
    this.form.controls['importFichier'].clearValidators();
    this.form.controls['typeFTP'].clearValidators();
    this.form.controls['crous'].clearValidators();

    if (this.idBatchFtpType==9 || this.idBatchFtpType == 11 || this.idBatchFtpType==13) {
      this.form.controls['importFichier'].clearValidators();
      this.form.controls['typeFTP'].clearValidators();
      this.form.controls['importAdresseServeur'].setValidators([Validators.required]);
      this.form.controls['importPort'].setValidators([Validators.required]);
      this.form.controls['importIdentifiant'].setValidators([Validators.required]);
      this.form.controls['importMotDePasse'].setValidators([Validators.required]);
      this.form.controls['importChemin'].setValidators([Validators.required]);
      if (this.idBatchType === 13) {
        this.form.controls['crous'].setValidators([Validators.required]);
      }
    } else {
      this.form.controls['importFichier'].setValidators([Validators.required]);
      this.form.controls['typeFTP'].setValidators([Validators.required]);
      this.form.controls['importAdresseServeur'].setValidators([Validators.required]);
      this.form.controls['importPort'].setValidators([Validators.required]);
      this.form.controls['importIdentifiant'].setValidators([Validators.required]);
      this.form.controls['importMotDePasse'].setValidators([Validators.required]);
      this.form.controls['importChemin'].setValidators([Validators.required]);
    }

    this.form.controls['batchFtpType'].updateValueAndValidity();
    this.form.controls['importAdresseServeur'].updateValueAndValidity();
    this.form.controls['importPort'].updateValueAndValidity();
    this.form.controls['importIdentifiant'].updateValueAndValidity();
    this.form.controls['importMotDePasse'].updateValueAndValidity();
    this.form.controls['importChemin'].updateValueAndValidity();
    this.form.controls['importFichier'].updateValueAndValidity();
    this.form.controls['typeFTP'].updateValueAndValidity();
    this.form.controls['crous'].updateValueAndValidity();

  }
}
