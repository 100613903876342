import {Component, OnInit} from '@angular/core';
import {FormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {DateFormatPipe} from "../../../tools/pipe/date-format.pipe";
import {NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n} from "@ng-bootstrap/ng-bootstrap";
import {NgbDateNativeAdapter} from "../../../tools/datepicker-adapter";
import {NgbDateFRParserFormatter} from "../../../tools/ngb-date-fr-parser-formatter";
import {Utils} from "../../../tools/Utils";
import {AnnonceService} from "../../../services/annonce.service";
import {ToastTool} from "../../../tools/toast.tool";
import {NgxSpinnerService} from "ngx-spinner";
import {ActivatedRoute, Router} from "@angular/router";
import {Annonce} from "../../../models/annonce";

@Component({
  selector: 'app-publication-annonce-edit',
  templateUrl: './publication-annonce-edit.component.html',
  styleUrls: ['./publication-annonce-edit.component.css'],
  providers: [
    DateFormatPipe,
    {provide: NgbDateAdapter, useClass: NgbDateNativeAdapter},
    {provide: NgbDateParserFormatter, useFactory: () => {
        return new NgbDateFRParserFormatter();
      }
    }]

})
export class PublicationAnnonceEditComponent implements OnInit{

  action: string = 'Créer';
  form: UntypedFormGroup;
  annonce = new Annonce();

  constructor(private fb: FormBuilder, private annonceService: AnnonceService,
              private router: Router,
              private route: ActivatedRoute,
              private spinner: NgxSpinnerService,) {
  }

  ngOnInit(): void {
    const id = this.route.snapshot.params['id'];
    if (id) {
      this.action='Modifier';
      this.loadAnnonce(id);
    } else {
      this.loadForm()
    }
  }


  loadForm() {
    this.form = this.fb.group({
      sujet: this.fb.control<string>(this.annonce.sujet, [Validators.required, Validators.minLength(10), Validators.maxLength(255)]),
      label: this.fb.control<number>(this.annonce.importance ? this.annonce.importance : 1),
      description: this.fb.control<string>(this.annonce.description, [Validators.required, Validators.minLength(20)]),
      dateDebut   : this.fb.control<Date|null>(this.annonce.dateDebut, Validators.required),
      dateFin   : this.fb.control<Date|null>(this.annonce.dateFin, Validators.required),
      poid   : this.fb.control<number>(this.annonce.poid ? this.annonce.poid : 1),
      actif   : this.fb.control<boolean>(this.annonce.actif),
    });
  }

  save() {

    if (!this.form.valid) {
      Utils.validateAllFormFields(this.form);
      return;
    }
    const id = this.route.snapshot.params['id'];
    this.spinner.show();
    if (id) {
      this.update(id)
    } else {
      this.insert()
    }

  }

  insert(){
    this.annonceService.post(this.form.getRawValue()).subscribe({next:res=>{
        ToastTool.success("L'annonce a été enregistrée")
        this.spinner.hide();
        this.router.navigate(['parametres','annonces'])
      }, error: err => {
        console.log(err)
        this.spinner.hide();
        ToastTool.dangerReponseApi(err);
      }})
  }

  update(id){
    this.annonceService.put(id, this.form.getRawValue()).subscribe({next:res=>{
        ToastTool.success("L'annonce a été enregistrée")
        this.spinner.hide();
        this.router.navigate(['parametres','annonces'])
      }, error: err => {
        console.log(err)
        this.spinner.hide();
        ToastTool.dangerReponseApi(err);
      }})
  }


  private loadAnnonce(id: any) {

    this.annonceService.get(id).subscribe({next:res=>{
        this.annonce = res;
        this.loadForm()
        this.spinner.hide();
      }, error: err => {
        console.log(err)
        this.spinner.hide();
        ToastTool.dangerReponseApi(err);
      }})
  }
}
