import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {debounceTime, map, Observable, startWith} from "rxjs";
import {FormControl} from "@angular/forms";
import {UserService} from "../../services/user.service";
import {ToastTool} from "../../tools/toast.tool";
import {NgbdSortableHeader, SortEvent} from "../../tools/ngbd-sortable-header.directive";
import {UtilisateurB4} from "../../models/utilisateur-b4";
import {Router} from "@angular/router";
import {ModalAlertComponent} from "../../tools/modal-alert/modal-alert.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Me} from "../../models/me";

@Component({
  selector: 'app-utilisateur-inactif-list',
  templateUrl: './utilisateur-inactif-list.component.html',
  styleUrls: ['./utilisateur-inactif-list.component.css']
})
export class UtilisateurInactifListComponent implements OnInit {

  list: Array<UtilisateurB4> = [];
  page = 1;
  pageSize = 15;
  total;
  filter = new FormControl('', {nonNullable: true});

  me: Me;

  constructor(private userService: UserService, private modalService: NgbModal,
              private router: Router) {
  }

  ngOnInit(): void {

    this.userService.me().subscribe(me => this.me = me);

    this.filter.valueChanges.pipe(
      debounceTime(300),
      startWith(''),
    ).subscribe(text => {
      this.loadItem(text);
    });
  }

  private loadItem(text='', column='nom', direction='asc') {
    const start = (this.page - 1) * this.pageSize;
    this.userService.getDemandeHabilitation(start, text, column, direction).subscribe({next: res => {
        this.total = res.total;
        this.list = res.data;
      },error: (err) => {
        ToastTool.dangerReponseApi(err);
      }})
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    for (const header of this.headers) {
      if (header.sortable !== column) {
        header.direction = '';
      }
    }
    this.loadItem(this.filter.getRawValue(), column, direction);

  }


  modifier(item: UtilisateurB4) {
    this.router.navigate(['utilisateurs','edit',item.idUtilisateur]);
  }

  supprimer(item: UtilisateurB4) {
    const modal = this.modalService.open(ModalAlertComponent, {backdrop: 'static'})
    modal.componentInstance.title = 'Suppression d\'un utilisateur';
    modal.componentInstance.messageP1 = 'Vous allez supprimer un utilisateur';
    modal.componentInstance.messageP1Red = true;
    modal.componentInstance.messageP2 = 'Êtes vous sur ?';
    modal.componentInstance.messageP2Bold = true;
    modal.result.then(
      value => {
        this.userService.delete(item.idUtilisateur).subscribe({next: res => {
            ToastTool.success('Utilisateur supprimé');
            this.loadItem();
          },error: (err) => {
            ToastTool.dangerReponseApi(err);
          }})
      }
    ).catch(r => {
    });
  }

  seachUser($event: number) {
    this.page=$event;
    this.loadItem();

  }

  protected readonly Me = Me;
}
