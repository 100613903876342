import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { MenuComponent } from './menu/menu.component';
import {HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withInterceptors} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UserService} from './services/user.service';
import {CrousService} from './services/crous.service';
import { SearchAyantDroitComponent } from './ayant-droit/search-ayant-droit/search-ayant-droit.component';
import {ROUTES} from './app.routes';
import {EtablissementService} from './services/etablissement.service';
import {MenuService} from './services/menu.service';
import { DatatableAyantDroitComponent } from './ayant-droit/datatable-ayant-droit/datatable-ayant-droit.component';
import { AyantDroitFormComponent } from './ayant-droit/ayant-droit-form/ayant-droit-form.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {RightholderService} from './services/rightholder.service';
import {DatePipe, HashLocationStrategy, LocationStrategy} from '@angular/common';
import {RneService} from './services/rne.service';
import {AccountStatusPipe} from './tools/pipe/account-status.pipe';
import {BlockingStatusPipe} from './tools/pipe/blocking-status.pipe';
import { DateFormatPipe } from './tools/pipe/date-format.pipe';
import {CounterPipe} from './tools/pipe/counter.pipe';
import {DateHeureFormatPipe} from './tools/pipe/date-heure-format.pipe';
import {SearchAyantDroitFormComponent} from './common/search/form/search-ayant-droit-form.component';
import {SearchErreurInfocartecrousService} from './common/search/search-erreur-infocartecrous.service';
import {SearchAyantDroitService} from './common/search/search-ayant-droit.service';
import {SearchCorrespondanceErreurInfocartecrousService} from './common/search/search-correspondance-erreur-infocartecrous.service';
import {ErrorInterceptor} from './interceptor/error-interceptor';
import { FromIzlyComponent } from './common/search/from-izly/from-izly.component';
import { SearchFromIzlyComponent } from './ayant-droit/search-from-izly/search-from-izly.component';
import { ApiBoursierComponent } from './api-boursier/api-boursier.component';
import {ApiBoursierService} from "./services/api-boursier.service";
import {CookieService} from "ngx-cookie-service";
import { LogsApiComponent } from './logs-api/logs-api.component';
import { FromLogsApiComponent } from './common/search/from-logs-api/from-logs-api.component';
import {SearchApiLogsService} from "./common/search/search-api-logs.service";
import {LogsApiService} from "./services/logs-api.service";
import { DatatableLogsApiComponent } from './logs-api/datatable-logs-api/datatable-logs-api.component';
import {NgSelectModule} from "@ng-select/ng-select";
import { ListeEmetteursComponent } from './admin/emetteurs/liste-emetteurs/liste-emetteurs.component';
import { TableauEmetteursComponent } from './admin/emetteurs/tableau-emetteurs/tableau-emetteurs.component';
import { ListeContratsComponent } from './admin/contrats/liste-contrats/liste-contrats.component';
import { TableauContratsComponent } from './admin/contrats/tableau-contrats/tableau-contrats.component';
import { DialogFormEmetteurComponent } from './admin/emetteurs/dialog-form-emetteur/dialog-form-emetteur.component';
import { FiltreTableauPipe } from './tools/pipe/filtre-tableau.pipe';
import { DialogFormContratComponent } from './admin/contrats/dialog-form-contrat/dialog-form-contrat.component';
import { ListeRneComponent } from './rne/liste-rne/liste-rne.component';
import { TableauRneComponent } from './rne/tableau-rne/tableau-rne.component';
import { DialogFormRneComponent } from './rne/dialog-ajout-rne/dialog-form-rne.component';
import { ListeEtablissementsComponent } from './admin/etablissements/liste-etablissements/liste-etablissements.component';
import { TableauEtablissementsComponent } from './admin/etablissements/tableau-etablissements/tableau-etablissements.component';
import { DialogFormEtablissementComponent } from './admin/etablissements/dialog-form-etablissement/dialog-form-etablissement.component';
import { ListeCrousComponent } from './admin/crous/liste-crous/liste-crous.component';
import { TableauCrousComponent } from './admin/crous/tableau-crous/tableau-crous.component';
import { DialogFormCrousComponent } from './admin/crous/dialog-form-crous/dialog-form-crous.component';
import {NgbdSortableHeader} from "./tools/ngbd-sortable-header.directive";
import { ListeDemandeAyantDroitComponent } from './admin/demande-ayant-droit/liste-demande-ayant-droit/liste-demande-ayant-droit.component';
import { TableauDemandeAyantDroitComponent } from './admin/demande-ayant-droit/tableau-demande-ayant-droit/tableau-demande-ayant-droit.component';
import { DialogDemandeAyantDroitComponent } from './admin/demande-ayant-droit/dialog-demande-ayant-droit/dialog-demande-ayant-droit.component';
import { XpollensApiTestComponent } from './admin/xpollens-api-test/xpollens-api-test.component';
import { TaskListComponent } from './admin/tasks/task-list/task-list.component';
import {AuthGuard} from "./interceptor/auth.guard";
import { AdminComponent } from './admin/admin.component';
import { BooleanToOuiNonPipe } from './tools/pipe/boolean-to-oui-non.pipe';
import { DialogFormTaskComponent } from './admin/tasks/dialog-form-task/dialog-form-task.component';
import {ModalAlertComponent} from "./tools/modal-alert/modal-alert.component";
import { ImportAyantDroitComponent } from './ayant-droit/import-excel/import-ayant-droit/import-ayant-droit.component';
import { SagaListComponent } from './saga/saga-list/saga-list.component';
import { SagaComponent } from './saga/saga.component';
import { SagaAideComponent } from './saga/saga-aide/saga-aide.component';
import { LectureCarteComponent } from './lecture-carte/lecture-carte.component';
import {NgxSpinnerModule} from "ngx-spinner";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { UtilisateurInactifListComponent } from './utilisateurs/utilisateur-inactif-list/utilisateur-inactif-list.component';
import { UtilisateursComponent } from './utilisateurs/utilisateurs.component';
import { UtilisateurEditComponent } from './utilisateurs/utilisateur-edit/utilisateur-edit.component';
import { UtilisateurEtablissementsComponent } from './utilisateurs/utilisateur-etablissements/utilisateur-etablissements.component';
import { UtilisateurListComponent } from './utilisateurs/utilisateur-list/utilisateur-list.component';
import {ClickOutsideDirective, ListFilterPipe, MultiSelectComponent} from "./tools/multiselect";
import { ChangeIdentifiantClientComponent } from './admin/change-identifiant-client/change-identifiant-client.component';
import { PublicationAnnoncesListComponent } from './admin/publication-annonces/publication-annonces-list/publication-annonces-list.component';
import { PublicationAnnonceEditComponent } from './admin/publication-annonces/publication-annonce-edit/publication-annonce-edit.component';
import {LabelPipe} from "./tools/pipe/label.pipe";
import { MonCompteProModalComponent } from './menu/mon-compte-pro-modal/mon-compte-pro-modal.component';
import { AuthModule } from 'angular-auth-oidc-client';
import {authInterceptor} from "./auth/auth.interceptor";
import {authConfig} from "./auth/auth.config";
import { NoHabilitationComponent } from './connection/no-habilitation/no-habilitation.component';
import {HomeComponent} from "./connection/home/home.component";
import { RechercheCarteComponent } from './ayant-droit/recherche-carte/recherche-carte.component';


@NgModule({
  declarations: [
    ListFilterPipe,
    ClickOutsideDirective,
    MultiSelectComponent,
    AppComponent,
    MenuComponent,
    SearchAyantDroitComponent,
    SearchAyantDroitFormComponent,
    DatatableAyantDroitComponent,
    AyantDroitFormComponent,
    AccountStatusPipe,
    BlockingStatusPipe,
    DateFormatPipe,
    DateHeureFormatPipe,
    CounterPipe,
    FromIzlyComponent,
    SearchFromIzlyComponent,
    ApiBoursierComponent,
    LogsApiComponent,
    FromLogsApiComponent,
    DatatableLogsApiComponent,
    ListeEmetteursComponent,
    TableauEmetteursComponent,
    ListeContratsComponent,
    TableauContratsComponent,
    DialogFormEmetteurComponent,
    FiltreTableauPipe,
    DialogFormContratComponent,
    ListeRneComponent,
    TableauRneComponent,
    DialogFormRneComponent,
    ListeEtablissementsComponent,
    TableauEtablissementsComponent,
    DialogFormEtablissementComponent,
    ListeCrousComponent,
    TableauCrousComponent,
    DialogFormCrousComponent,
    ListeDemandeAyantDroitComponent,
    TableauDemandeAyantDroitComponent,
    DialogDemandeAyantDroitComponent,
    XpollensApiTestComponent,
    TaskListComponent,
    AdminComponent,
    BooleanToOuiNonPipe,
    DialogFormTaskComponent,
    ModalAlertComponent,
    ImportAyantDroitComponent,
    SagaListComponent,
    SagaComponent,
    SagaAideComponent,
    LectureCarteComponent,
    UtilisateurInactifListComponent,
    UtilisateursComponent,
    UtilisateurEditComponent,
    UtilisateurEtablissementsComponent,
    UtilisateurListComponent,
    ChangeIdentifiantClientComponent,
    PublicationAnnoncesListComponent,
    PublicationAnnonceEditComponent,
    LabelPipe,
    MonCompteProModalComponent,
    HomeComponent,
    NoHabilitationComponent,
    RechercheCarteComponent
  ],
    imports: [
        NgbModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        RouterModule.forRoot(ROUTES),
        NgSelectModule,
        NgbdSortableHeader,
      NgxSpinnerModule,
      BrowserAnimationsModule,
      AuthModule.forRoot(authConfig),
    ],
  providers: [
    UserService,
    CrousService,
    ListFilterPipe,
    EtablissementService,
    MenuService,
    SearchAyantDroitService,
    SearchApiLogsService,
    SearchErreurInfocartecrousService,
    SearchCorrespondanceErreurInfocartecrousService,
    RightholderService,
    LogsApiService,
    DatePipe,
    RneService,
    ApiBoursierService,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    provideHttpClient(withInterceptors([authInterceptor])),
    CookieService
  ],
  bootstrap: [AppComponent]

})
export class AppModule {
}
