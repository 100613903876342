import {Rne} from "./rne";
import {RneLight} from "./rne-light";
import {BceEtablissement} from "./bce-etablissement";

export class Crous {

  private _idCrous: number;
  private _libelle: string;
  private _numeroCrous: number;
  private _rneLight: RneLight;
  private _rne: Rne;
  private _bceEtablissement: BceEtablissement;


  get idCrous(): number {
    return this._idCrous;
  }

  set idCrous(value: number) {
    this._idCrous = value;
  }

  get libelle(): string {
    return this._libelle;
  }

  set libelle(value: string) {
    this._libelle = value;
  }

  get numeroCrous(): number {
    return this._numeroCrous;
  }

  set numeroCrous(value: number) {
    this._numeroCrous = value;
  }

  get rneLight(): RneLight {
    return this._rneLight;
  }

  set rneLight(value: RneLight) {
    this._rneLight = value;
  }


  get rne(): Rne {
    return this._rne;
  }

  set rne(value: Rne) {
    this._rne = value;
  }


  get bceEtablissement() : BceEtablissement {
    return this._bceEtablissement;
  }

  set bceEtablissement(value: BceEtablissement) {
    this._bceEtablissement = value;
  }
}
