import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ToastTool} from "../../tools/toast.tool";
import {AideSagaService} from "../../services/aide-saga.service";
import {AideSaga} from "../../models/aide-saga";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {LectureCarteComponent} from "../../lecture-carte/lecture-carte.component";
import {AyantDroit} from "../../models/ayant-droit";
import {Zdc} from "../../models/zdc";
import {RightholderService} from "../../services/rightholder.service";
import {Rightholder} from "../../models/rightholder";
import {SmartCard} from "../../models/smart-card";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-saga-aide',
  templateUrl: './saga-aide.component.html',
  styleUrls: ['./saga-aide.component.css']
})
export class SagaAideComponent implements OnInit {

  aide: AideSaga;
  zdc: Zdc = new Zdc();
  ayantDroit: Rightholder;
  ayantDroitInconnu = false;

  constructor(private route: ActivatedRoute, private modalService: NgbModal,
              private spinner: NgxSpinnerService,
              private rightholderService: RightholderService,
              private aideSagaService: AideSagaService) {
  }

  ngOnInit(): void {

    const id = this.route.snapshot.params['id'];
    this.loadAide(id)
  }




  loading = false;

  loadAide(idAide){
    this.aideSagaService.getAide(idAide).subscribe({next:res=> {
        this.aide = res;
      }, error: err => {
          ToastTool.dangerReponseApi(err);
      }
    })

  }

  lectureCarte() {
      this.ayantDroitInconnu = false;
      const modal = this.modalService.open(LectureCarteComponent, {size:'xl'});
      const ad = new AyantDroit();
      ad.nom = this.aide.nom;
      ad.prenom = this.aide.prenom;
      modal.componentInstance.ayantDroit = ad;
      modal.componentInstance.buttonLabel = 'Valider la carte et rechercher l\'ayant droit';
      modal.result.then(res =>{
        this.ayantDroit = null;
        if (!res || !res.identifiantCnous || !res.uid) {
          ToastTool.danger("carte non lue");
          return;
        }
        this.zdc = res;
      });

  }

  creditAide() {
    if (!this.zdc.uid || !this.zdc.identifiantCnous) {
      ToastTool.danger("L'identifiant Cnous et le numero applicatif sont obligatoires")
      return
    }

    this.spinner.show();
    this.aideSagaService.creditAide(this.aide.idSaga, SmartCard.convertToSmartCard(this.zdc)).subscribe( {next:res=>{
        ToastTool.success("L'aide est créditée")
        this.loadAide(this.aide.idSaga);
        this.zdc = null;
        this.ayantDroit = null;
        this.spinner.hide();
      }, error: err => {
        this.spinner.hide();
        ToastTool.dangerReponseApi(err);
      }})
  }

  recherche() {
    if (!this.zdc.identifiantCnous) {
      ToastTool.danger("L'identifiant Cnous est obligatoire")
      return
    }

    this.rightholderService.getWithCard(parseInt(this.zdc.identifiantCnous)).subscribe({
      next:res=>{
        this.ayantDroit = res;
        if (res.uidCard && !this.zdc.uid){
          this.zdc.uid=res.uidCard;
        }
      },
      error: err => {
        this.ayantDroitInconnu = true;
        ToastTool.dangerReponseApi(err);
      }});
  }
}
