import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {HttpService} from "../http.service";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {BceEtablissement} from "../../models/bce-etablissement";
import {BceEtablissementSearch} from "../../models/bce-etablissement-search";

@Injectable({
  providedIn: 'root'
})
export class BceService {

  constructor(private http: HttpClient,
              private httpService: HttpService) {
  }

  getListeBce(search: BceEtablissementSearch): Observable<BceEtablissement[]> {
    const params = this.httpService.buildParams(search);
    return this.http.get<BceEtablissement[]>(environment.apiB4Url + '/v1/bce', {params: params, withCredentials: true});
  }

  getBce(bce: BceEtablissement, search: BceEtablissementSearch): Observable<BceEtablissement> {
    const params = this.httpService.buildParams(search);
    return this.http.get<BceEtablissement>(environment.apiB4Url + '/v1/bce/' + bce.numeroUai, {params: params, withCredentials: true})
  }
}
