import { Component, OnInit } from '@angular/core';
import {EmetteurZdc} from "../../../models/emetteur-zdc";
import {EmetteurZdcService} from "../../../services/emetteur-zdc.service";

@Component({
  selector: 'app-liste-emetteurs',
  templateUrl: './liste-emetteurs.component.html',
  styleUrls: ['./liste-emetteurs.component.css']
})
export class ListeEmetteursComponent {

  constructor() {
  }

}
