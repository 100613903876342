<div class="modal-header">
  <h4 class="modal-title" id="modal-modif-title">Demande création compte Izly n° {{ demandeSelectionnee.idDemandeAyantDroit }}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <span [hidden]="listeAdSupport.length !== 0">Pas de lignes pour cette demande</span>
    <table class="table" [hidden]="listeAdSupport.length === 0">
      <thead>
      <tr>
        <th scope="col">Nom</th>
        <th scope="col">Prénom</th>
        <th scope="col">Courriel</th>
        <th scope="col">Identifiant client</th>
        <th scope="col">Numéro ZDC</th>
        <th scope="col">N° protocolaire</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let support of listeAdSupport | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
        <td>{{ support.nom }}</td>
        <td>{{ support.prenom }}</td>
        <td>{{ support.courrielInstitutionnel }}</td>
        <td>{{ support.identifiantClient }}</td>
        <td>{{ support.uid }}</td>
        <td>{{ support.identifiantCnous }}</td>
      </tr>
      </tbody>
    </table>
    <div>
      <div class="d-inline-block">
        <ngb-pagination class="d-flex" [pageSize]="pageSize" [collectionSize]="listeAdSupport.length" [(page)]="page" aria-label="Default pagination"></ngb-pagination>
      </div>
    </div>
</div>
