import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ToastTool} from "../../../tools/toast.tool";
import {HttpErrorResponse} from "@angular/common/http";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {EmetteurZdcService} from "../../../services/emetteur-zdc.service";
import {FormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {EmetteurZdc} from "../../../models/emetteur-zdc";

@Component({
  selector: 'app-dialog-form-emetteur',
  templateUrl: './dialog-form-emetteur.component.html',
  styleUrls: ['./dialog-form-emetteur.component.css']
})
export class DialogFormEmetteurComponent {

  @Output() readonly emetteurModifie = new EventEmitter<EmetteurZdc>;

  @Input() emetteurSelectionne: EmetteurZdc;

  form: UntypedFormGroup;

  constructor(private emetteurService: EmetteurZdcService,
              private fb: FormBuilder,
              public activeModal: NgbActiveModal) {

  }

  ngOnInit(): void {
    this.form = this.fb.group({
      raisonSociale: this.fb.control<string|null>(null, Validators.required),
      adresse: this.fb.control<string|null>(null),
      adresse2: this.fb.control<string|null>(null),
      codePostal: this.fb.control<string|null>(null),
      ville: this.fb.control<string|null>(null),
    });

    if (this.emetteurSelectionne.idEmetteurZdc) {
      this.initFormValues();
    } else {
      this.resetFormValues();
    }
  }

  initFormValues() {
    this.form.controls['raisonSociale'].setValue(this.emetteurSelectionne?.raisonSociale);
    this.form.controls['adresse'].setValue(this.emetteurSelectionne?.adresse);
    this.form.controls['adresse2'].setValue(this.emetteurSelectionne?.adresse2);
    this.form.controls['codePostal'].setValue(this.emetteurSelectionne?.codePostal);
    this.form.controls['ville'].setValue(this.emetteurSelectionne?.ville);
  }

  resetFormValues() {
    this.form.reset();
  }

  setValues() {
    this.emetteurSelectionne.raisonSociale = this.form.controls['raisonSociale']?.value;
    this.emetteurSelectionne.adresse = this.form.controls['adresse']?.value;
    this.emetteurSelectionne.adresse2 = this.form.controls['adresse2']?.value;
    this.emetteurSelectionne.codePostal = this.form.controls['codePostal']?.value;
    this.emetteurSelectionne.ville = this.form.controls['ville']?.value;
  }

  save() {
    if (this.emetteurSelectionne.idEmetteurZdc) {
      this.modifierEmetteur();
    } else {
      this.ajouterEmetteur();
    }
  }

  ajouterEmetteur() {
    this.setValues();
    this.emetteurService.postEmetteurZdc(this.emetteurSelectionne).subscribe({
      next:() => {
        ToastTool.success('Emetteur crée');
        this.emetteurModifie.emit();
        this.activeModal.close();
      },
      error: (err: HttpErrorResponse) => {
        for (let error of err.error) {
          let description = error.error_description;
          ToastTool.danger(description);
        }
        this.emetteurModifie.emit();
      }
    });
  }

  modifierEmetteur() {
    this.setValues();
    this.emetteurService.putEmetteurZdc(this.emetteurSelectionne).subscribe({
      next:() => {
        ToastTool.success('Emetteur modifié');
        this.emetteurModifie.emit();
        this.activeModal.close();
      },
      error: (err: HttpErrorResponse) => {
        for (let error of err.error) {
          let description = error.error_description;
          ToastTool.danger(description);
        }
        this.emetteurModifie.emit();
      }
    })
  }
}
