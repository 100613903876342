import {Component, OnInit} from '@angular/core';
import {AliveService} from "../../services/alive.service";
import {CrousService} from "../../services/crous.service";

@Component({
  selector: 'app-xpollens-api-test',
  templateUrl: './xpollens-api-test.component.html',
  styleUrls: ['./xpollens-api-test.component.css']
})
export class XpollensApiTestComponent implements OnInit {

  dateTime='';
  tokenExpirationDate='';
  error = '';

  constructor(private aliveService: CrousService) {
  }


  ngOnInit(): void {
    this.aliveService.getXpollensDate().subscribe({next : res => {
        this.dateTime = res.DateTime;
        this.tokenExpirationDate = res.TokenExpirationDate;
    }, error: (err) => {
      this.error = err && err.error && err.error.length > 0 && err.error[0].error_description ? err.error[0].error_description : 'Impossible de joindre l\'API Xpollens';
    }})
  }


}
