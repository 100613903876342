<div class="mb-3 row">
  <div class="contrats-actions col-xs-3 col-sm-auto">
    <button class="btn btn-primary" (click)="nouveauContrat()">Ajouter</button>
  </div>

  <form class="col-xs-3 col-sm-auto">
    <div class="filtre-contrat mb-3 row">
      <label for="table-filtering-search" class="col-xs-3 col-sm-auto col-form-label">Recherche :</label>
      <div class="col-xs-3 col-sm-auto">
        <input id="table-filtering-search" type="text"  class="form-control" [formControl]="filter"/>
      </div>
    </div>
  </form>
</div>

<table class="table table-striped table-bordered table-hover" id="excel-table">
  <thead>
  <tr>
    <th scope="col" sortable="emetteurZdc.raisonSociale" (sort)="onSort($event)">Emetteur</th>
    <th scope="col" sortable="libelle" (sort)="onSort($event)">Libellé</th>
    <th scope="col" sortable="crousPrivilegie" (sort)="onSort($event)">Crous</th>
    <th scope="col" sortable="codeEmetteur" (sort)="onSort($event)">Code Emetteur</th>
    <th scope="col">Code Emetteur Hex</th>
    <th scope="col" sortable="numeroLimiteInferieure" (sort)="onSort($event)">Limite Inférieure</th>
    <th scope="col" sortable="numeroLimiteSuperieure" (sort)="onSort($event)">Limite Supérieure</th>
    <th scope="col" sortable="dateDebutContrat" (sort)="onSort($event)">Date de délivrance</th>
    <th scope="col">Actions</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let contrat of contrats$ | async | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize" #em>
    <td><ngb-highlight [result]="contrat.emetteurZdc.raisonSociale" [term]="filter.value"></ngb-highlight></td>
    <td><ngb-highlight [result]="contrat.libelle" [term]="filter.value"></ngb-highlight></td>
    <td><ngb-highlight [result]="contrat.crousPrivilegie" [term]="filter.value"></ngb-highlight></td>
    <td><ngb-highlight [result]="contrat.codeEmetteur | number" [term]="filter.value"></ngb-highlight></td>
    <td><ngb-highlight [result]="contrat.codeEmetteur.toString(16)" [term]="filter.value"></ngb-highlight></td>
    <td><ngb-highlight [result]="contrat.numeroLimiteInferieure.toString()" [term]="filter.value"></ngb-highlight></td>
    <td><ngb-highlight [result]="contrat.numeroLimiteSuperieure.toString()" [term]="filter.value"></ngb-highlight></td>
    <td><ngb-highlight [result]="contrat.dateDebutContrat | date :'dd/MM/YYYY'" [term]="filter.value"></ngb-highlight></td>
    <td>
      <a class="btn btn-warning" (click)="actionBoutonContrat(contrat)"> Modifier </a>
      &nbsp;
      <a class="btn btn-danger" (click)="supprimerContrat(contrat)"> Supprimer </a>
    </td>
  </tr>
  </tbody>
</table>
<div class="align-content-start row">
  <div class="col-auto"><ngb-pagination [pageSize]="pageSize" [collectionSize]="(contrats$ | async) ? (contrats$ | async).length : listeContrats.length" [(page)]="page" aria-label="Default pagination"></ngb-pagination></div>
  <div class="col"><a class="btn btn-excel" (click)="getXls()" *ngIf="(contrats$ | async) ? (contrats$ | async).length > 0 : listeContrats.length > 0">Export Excel</a></div>
</div>


