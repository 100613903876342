import { HttpInterceptorFn} from '@angular/common/http';
import {inject} from "@angular/core";
import {OidcSecurityService} from "angular-auth-oidc-client";
import {finalize, switchMap, tap} from "rxjs";
import {NgxSpinnerService} from "ngx-spinner";

export const authInterceptor: HttpInterceptorFn = (req, next) => {

 const oidcSecurityService = inject(OidcSecurityService);
 const spinner = inject(NgxSpinnerService);

 let timer : any;
 if (!req.url.includes('code-insee')) {
   timer = setTimeout(() => {
     spinner.show();
     clearTimeout(timer);
   }, 500);
 }

 if (req.url.indexOf('v1') > 0 && req.url.indexOf('14242/v1/lecture-carte') < 0) {
   return oidcSecurityService.getIdToken().pipe(
     switchMap(token => {
       const newRequest = req.clone({
         setHeaders: {
           Authorization: `Bearer ${token}`
         },
         withCredentials: true
       });
       return next(newRequest).pipe(finalize(()=> {
         if (timer) {
           clearTimeout(timer);
         }
         spinner.hide();
       }));
     }));

 } else {
   return next(req).pipe(finalize(()=> {
     if (timer) {
       clearTimeout(timer);
     }
     spinner.hide();
   }));
 }
};
