<div class="loading" [hidden]="!loading"></div>
<form novalidate (ngSubmit)="submit()" class="needs-validation">
<div class="container-fluid mt-3">
  <h4>Importer des Ayants droit</h4>
  <div class="row">
      <div class="mb-3 col-md-5">
        <label for="formFile" class="form-label">Selectionner un fichier <b>xlsx</b> au format attendu</label>
        <input class="form-control" [class.is-invalid]="formErreur" type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" id="formFile" (change)="onFileSelected($event)" #inputFile>
        <div id="validationServer03Feedback" class="invalid-feedback" [innerHTML]="formErreur">
        </div>
      </div>
  </div>
  <div class="row">
    <div class="mb-3 col-md-5">
      <ngb-progressbar type="danger" [value]="progress" [striped]="true" />
    </div>
  </div>
  <div class="form-row" style="margin-left: 0.1em">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" id="forceTarif" [value]="1" name="forceTarif" [(ngModel)]="forceTarif">
      <label class="form-check-label" for="forceTarif">Force les tarifs du fichier excel <span style="color:red;font-weight: bolder">(Attention, peut écraser les tarifs boursiers à 1€ !)</span></label>
    </div>
  </div>
  <div class="form-row mb-3" style="margin-left: 0.1em">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" id="razRnePrioritaire" [value]="1" name="razRnePrioritaire" [(ngModel)]="razRnePrioritaire">
      <label class="form-check-label" for="razRnePrioritaire">Ecrase Rne Prioritaire</label>
    </div>
  </div>
  <div class="row mb-3">
    <div class="form-group  col-md-5">
      <label for="idCrousSelected" class="form-label">Si un UAI (RNE) du fichier n'existe pas, utiliser l'UAI du crous :</label>
      <select class="form-select" id="idCrousSelected"  [(ngModel)]="idCrousSelected" name="idCrousSelected">
        <option  *ngFor="let c of crousList" [ngValue]="c.numeroCrous">{{c.libelle}}</option>
      </select>
    </div>
  </div>
  <div class="row" *ngIf="rowQuantity > 0">
    <div class="mb-3 col-md-5">
      <h6><span class="font-import-ok">{{rowQuantity}}</span> ligne{{rowQuantity>0?'s':''}} dans le fichier (y compris l'entête)</h6>
      <h6><span class="font-import-ko">{{excelError.length}}</span> ligne{{excelError.length>0?'s':''}} avec des erreurs bloquantes</h6>
    </div>
  </div>
  <div class="row" *ngIf="rowQuantity > 0 && this.excelErrorExtrait.length > 0">
    <div class="col-md-5">
      <table class="table table-striped">
        <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Erreur</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let error of excelErrorExtrait">
          <th scope="row">{{error.rowId}}</th>
          <td [innerHTML]="error.errors.join(' | ')"></td>
        </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-between p-2">
        <div><a class="btn btn-excel" (click)="getXls()" *ngIf="excelErrorSize > 0">Export Excel</a></div>
        <div>
        <ngb-pagination
          [collectionSize]="excelErrorSize"
          [(page)]="page"
          [maxSize]="5"
          [pageSize]="pageSize"
          (pageChange)="errorNext()">
        </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
      <div class="col-md-5 d-md-flex justify-content-md-end">
        <button type="submit" class="btn btn-primary" [disabled]="!file || formErreur || excelError.length>0">Envoyer</button>
      </div>
  </div>
</div>
</form>
