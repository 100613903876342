import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {EmetteurZdc} from "../models/emetteur-zdc";
import { Observable } from 'rxjs';
import {environment} from "../../environments/environment";
import {EmetteurPostPut} from "../models/emetteur-post-put";

@Injectable({
  providedIn: 'root'
})
export class EmetteurZdcService {

  constructor(private httpClient: HttpClient) { }

  getListeEmetteursZdc(): Observable<EmetteurZdc[]> {
    return this.httpClient.get<EmetteurZdc[]>(environment.apiB4Url + '/v1/emetteurszdc', { withCredentials: true});
  }

  getEmetteurZdc(emetteur: EmetteurZdc): Observable<EmetteurZdc> {
    return this.httpClient.get<EmetteurZdc>(environment.apiB4Url + '/v1/emetteurszdc/' + emetteur.idEmetteurZdc, { withCredentials: true});
  }

  postEmetteurZdc(emetteur: EmetteurZdc): Observable<EmetteurZdc> {
    return this.httpClient.post<EmetteurZdc>(environment.apiB4Url + '/v1/emetteurszdc',new EmetteurPostPut(emetteur), { withCredentials: true});
  }

  putEmetteurZdc(emetteur: EmetteurZdc): Observable<EmetteurZdc> {
    return this.httpClient.put<EmetteurZdc>(environment.apiB4Url + '/v1/emetteurszdc/' + emetteur.idEmetteurZdc,new EmetteurPostPut(emetteur), { withCredentials: true});
  }
}
