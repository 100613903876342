export class Zdc {
  codeCnous: string;
  identifiantCrous: string;
  identifiantEmetteur: string;
  identifiantMapping: string;
  identifiantCnous: string;
  dateCreation: string;
  pixSS: string;
  pixNN: string;
  appl: string;
  uid: string;
  numeroApplicatif: string;
}
