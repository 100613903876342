
<div class="col-12 col-md-3 col-xl-2 bd-sidebar collapse width" id="leftmenu" style="width: 320px;padding:0.3rem" [ngClass]="{'show': showMenu, 'hide': !showMenu}">

  <form class="bd-search" novalidate (ngSubmit)="search()" [formGroup]="searchForm">

    <div class="form-check"  *ngIf="!this.rechercheInfocartecrous && droitSearchCompteCloture">
      <label class="form-check-label">
        <input type="checkbox"  formControlName="compteCloture" class="form-check-input" (change)="compteCloture()">
        Rechercher les comptes cloturés
      </label>
    </div>

    <div class="form-group"  *ngIf="!this.rechercheInfocartecrous">
      <select class="form-select" id="crous" formControlName="crous" (change)="selectedCrous($event)">
        <option value="0">Tous crous les autorisés</option>
        <option  *ngFor="let c of crousList" [ngValue]="c.idCrous">{{c.libelle}}</option>
      </select>
    </div>

    <div class="mb-2" *ngIf="!comptecloture">
      <div class="form-inline ">
        <div class="col-12 mb-2" >
            <div class="input-group">
              <input class="form-control" placeholder="Fin de validité >="  name="dateFinApres" formControlName="dateFinApres" ngbDatepicker #avant="ngbDatepicker">
              <button class="btn btn-outline-secondary bi bi-calendar3" (click)="avant.toggle()" type="button"></button>
            </div>
        </div>

        <div class="col-12">
          <div class="input-group ">
              <input class="form-control" placeholder="Fin de validité <"  name="dateFinAvant" formControlName="dateFinAvant" ngbDatepicker #apres="ngbDatepicker">
              <button class="btn btn-outline-secondary bi bi-calendar3" (click)="apres.toggle()" type="button"></button>
          </div>
          </div>
        </div>

    </div>
    <div class="form-group" *ngIf="!this.rechercheInfocartecrous && !comptecloture">
      <ng-select [items]="etablissementList | async"
                 id="etablissement"
                 bindLabel="nom"
                 [multiple]="false"
                 [hideSelected]="true"
                 [minTermLength]="2"
                 typeToSearchText="Saisir 2 lettres minimum"
                 placeholder="Etablissement"
                 [typeahead]="etablissementInput"
                 #etabSelect
                 (change)="selectEtablissement($event)">
        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
          <div>{{item.nom}}</div>
          <div style="font-size: xx-small">{{item.rneInList}}</div>
        </ng-template>
      </ng-select>

    </div>

    <div class="form-inline mb-2">
      <div class="form-group type-query-input">
        <select class="form-control" id="typeQueryNom" formControlName="typeQueryNom" *ngIf="!comptecloture">
          <option value="eq">=</option>
          <option value="ap">&#8776; </option>
          <option value="neq">&#8800; </option>
          <option value="nap">&#8777; </option>
        </select>
        <input type="text" class="form-control" id="nom" placeholder="Nom" formControlName="nom">
      </div>
    </div>

    <div class="form-inline mb-2">
      <div class="form-group type-query-input">
        <select class="form-control" id="typeQueryPrenom" formControlName="typeQueryPrenom" *ngIf="!comptecloture">
          <option value="eq">=</option>
          <option value="ap">&#8776; </option>
          <option value="neq">&#8800; </option>
          <option value="nap">&#8777; </option>
        </select>
        <input type="text" class="form-control" id="prenom" placeholder="Prénom" formControlName="prenom">
      </div>
    </div>
    <div class="form-inline mb-2">
      <div class="form-group type-query-input">
        <select class="form-control" id="typeQueryEmail" formControlName="typeQueryEmail" *ngIf="!comptecloture">
          <option value="eq">=</option>
          <option value="ap">&#8776; </option>
          <option value="neq">&#8800; </option>
          <option value="nap">&#8777; </option>
        </select>
        <input type="text" class="form-control" id="email" placeholder="Email" formControlName="email">
      </div>
    </div>
    <div class="form-group">
      <input type="text" class="form-control" id="identifiant" placeholder="Identifiant" formControlName="identifiant">
    </div>
    <div class="form-group">
      <input type="text" class="form-control" id="numZdc" placeholder="Numéro de carte" formControlName="numZdc">
    </div>
    <div class="form-group" *ngIf="!comptecloture">
      <input type="text" class="form-control" id="ine" placeholder="Ine" formControlName="ine">
    </div>
    <div class="form-check"  *ngIf="!this.rechercheInfocartecrous && !comptecloture">
      <label class="form-check-label">
        <input type="checkbox"  formControlName="tarifsForces" class="form-check-input">
        Tarifs forcés
      </label>
    </div>
    <div class="form-inline mb-2">
      <div class="form-group type-query-input">
        <select class="form-control" id="typeQueryCodeSociete" formControlName="typeQueryCodeSociete">
          <option value="eq">=</option>
          <option value="sup">></option>
          <option value="inf"><</option>
          <option value="neq">&#8800; </option>
        </select>
        <input type="number" class="form-control" id="codeSociete" placeholder="code Societe" formControlName="codeSociete">
      </div>
    </div>
    <div class="form-inline mb-2">
      <div class="form-group type-query-input">
        <select class="form-control" id="typeQueryCodeTarif" formControlName="typeQueryCodeTarif">
          <option value="eq">=</option>
          <option value="sup">></option>
          <option value="inf"><</option>
          <option value="neq">&#8800; </option>
        </select>
        <input type="number" class="form-control" id="codeTarif" placeholder="code Tarif" formControlName="codeTarif">
      </div>
    </div>
    <div class="form-group">
      <input type="text" class="form-control" id="rneEtablissement" placeholder="Rne Etablissement" formControlName="rneEtablissement">
    </div>
    <div class="form-group">
      <input type="text" class="form-control" id="rneComposante" placeholder="Rne Composante" formControlName="rneComposante">
    </div>

    <div style="margin-bottom: 10px" *ngIf="!comptecloture">
      <a data-toggle="collapse" href="javascript:void(0)" role="button" aria-expanded="false" aria-controls="extraoption" id="collapseextraoption" class="collapsed" (click)="toggle()"> de critères...
      </a>
    </div>

    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" id="extraoption" class="collapse" *ngIf="!comptecloture">

      <div class="form-group">
        <input type="text" class="form-control" id="numInterne" placeholder="numero Interne" formControlName="numInterne">
      </div>
        <div class="form-check"  *ngIf="!this.rechercheInfocartecrous">
          <label class="form-check-label">
            <input type="checkbox"  formControlName="sansCarte" class="form-check-input">
            Sans Carte
          </label>
        </div>
      <div class="form-check"  *ngIf="!this.rechercheInfocartecrous">
        <label class="form-check-label">
          <input type="checkbox"  formControlName="avecCarte" class="form-check-input">
          Avec au moins 1 Carte
        </label>
      </div>
      <div class="form-check"  *ngIf="!this.rechercheInfocartecrous">
        <label class="form-check-label">
          <input type="checkbox"  formControlName="sansIne" class="form-check-input">
          Sans INE
        </label>
      </div>
      <div class="form-check"  *ngIf="!this.rechercheInfocartecrous">
        <label class="form-check-label">
          <input type="checkbox"  formControlName="sansDateNaissance" class="form-check-input">
          Sans Date de Naissance
        </label>
      </div>
        <div class="form-inline">
          <div class="form-group">
            <select class="form-control" id="persoSelected" formControlName="persoSelected">
              <option value="perso1">Perso1</option>
              <option value="perso2">Perso2</option>
              <option value="perso3">Perso3</option>
              <option value="perso4">Perso4</option>
              <option value="perso5">Perso5</option>
              <option value="perso6">Perso6</option>
              <option value="perso7">Perso7</option>
              <option value="perso8">Perso8</option>
            </select>
            <select class="form-control" id="typeQueryCodePerso" formControlName="typeQueryCodePerso" style="padding-left: 0;">
              <option value="eq">=</option>
              <option value="ap">&#8776; </option>
              <option value="neq">&#8800; </option>
              <option value="nap">&#8777; </option>
            </select>
            <input type="text" class="form-control" id="perso" placeholder="Perso" formControlName="perso" style="width: 55%">
          </div>
        </div>
        <div class="form-inline">
          <div class="form-group type-query-input">
            <select class="form-control" id="typeQueryDateNaissance" formControlName="typeQueryDateNaissance">
              <option value="eq">=</option>
              <option value="sup">></option>
              <option value="inf"><</option>
              <option value="neq">&#8800; </option>
            </select>
            <input type="text" class="form-control" id="dateNaissance" placeholder="date naissance (jj/mm/aaaa)" formControlName="dateNaissance">
          </div>
        </div>
        <div class="form-inline">
          <div class="form-group type-query-input">
            <select class="form-control" id="typeQueryDateCreation" formControlName="typeQueryDateCreation">
              <option value="eq">=</option>
              <option value="sup">></option>
              <option value="inf"><</option>
              <option value="neq">&#8800; </option>
            </select>
            <input type="text" class="form-control" id="dateCreation" placeholder="date creation (jj/mm/aaaa)" formControlName="dateCreation">
          </div>
        </div>
        <div class="form-inline">
          <div class="form-group type-query-input">
            <select class="form-control" id="typeQueryDateModification" formControlName="typeQueryDateModification">
              <option value="eq">=</option>
              <option value="sup">></option>
              <option value="inf"><</option>
              <option value="neq">&#8800; </option>
            </select>
            <input type="text" class="form-control" id="dateModification" placeholder="date der. modif. (jj/mm/aaaa)" formControlName="dateModification">
          </div>
        </div>
    </div>

    <!-- button -->
    <div class="form-group">
        <button class="btn btn-primary" type="submit">
          <span class="btn-text">Rechercher</span>
          <img [hidden]="!formSubmited" style="margin-left: 5px" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        </button>
    </div>
  </form>
</div>

<!--

Date de naissance
Date de creation
Date de modification



-->
