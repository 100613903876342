import {Component, EventEmitter, Input, Output, QueryList, ViewChildren} from '@angular/core';
import {Rne} from "../../models/rne";
import {ToastTool} from "../../tools/toast.tool";
import {RneService} from "../../services/rne.service";
import {map, Observable, startWith, toArray} from "rxjs";
import {FormControl} from "@angular/forms";
import {EmetteurZdcContrat} from "../../models/emetteur-zdc-contrat";
import {DialogFormEmetteurComponent} from "../../admin/emetteurs/dialog-form-emetteur/dialog-form-emetteur.component";
import {EmetteurZdc} from "../../models/emetteur-zdc";
import {NgbModal, NgbModalConfig} from "@ng-bootstrap/ng-bootstrap";
import {DialogFormRneComponent} from "../dialog-ajout-rne/dialog-form-rne.component";
import {NgbdSortableHeader, SortEvent} from "../../tools/ngbd-sortable-header.directive";

@Component({
  selector: 'app-tableau-rne',
  templateUrl: './tableau-rne.component.html',
  styleUrls: ['./tableau-rne.component.css']
})
export class TableauRneComponent {
  @Input() listeRne: Rne[] = [];
  @Output() rneModifie = new EventEmitter<Rne>();

  listeRne$ : Observable<Rne[]>;
  filter = new FormControl('', {nonNullable: true});

  pageSize = 500;
  page = 5;

  loading = false;

  constructor(private rneSrvice: RneService,
              private modalService: NgbModal,
              private config: NgbModalConfig) {
    this.loadListeRne();
  }

  ngOnInit(): void {
  }

  search(text: string): Rne[] {
    return this.listeRne.filter((rne) => {
      const term = text.toLowerCase();
      return (
        rne.uairne.toLowerCase().includes(term) ||
        rne.libelleCourt?.toLowerCase().includes(term) ||
        rne.nomUsuel?.toLowerCase().includes(term) ||
        rne.localite?.toLowerCase().includes(term) ||
        rne.etablissementClient?.nom.toLowerCase().includes(term)
      );
    });
  }

  loadListeRne() {
    this.loading = true;
    this.rneSrvice.getListeRne().subscribe({
      next: (data) => {
        this.listeRne = data;

        this.filter.reset();
        this.listeRne$ = this.filter.valueChanges.pipe(
          startWith(''),
          map((text) => this.search(text)),
        );
        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
        for (let error of err.error) {
          let description = error.error_description;
          ToastTool.danger(description);
        }
      }
    })
  }

  modifierRne(rne: Rne): void {
    const modalRef = this.modalService.open(DialogFormRneComponent);
    modalRef.componentInstance.rneSelectionne = rne;
    modalRef.componentInstance.rneModifie.subscribe(() => {
      this.loadListeRne();
    })
  }

  supprimerRne(rne: Rne): void {
    if(confirm("Etes-vous sûr de vouloir supprimer ce RNE")) {
      this.rneSrvice.deleteRne(rne).subscribe({
        next: () => {
          ToastTool.success("RNE supprimé");
          this.loadListeRne();
        },
        error: (err) => {
          for (let error of err.error) {
            let description = error.error_description;
            ToastTool.danger(description);
          }
        }
      });
    }
  }

  nouvelRne(): void {
    const modalRef = this.modalService.open(DialogFormRneComponent);
    modalRef.componentInstance.rneSelectionne = new Rne();
    modalRef.componentInstance.rneModifie.subscribe(() => {
      this.loadListeRne();
    })
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    for (const header of this.headers) {
      if (header.sortable !== column) {
        header.direction = '';
      }
    }

    if (direction === '' || column === '') {
      this.loadListeRne();
    } else {
      this.listeRne$ = this.listeRne$.pipe(map(arr => arr.sort((a, b) => {
        console.log(column)
        let res;
        if (column == 'etablissementClient.nom') {
          res = NgbdSortableHeader.compare(a.etablissementClient ? a.etablissementClient.nom : '', b.etablissementClient ? b.etablissementClient.nom : '');
        } else {
          res = NgbdSortableHeader.compare(a[column], b[column]);
        }
        return direction === 'asc' ? res : -res;
      })));
    }
  }
}
