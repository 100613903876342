import {BatchType} from "./batch-type";
import {Crous} from "./crous";
import {EtablissementClient} from "./etablissement-client";
import {BatchFtpType} from "./batch-ftp-type";

export class Batch {

  idBatch:number;
  description:string;
  cronSyntax:string;
  actif:boolean;
  batchType:BatchType;
  dateDebutDerniereExecution:string;
  dateFinDerniereExecution:string;
  humanCronTranslation:string;
  sendLogReport:boolean;
  courrielEnvoiLogReport:string;
  isRunning:boolean;
  migrated:boolean;

  //BatchFtp
  importAdresseServeur:string;
  importPort:number;
  importIdentifiant:string;
  importMotDePasse:string;
  importChemin:string;
  importFichier:string;
  crous:Crous;
  batchFtpType:BatchFtpType;
  dateGDernierFichier:string;
  typeFTP: string;
  etablissementClient:EtablissementClient;
  useSSHKey:boolean;
  partialFile:boolean;
  forceImportEtablissement:boolean;
  useGcc:boolean;

  //BatchImportClosedAccount
  recupereAutreSFTPIzly: boolean;
  host:string;
  user:string;
  password:string;
  port:number;
  remoteDir:string;

  //BatchImportOpposition, BatchStatutAyantDroit
  dateDebut:string;
  dateFin:string;
  nbJour:number;

  //BatchPurgeRGPD
  nombreMoisConservationCommande:number;
  nombreMoisConservationCommandeBrouillon:number;
  nombreMoisConservationAideSaga:number;
}
