import { Component } from '@angular/core';
import {FormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {CardService} from "../../services/card.service";
import {ToastTool} from "../../tools/toast.tool";
import {CardIzly} from "../../models/card-izly";
import {Card} from "../../models/card";
import {SupportPhysique} from "../../models/support-physique";


@Component({
  selector: 'app-recherche-carte',
  templateUrl: './recherche-carte.component.html',
  styleUrls: ['./recherche-carte.component.css']
})
export class RechercheCarteComponent {

  loading = false
  messageIzly='';
  messageB4='';
  searchForm: UntypedFormGroup;
  cardIzly: CardIzly;
  cardB4: SupportPhysique;

  idIzly: number;
  uid: string;

  constructor(private fb: FormBuilder, private cardService: CardService) {
  }


  searchIzly() {
    console.log(this.idIzly)
    this.messageIzly='';
    this.cardIzly=null;
    this.cardService.searchIzly(this.idIzly, this.uid).subscribe({
      next : res => {
        if (!res) {
          this.messageIzly='Carte introuvable sur izly';
        } else {
          this.cardIzly = res;
        }
      },
      error : err => {
        console.log(err);
        this.messageIzly=err.error.errors[0].message;
      }
    })
  }

  searchB4() {
    console.log(this.idIzly)
    this.messageB4='';
    this.cardB4=null;
    this.cardService.searchBeforeizly(this.idIzly, this.uid).subscribe({
      next : res => {
        if (!res) {
          this.messageB4='Carte introuvable sur izly';
        } else {
          this.cardB4 = res;
          this.idIzly = parseInt(res.identifiantCnous);
          this.uid = res.numeroApplicatif;
        }
      },
      error : err => {
        console.log(err);
        this.messageB4=err.error.errors[0].message;
      }
    })
  }
}
