import {Component, OnInit} from '@angular/core';
import {UserService} from "../../services/user.service";
import {Me} from "../../models/me";
import {ToastTool} from "../../tools/toast.tool";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

    me: Me;

    constructor(private userService: UserService) { }


    ngOnInit(): void {
        this.userService.me().subscribe({
          next: me => {this.me=me },
          error: err => {
            ToastTool.dangerReponseApi(err);
          }
        })
    }

  protected readonly Me = Me;
}
