import { Injectable } from '@angular/core';
import {SearchAyantDroit} from '../models/search-ayant-droit';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {GetInfoCarteCrous} from "../models/get-info-cartecrous";
import {InfoCarteCrous} from "../models/infocartecrous";
import {map, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AyantDroitSearchService {


  constructor(private http: HttpClient) {

  }

  search(form: any) {
    return this.http.post<SearchAyantDroit>( environment.apiB4Url +
      '/v1/rightholders/search?format=datatable', form, { withCredentials: true });
  }

  searchIzly(form: InfoCarteCrous) : Observable<SearchAyantDroit> {
    return this.http.post<GetInfoCarteCrous[]>( environment.apiB4Url +
      '/v1/rightholders/find', form, { withCredentials: true }).pipe(map(res => SearchAyantDroit.fromGetInfoCarteCrous(res) ));
  }

}
