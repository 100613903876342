<div class="container-fluid mt-3">
  <h4>{{action | titlecase}} une annonce</h4>

  <form (submit)="save()" [formGroup]="form" *ngIf="form" class="mt-4" style="margin-left: 20px; margin-right: 20px">

    <div class="row mb-3">
      <label for="sujet"  class="col-md-2 col-form-label required">Sujet</label>
      <div class="col-md-2">
        <input type="text" autocomplete="off"  class="form-control" id="sujet" name="sujet"
               formControlName="sujet" [class.is-invalid] = "form.get('sujet')?.invalid && (form.get('sujet')?.touched || form.get('sujet')?.dirty)">
        <div class="invalid-feedback" *ngIf="form.get('sujet')?.touched && form.get('sujet')?.hasError('required')">Sujet est requis</div>
        <div class="invalid-feedback" *ngIf="form.get('sujet')?.touched && form.get('sujet')?.hasError('maxlength')">Maximum 255 caractères</div>
        <div class="invalid-feedback" *ngIf="form.get('sujet')?.touched && form.get('sujet')?.hasError('minlength')">Minimum 10 caractères</div>
      </div>
      <div class="col-md-1">&nbsp;</div>
      <label for="label" class="col-md-2 col-form-label required">Label</label>
      <div class="col-md-3">
        <select class="form-select" id="label" name="label" formControlName="label" [class.is-invalid] = "form.get('label')?.invalid && (form.get('label')?.touched || form.get('label')?.dirty)">
          <option  value="1">Info</option>
          <option  value="2">Alerte</option>
          <option  value="3">Maintenance</option>
          <option  value="4">Panne</option>
        </select>
      </div>
    </div>

    <div class="row mb-3">
        <label for="description" class="col-md-2 col-form-label required">Description</label>
        <div class="col-sm-9">
          <textarea id="description" name="description" formControlName="description" class="form-control" rows="3"
                                        [class.is-invalid] = "form.get('description')?.invalid && (form.get('description')?.touched || form.get('description')?.dirty)"></textarea>
          <div class="invalid-feedback" *ngIf="form.get('description')?.touched && form.get('description')?.hasError('required')">Description est requis</div>
          <div class="invalid-feedback" *ngIf="form.get('description')?.touched && form.get('description')?.hasError('minlength')">Minimum 20 caractères</div>
        </div>
    </div>


    <div class="row mb-3">
      <label for="dateDebut" class="col-md-2 col-form-label required">Date&nbsp;debut</label>
      <div class="col-md-1">
        <div class="input-group ">
          <input type="text" id="dateDebut" name="dateDebut"  placeholder="jj/mm/aaaa" class="form-control"
                 [class.is-invalid]= "form.get('dateDebut')?.invalid && (form.get('dateDebut')?.touched || form.get('dateDebut')?.dirty)"
                 formControlName="dateDebut" ngbDatepicker #d="ngbDatepicker">
          <button class="btn btn-outline-secondary bi bi-calendar3" (click)="d.toggle()" type="button"></button>
        </div>
      </div>
      <div class="col-md-2">&nbsp;</div>
      <label for="datefin" class="col-md-1 col-form-label required">Date&nbsp;fin</label>
      <div class="col-md-1">
        <div class="input-group ">
          <input type="text" id="dateFin" name="dateFin"  placeholder="jj/mm/aaaa" class="form-control"
                 [class.is-invalid]= "form.get('dateFin')?.invalid && (form.get('dateFin')?.touched || form.get('dateFin')?.dirty)"
                 formControlName="dateFin" ngbDatepicker #d2="ngbDatepicker">
          <button class="btn btn-outline-secondary bi bi-calendar3" (click)="d2.toggle()" type="button"></button>
        </div>
      </div>

    </div>

    <div class="row mb-3">
      <label for="poid" class="col-md-2 col-form-label required">Poid</label>
      <div class="col-md-1">
        <select class="form-select" id="poid" name="label" formControlName="poid" [class.is-invalid] = "form.get('poid')?.invalid && (form.get('poid')?.touched || form.get('poid')?.dirty)">
          <option [value]="1">1</option>
          <option [value]="2">2</option>
          <option [value]="3">3</option>
          <option [value]="4">4</option>
          <option [value]="5">5</option>
          <option [value]="6">6</option>
          <option [value]="7">7</option>
          <option [value]="8">8</option>
          <option [value]="9">9</option>
          <option [value]="10">10</option>
        </select>
      </div>
      <div class="col-md-2">&nbsp;</div>
      <div class="col-md-1 form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="actif" [value]="1" formControlName="actif" name="actif">
        <label for="actif"  class="form-check-label">Actif</label>
      </div>
    </div>


      <div class="row">

      </div>

    <div class="row">&nbsp;</div>
    <div class="row">
      <div class="col-md-11 d-md-flex justify-content-md-end">
        <button type="submit" class="btn btn-primary">Enregistrer</button>
      </div>
    </div>



  </form>
</div>
