<div class="modal-header">
  <h4 class="modal-title">Evolution du système d'authentification</h4>
</div>
<div class="modal-body">
  <p>Pour renforcer la sécurité, le système d'authentification à BeforeIzly évolue vers <b>ProConnect</b>.</p>
  <p>Afin de migrer facilement votre compte Beforeizly,
    liez le dès aujourd'hui à ProConnect en cliquant sur le bouton ci dessous</p>
  <p>Si vous n'avez pas encore de compte ProConnect, il vous sera proposer d'en créer un.</p>
  <p class="text-danger" style="font-size: x-large">A compté du 15/10, seul l'authentification ProConnect sera possible. Si vous n'avez pas fait cette démarche avant, vous ne pourrez plus lier votre compte sans contacter le support.</p>
  <p>
  <div class="text-center">
    <button class="proconnect-button" (click)="login()">
      <span class="proconnect-sr-only">S'identifier avec ProConnect</span>
    </button>
    <p>
      <a
        href="https://www.proconnect.gouv.fr/"
        target="_blank"
        rel="noopener noreferrer"
        title="Qu’est-ce que ProConnect ? - nouvelle fenêtre"
      >
        Qu’est-ce que ProConnect ?
      </a>
    </p>
  </div>
</div>
