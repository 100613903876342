import { Component } from '@angular/core';
import {UserService} from "../../services/user.service";
import {OidcSecurityService} from "angular-auth-oidc-client";
import {AnnonceService} from "../../services/annonce.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Router} from "@angular/router";

@Component({
  selector: 'app-no-habilitation',
  templateUrl: './no-habilitation.component.html',
  styleUrls: ['./no-habilitation.component.css']
})
export class NoHabilitationComponent {

  constructor(private oidcSecurityService: OidcSecurityService, private router: Router) {}

  deconnect() {
    this.oidcSecurityService.logoff().subscribe((result) => console.log(result));
    this.router.navigate(['connexion']);
  }

}
