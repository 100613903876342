import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {HttpService} from "./http.service";
import {environment} from "../../environments/environment";
import {EmetteurZdcContrat} from "../models/emetteur-zdc-contrat";
import {Observable} from "rxjs";
import {EmetteurZdcContratPostPut} from "../models/emetteur-zdc-contrat-post-put";

@Injectable({
  providedIn: 'root'
})
export class EmetteurZdcContratService {

  constructor(private httpClient: HttpClient, private httpService: HttpService) { }

  getListeContrats(fields: string): Observable<EmetteurZdcContrat[]> {
    const params = this.httpService.buildParams({fields: fields})
    return this.httpClient.get<EmetteurZdcContrat[]>(environment.apiB4Url + '/v1/emetteurszdccontrats', { withCredentials: true, params });
  }

  getContrat(idContrat: number, fields: string) : Observable<EmetteurZdcContrat> {
    const params = this.httpService.buildParams({fields: fields})
    return this.httpClient.get<EmetteurZdcContrat>(environment.apiB4Url + '/v1/emetteurszdccontrats/' + idContrat, { withCredentials: true, params })
  }

  ajouterContrat(contrat: EmetteurZdcContrat): Observable<EmetteurZdcContrat> {
    return this.httpClient.post<EmetteurZdcContrat>(environment.apiB4Url + '/v1/emetteurszdccontrats',new EmetteurZdcContratPostPut(contrat), {withCredentials: true })
  }

  modifierContrat(contrat: EmetteurZdcContrat): Observable<EmetteurZdcContrat> {
    return this.httpClient.put<EmetteurZdcContrat>(environment.apiB4Url + '/v1/emetteurszdccontrats/' + contrat.idEmetteurZdcContrat,new EmetteurZdcContratPostPut(contrat), { withCredentials: true })
  }

  trancheSuivante(): Observable<number> {
    return this.httpClient.get<number>(environment.apiB4Url + '/v1/trancheSuivante', { withCredentials: true});
  }

  supprimerContrat(contrat: EmetteurZdcContrat): Observable<EmetteurZdcContrat> {
    return this.httpClient.delete<EmetteurZdcContrat>(environment.apiB4Url + '/v1/emetteurszdccontrats/' + contrat.idEmetteurZdcContrat, { withCredentials: true })
  }
}
