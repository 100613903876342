import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from "rxjs";
import {catchError, map, tap} from "rxjs/operators";
import {UserService} from '../services/user.service';
import {Me} from "../models/me";
import {ToastTool} from "../tools/toast.tool";
import {environment} from "../../environments/environment";

@Injectable()
export class AuthGuard implements CanActivate {

  private user:Me;

  /**
   * Constructeur par défaut de la classe AuthGuard
   * @param userService
   * @param router instance de Router
   * @param messageTool service d'aide à l'affichage de messages
   */
  constructor(private userService: UserService, private router: Router) {
  }

  /**
   * Méthode permettant de renvoyer l'utilisateur vers la page de login si il apparait qu'il n'est pas connecté
   * @param next  Route à laquelle l'utilisateur veut accèder
   * @param state Route actuelle
   */
  canActivate(
    next: ActivatedRouteSnapshot | null,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> {

    // Droit nécessaire pour pouvoir accéder à la route
    let droitNecessaire = next.data["droit"] as string;

    // Si l'utilisateur n'est pas authentifié
    return this.isAuth(droitNecessaire);

  }

  isAuth(droit): Observable<boolean> {

    return this.userService.me().pipe(
      tap((user: Me) => {
        this.user = Object.assign(new Me(), user);
      }),
      map(res => {
        if (res) {
          if (!this.user.possedeDroit(droit)) {
            alert('Accès interdit')
            return false;
          }
          return true;
        } else {
          this.router.navigate(['/']);
          console.log("/me error");
          return false;
        }
      }),
      catchError((err) => {
        this.router.navigate(['/']);
        console.log(err);
        return of(false);
      })
    );
  }

}
