import { Component } from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-modal-alert',
  templateUrl: './modal-alert.component.html',
  styleUrls: ['./modal-alert.component.css']
})
export class ModalAlertComponent {

  title='';
  messageP1='';
  messageP2 = '';
  messageP3 = ''
  messageP4='';

  messageP1Bold= false;
  messageP2Bold= false;
  messageP3Bold= false;
  messageP4Bold= false;

  messageP1Red= false;
  messageP2Red= false;
  messageP3Red= false;
  messageP4Red= false;



  constructor(public activeModal: NgbActiveModal) {
  }
}
