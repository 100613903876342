import {UtilisateurB4} from "./utilisateur-b4";

export class Annonce {
  idInfo: number;
  sujet: string;
  description: string;
  dateDebut: Date;
  dateFin: Date;
  utilisateur: UtilisateurB4;
  ts: Date;
  importance: number;
  poid: number;
  actif: boolean;
}
