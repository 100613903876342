import { Component } from '@angular/core';

@Component({
  selector: 'app-liste-demande-ayant-droit',
  templateUrl: './liste-demande-ayant-droit.component.html',
  styleUrls: ['./liste-demande-ayant-droit.component.css']
})
export class ListeDemandeAyantDroitComponent {

}
