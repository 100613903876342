import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {ApiBoursier} from "../models/api-boursier";
import {ApiBoursierRegion} from "../models/api-boursier-openId";



@Injectable()
export class ApiBoursierService {


  constructor(private http: HttpClient) {
  }

  getByIne(ine: string) {
    return this.http.get<ApiBoursier>(environment.apiB4Url + '/v1/boursier/' + ine, { withCredentials: true });
  }

  getByOpenId(dto: ApiBoursierRegion) {
    return this.http.post<ApiBoursier>(environment.apiB4Url + '/v1/boursier/find',  dto, {withCredentials: true })
  }

}
