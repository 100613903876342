import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(value: any, args: string[]): any {
    if (value) {
      const date = moment(value, args[0]);
      return  date.format(args[1]);
    }
    return '';
  }

}
