import {Component, Input} from '@angular/core';
import {DemandeAyantDroit} from "../../../models/demande-ayant-droit";
import {NgbActiveModal, NgbModalConfig} from "@ng-bootstrap/ng-bootstrap";
import {DemandeAyantDroitService} from "../../../services/demande-ayant-droit.service";
import {ADSupport} from "../../../models/adsupport";
import {ToastTool} from "../../../tools/toast.tool";

@Component({
  selector: 'app-dialog-demande-ayant-droit',
  templateUrl: './dialog-demande-ayant-droit.component.html',
  styleUrls: ['./dialog-demande-ayant-droit.component.css']
})
export class DialogDemandeAyantDroitComponent {

  @Input() demandeSelectionnee: DemandeAyantDroit;
  loading: boolean = false;

  page = 5;
  pageSize = 20;

  listeAdSupport: ADSupport[] = [];

  constructor(private demandeAyantDroitService: DemandeAyantDroitService,
              private config: NgbModalConfig,
              public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    this.loadListeADSupport();
  }


  loadListeADSupport() {
    this.loading = true;
    this.demandeAyantDroitService.getListeADSupportFromDemandeAyantDroit(this.demandeSelectionnee).subscribe({
      next: (data) => {
        this.listeAdSupport = data;
        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
        for (let error of err.error) {
          let description = error.error_description;
          ToastTool.danger(description);
        }
      }
    })
  }
}
