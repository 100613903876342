<div *ngFor="let annonce of annonceList">
  <div class="alert alert-dismissible fade show" role="alert"
       [class.alert-success]="annonce.importance==1" [class.alert-danger]="annonce.importance==2 || annonce.importance==3 || annonce.importance==4">
    <h6>{{annonce.sujet}} <span class="badge text-bg-primary">{{ annonce.importance | label}}</span></h6>
    <p>{{annonce.description}}</p>
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
  </div>
</div>

<nav class="navbar navbar-expand-md navbar-light bg-light" style="padding-top:0px;padding-bottom:0;line-height: 2;">
  <div class="container-fluid">
      <button type="button" data-bs-toggle="collapse" href="#leftmenu" aria-expanded="false" aria-controls="leftmenu"
              style="font-size: 100%; font-family:inherit;border: 0; padding: 0; background-color: #f8f9fa;">
        <i class="fa fa-bars" style="font-size: 25px; line-height: unset; margin-right: 10px"></i>
      </button>
    <a class="navbar-brand" [routerLink]="['/']">BeforeIzly</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample04" aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarsExample04">
      <ul class="navbar-nav mr-auto ">
        <li *ngFor="let c of actions" class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" [attr.id]="'dropdown'+ c.id" data-toggle="dropdown" href="#" aria-haspopup="true" role="button" data-bs-toggle="dropdown" aria-expanded="false">{{ c.name }}</a>
          <ul class="dropdown-menu" [attr.aria-labelledby]="'dropdown' + c.id " >
              <li *ngFor="let m of c.subMenu" [ngClass]="{'dropdown-submenu': m.subMenu!=null}">
                <a [ngClass]="{'dropdown-item':true, 'dropdown-toggle': m.subMenu!=null}" routerLink="{{ m.href }}">{{ m.name }}</a>

                <ul class="dropdown-menu" *ngIf="m.subMenu!=null">
                  <li *ngFor="let n of m.subMenu">
                    <a class="dropdown-item" routerLink="{{ n.href }}">{{ n.name }}</a>
                  </li>
                </ul>
              </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="justify-content-end">
      <span style="font-size: small" *ngIf="me" [class.fw-bold]="expiration1mois()" [class.text-danger]="expiration1mois()">Compte actif jusqu'au {{me.dateFinValidite | date}}</span> |
      <span style="font-size: small">ui-{{version}}</span>
      <button class="btn" (click)="deconnect()"><i class="fa fa-power-off" aria-hidden="true"></i></button>
    </div>
  </div>
</nav>
