<div class="row">
  <div class="col-md-3 bd-sidebar collapse width show" id="leftmenu" style="width: 320px; padding: 1rem">

    <form class="bd-search" novalidate>
      <div class="form-group">
        <input [(ngModel)]="idIzly" id="idIzly" type="number" class="form-control" name="idIzly" placeholder="Identifiant (id Izly)"/>
      </div>
      <div class="form-group">
        <input [(ngModel)]="uid" id="uid" type="text" class="form-control" name="uid" placeholder="Num. applicatif (uid)"/>
      </div>
    </form>
  </div>


  <div class="card col-4 mt-3">
    <div class="row">
      <div class="col-8 mt-2">
        <h3>Recherche sur Beforeizly</h3>
        <p><small>Identifiant <b>ou</b> Numéro applicatif sont obligatoires</small></p>
        <p *ngIf="messageB4" class="text-bg-danger">{{messageB4 }}</p>
      </div>
      <div class="text-center col-3 mt-4">
        <button class="btn btn-primary" type="button" (click)="searchB4()" [disabled]="!idIzly && !uid">
          <span class="btn-text">Rechercher</span>
        </button>
      </div>
    </div>

    <div *ngIf="cardB4">
      idIzly : {{cardB4.identifiantCnous}}<br>
      numeroProtocolaire : {{cardB4.numeroProtocolaire}}<br>
      identifiantCrous : {{cardB4.identifiantCrous}}<br>
      identifiantEmetteur : {{cardB4.identifiantEmetteur}}<br>
      versionMapping : {{cardB4.identifiantMapping}}<br>
      dateCreation : {{cardB4.dateCreationSupport | date:'dd/MM/yyyy HH:mm'}}<br>
      dateOpposition : {{cardB4.dateOpposition | date:'dd/MM/yyyy HH:mm'}}<br>
      typeOpposition : {{cardB4.motifOpposition}}<br>
      <br>
      identifiantClient : {{cardB4.client.identifiantClient}}<br>
      codeSociete : {{cardB4.client.codesociete}}<br>
      numTarif : {{cardB4.client.codetarif}}<br>
      nom : {{cardB4.client.nom}}<br>
      prenom : {{cardB4.client.prenom}}<br>
      dateFinValidite : {{cardB4.client.dateFinValidite | date:'dd/MM/yyyy HH:mm'}}<br>
      courriel : {{cardB4.client.courrielInstitutionnel}}<br>
      rneEtablissement : {{cardB4.client.rneEtablissement}}<br>
      rneComposante : {{cardB4.client.rneComposante}}<br>
      codeEtudiant : {{cardB4.client.numeroInterne}}<br>
      dateNaissance : {{cardB4.client.dateNaissance | date:'dd/MM/yyyy'}}<br>
      ine : {{cardB4.client.ine}}<br>
      telPortable : {{cardB4.client.numeroTelephoneMobile}}<br>
      statutCompteClient : {{cardB4.client.statutCompte}}<br>
      statutBlocage : {{cardB4.client.statutBlocage}}<br><br>
      <a href="javascript:void(0)" [routerLink]="['/ad/search/ad/',cardB4.client.identifiantClient]">Voir l'ayantdroit sur beforeizly</a><br><br>
    </div>
  </div>


<div class="card col-4 mt-3" style="margin-left: 1rem">
  <div class="row">
    <div class="col-8 mt-2">
      <h3>Recherche sur izly</h3>
      <p><small>Identifiant <b>et</b> Numéro applicatif sont obligatoires</small></p>
      <p *ngIf="messageIzly" class="text-bg-danger">{{messageIzly }}</p>
    </div>
    <div class="text-center col-3 mt-4">
      <button class="btn btn-primary" type="button" (click)="searchIzly()" [disabled]="!idIzly || !uid">
        <span class="btn-text">Rechercher</span>
      </button>
    </div>
  </div>

  <div *ngIf="cardIzly">
    idIzly: {{idIzly}}<br>
    numeroProtocolaire : {{cardIzly.numeroProtocolaire}}<br>
    identifiantCrous : {{cardIzly.identifiantCrous}}<br>
    identifiantEmetteur : {{cardIzly.identifiantEmetteur}}<br>
    versionMapping : {{cardIzly.versionMapping}}<br>
    dateCreation : {{cardIzly.dateCreation | date:'dd/MM/yyyy HH:mm'}}<br>
    dateOpposition : {{cardIzly.dateOpposition | date:'dd/MM/yyyy HH:mm'}}<br>
    typeOpposition : {{cardIzly.typeOpposition}}<br>
    <br>
    Numero client VEM : {{cardIzly.idIzly}}<br>
    identifiantClient : {{cardIzly.codeClient}}<br>
    solde : {{cardIzly.solde/100}}<br>
    codeSociete : {{cardIzly.codeSociete}}<br>
    numTarif : {{cardIzly.numTarif}}<br>
    nom : {{cardIzly.nom}}<br>
    prenom : {{cardIzly.prenom}}<br>
    dateFinValidite : {{cardIzly.dateFinValidite | date:'dd/MM/yyyy HH:mm'}}<br>
    cycle : {{cardIzly.cycle}}<br>
    courriel : {{cardIzly.courriel}}<br>
    rneEtablissement : {{cardIzly.rneEtablissement}}<br>
    rneComposante : {{cardIzly.rneComposante}}<br>
    codeEtudiant : {{cardIzly.codeEtudiant}}<br>
    dateNaissance : {{cardIzly.dateNaissance | date:'dd/MM/yyyy'}}<br>
    ine : {{cardIzly.ine}}<br>
    telPortable : {{cardIzly.telPortable}}<br>
    statutCompteClient : {{cardIzly.statutCompteClient}}<br>
    statutBlocage : {{cardIzly.statutBlocage}}<br><br>
    <a href="javascript:void(0)" [routerLink]="['/ad/search/ad/',cardIzly.codeClient]">Voir l'ayantdroit sur beforeizly</a><br><br>
  </div>
</div>
</div>
