import {CrousParts} from './crous-parts';
import {IzlyParts} from './izly-parts';
import {RightholderHistorique} from './rightholder-historique';

export class Rightholder {


  constructor() {
    this.crousParts = new CrousParts();
    this.izlyParts = new IzlyParts();
  }

  identifier: string;
  firstName: string;
  lastName: string;
  email: string;
  dueDate: string;
  idCompanyRate: number;
  idRate: number;
  birthDate: string;
  rneOrgCode: String;
  rneDepCode: String;
  internalId: string;
  ine: string;
  secondaryEmail: string;
  cellNumber: string;
  address1: string;
  address2: string;
  address3: string;
  zipCode: string;
  city: string;
  country: string;
  other1: string;
  other2: string;
  other3: string;
  other4: string;
  other5: string;
  other6: string;
  other7: string;
  other8: string;
  other9: string;
  other10: string;
  changeRateDate: string;
  futurIdCompanyRate: number;
  futureRate: number;
  student: boolean;
  idCrous: number;

  applyDateRateFutur: string;
  idCompanyRateFutur: number;
  idRateFutur: number;

  applyRateForced: boolean;
  idCompanyRateForced: number;
  idRateForced: number;

  uidCard: string;

  crousParts: CrousParts;

  izlyParts: IzlyParts;

  historic: Array<RightholderHistorique>;

  rnePrioritaire: string;
}
