import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {UtilisateurB4} from "../../../models/utilisateur-b4";
import {map, Observable, startWith} from "rxjs";
import {FormControl} from "@angular/forms";
import {NgbdSortableHeader, SortEvent} from "../../../tools/ngbd-sortable-header.directive";
import {ToastTool} from "../../../tools/toast.tool";
import {Annonce} from "../../../models/annonce";
import {AnnonceService} from "../../../services/annonce.service";
import {Router} from "@angular/router";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-publication-annonces-list',
  templateUrl: './publication-annonces-list.component.html',
  styleUrls: ['./publication-annonces-list.component.css']
})
export class PublicationAnnoncesListComponent implements OnInit{

  list: Array<Annonce> = [];
  list$ : Observable<Annonce[]>;
  pageSize = 15;
  page = 5;
  filter = new FormControl('', {nonNullable: true});


  constructor(private annonceService: AnnonceService, private datePipe: DatePipe,
              private router: Router) {
  }

  ngOnInit(): void {
        this.loadItem();
  }

  search(text: string): Annonce[] {
    return this.list.filter((item) => {
      const term = text.toLowerCase();
      return (
        item?.sujet?.toLowerCase().includes(term)
        || item?.description?.toLowerCase().includes(term)
        || item?.utilisateur?.nom?.toLowerCase().includes(term)
      );
    });
  }

  cleanDate(dateString){
    dateString = dateString.slice(0, -3); // remove the timezone offset
    return dateString;
  }

  private loadItem() {
    this.annonceService.getAnnonces().subscribe({next: res => {
        this.list = res;
        this.filter.reset();
        this.list$ = this.filter.valueChanges.pipe(
          startWith(''),
          map((text) => this.search(text)),
        );
      },error: (err) => {
        ToastTool.dangerReponseApi(err);
      }})
  }



  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    for (const header of this.headers) {
      if (header.sortable !== column) {
        header.direction = '';
      }
    }

    if (direction === '' || column === '') {
      this.loadItem();
    } else {
      this.list$ = this.list$.pipe(map(arr => arr.sort((a, b) => {
        console.log(column)
        let res = NgbdSortableHeader.compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      })));
    }
  }

  modifier(item: Annonce) {
    this.router.navigate(['parametres','annonces','edit', item.idInfo])
  }
}
