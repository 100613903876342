import {Crous} from './crous';

export class EtablissementClient {
  idEtablissementClient: number;
  nom: string;
  crous: Crous;
  adresse: string;
  pic: number;
  envoyeDemande: boolean;
  rneInList: string;
}
