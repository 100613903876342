import {Rne} from "./rne";
import {EtablissementClientDto} from "./etablissement-client-dto";

export class RnePut {
  nomUsuel: string;
  libelleCourt: string;
  localite: string;
  etablissementClient: EtablissementClientDto;


  constructor(rne: Rne) {
    this.nomUsuel = rne.nomUsuel;
    this.libelleCourt = rne.libelleCourt;
    this.localite = rne.localite;
    this.etablissementClient = new EtablissementClientDto(rne.etablissementClient);
  }
}
