import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {debounceTime,  startWith} from "rxjs";
import {FormControl} from "@angular/forms";
import {UserService} from "../../services/user.service";
import {ToastTool} from "../../tools/toast.tool";
import {NgbdSortableHeader, SortEvent} from "../../tools/ngbd-sortable-header.directive";
import {UtilisateurB4} from "../../models/utilisateur-b4";
import {Router} from "@angular/router";
import {ModalAlertComponent} from "../../tools/modal-alert/modal-alert.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import { saveAs } from 'file-saver';
import {NgxSpinnerService} from "ngx-spinner";


@Component({
  selector: 'app-utilisateur-list',
  templateUrl: './utilisateur-list.component.html',
  styleUrls: ['./utilisateur-list.component.css']
})
export class UtilisateurListComponent implements OnInit {

  list: Array<UtilisateurB4> = [];
  page = 1;
  pageSize = 15;
  total;
  filter = new FormControl('', {nonNullable: true});

  constructor(private userService: UserService, private modalService: NgbModal,
              private router: Router, private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.filter.valueChanges.pipe(
      debounceTime(300),
      startWith(''),
    ).subscribe(text => {
      this.loadItem(text);
    });
  }

  private loadItem(text='', column='nom', direction='asc') {
    const start = (this.page - 1) * this.pageSize;
    this.userService.getUtilisateursB4(start, text, column, direction).subscribe({next: res => {
        this.total = res.total;
        this.list = res.data;
      },error: (err) => {
        ToastTool.dangerReponseApi(err);
      }})
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    for (const header of this.headers) {
      if (header.sortable !== column) {
        header.direction = '';
      }
    }
    this.loadItem(this.filter.getRawValue(), column, direction);

  }


  modifier(item: UtilisateurB4) {
    this.router.navigate(['utilisateurs','edit',item.idUtilisateur]);
  }

  supprimer(item: UtilisateurB4) {
    const modal = this.modalService.open(ModalAlertComponent, {backdrop: 'static'})
    modal.componentInstance.title = 'Suppression d\'un utilisateur';
    modal.componentInstance.messageP1 = 'Vous allez supprimer un utilisateur';
    modal.componentInstance.messageP1Red = true;
    modal.componentInstance.messageP2 = 'Êtes vous sur ?';
    modal.componentInstance.messageP2Bold = true;
    modal.result.then(
      value => {
        this.userService.delete(item.idUtilisateur).subscribe({next: res => {
            ToastTool.success('Utilisateur supprimé');
            this.loadItem();
          },error: (err) => {
            ToastTool.dangerReponseApi(err);
          }})
      }
    ).catch(r => {
    });
  }

  seachUser($event: number) {
    this.page=$event;
    this.loadItem();

  }

  getXls() {
    this.spinner.show();
    this.userService.getUtilisateursB4Xls(this.filter.getRawValue()).subscribe({next: res => {
        const blob = new Blob([res.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'export.xlsx');
        this.spinner.hide();
      }, error: error => {
        this.spinner.hide();
      }
    });
  }
}
