import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {Crous} from '../models/crous';

@Injectable()
export class MenuService {

  private crousSelected = new Subject<Crous>();

  crousSelected$ = this.crousSelected.asObservable();

  constructor() { }

  publishData(crousSelected: Crous) {
    this.crousSelected.next(crousSelected);
  }


}
