<div class="loading" [hidden]="!loading"></div>
<div class="row">
  <div class="col">
    <div class="widget">
      <div class="row">
        <div class="col">
          <h4>Liste des Logs</h4>
        </div>
      </div>
      <div class="row">
        <div class="loading" [hidden]="!loading"></div>
        <div class="col">
          <table class="table table-striped table-bordered table-hover" style="width: 100%" *ngIf="resultatLoaded">
            <thead>
            <tr>
              <th style="width: 100px">Nb erreurs</th>
              <th style="width: 180px">Date</th>
              <th>Message</th>
              <th>AppId</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let h of serverResult?.data">
              <th>{{ h.nbErreur }}</th>
              <th>{{ h.dateLastInsertion | dateHeureFormat }}</th>
              <th>{{ h.message }}</th>
              <th>{{ h.appId }}</th>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
      <div class="row"  *ngIf="resultatLoaded && affichePagination" [hidden]="loading">
        <div class="col"><ngb-pagination *ngIf="resultatLoaded" class="d-flex justify-content-end"
                                         [collectionSize]="total" [(page)]="page" [pageSize]="pageSize" maxSize="5" (pageChange)="search($event)">
        </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
