import {CrousDto} from "./crous-dto";
import {EtablissementClient} from "./etablissement-client";

export class EtablissementClientPostPut {
  nom: string;
  adresse: string;
  crous: CrousDto;

  constructor(etablissementClient: EtablissementClient) {
    this.nom = etablissementClient.nom;
    this.adresse = etablissementClient.adresse;
    this.crous = new CrousDto(etablissementClient.crous)
  }
}
