import {Component, OnInit} from '@angular/core';
import {EmetteurZdcContratService} from "../../../services/emetteur-zdc-contrat.service";
import {EmetteurZdcContrat} from "../../../models/emetteur-zdc-contrat";

@Component({
  selector: 'app-liste-contrats',
  templateUrl: './liste-contrats.component.html',
  styleUrls: ['./liste-contrats.component.css']
})
export class ListeContratsComponent implements OnInit {

  listeContrats: EmetteurZdcContrat[] = [];


  constructor() {
  }

  ngOnInit(): void {
  }


}
