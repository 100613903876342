import { Directive, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import {Crous} from "../models/crous";


export type SortColumn = '';
export type SortDirection = 'asc' | 'desc' | '';
const rotate: { [key: string]: SortDirection } = { asc: 'desc', desc: '', '': 'asc' };

export interface SortEvent {
  column: string; // SortColumn
  direction: SortDirection;
}

@Directive({
  selector: 'th[sortable]',
  standalone: true,
  host: {
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
    '(click)': 'rotate()',
  },
})
export class NgbdSortableHeader {
  @Input() sortable: SortColumn = '';
  @Input() direction: SortDirection = '';
  @Output() sort = new EventEmitter<SortEvent>();

  rotate() {
    this.direction = rotate[this.direction];
    this.sort.emit({ column: this.sortable, direction: this.direction });
  }

  static compare = (v1: any, v2: any) => {
    if (!v1 && !v2) {
      v1=0;
      v2=1000000;
    } else if (!v1 && isNaN(v2)) {
      v1='';
    } else if (!v1) {
      v1=0;
    } else if (!v2 && isNaN(v1)) {
      v2='';
    } else if (!v2) {
      v2=0;
    }
    if (isNaN(v1)) {
      return v1.localeCompare(v2);
    } else {
      return Number(v1) - Number(v2);
    }
  };
}
