import {AyantDroit} from './ayant-droit';
import {GetInfoCarteCrous} from "./get-info-cartecrous";

export class SearchAyantDroit {

  data: Array<AyantDroit>;
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;


  static fromGetInfoCarteCrous(res: GetInfoCarteCrous[]) : SearchAyantDroit {
    const arrayAd = res.map(r => GetInfoCarteCrous.toAyantDroit(r))
    const searchAyantDroit = new SearchAyantDroit();
    searchAyantDroit.data = arrayAd;
    searchAyantDroit.recordsTotal = arrayAd.length;
    return searchAyantDroit;
  }
}
