<div class="container-fluid mt-3">
  <h4>Aides Saga</h4>
  <div class="loading" [hidden]="!loading"></div>
  <div class="mb-3 row">
    <form class="col-xs-3 col-sm-auto">
      <div class="filtre-rne mb-3 row">
        <label for="table-filtering-search" class="col-xs-3 col-sm-auto col-form-label">Recherche multi-critères :</label>
        <div class="col-xs-3 col-sm-auto">
          <input id="table-filtering-search" type="text"  class="form-control" [formControl]="filter"/>
        </div>
      </div>
    </form>
    <div class="col-xs-3 col-sm-auto">
      <div class="input-group">
        <select class="form-select form-control" [(ngModel)]="codeEtat" (change)="changeEtat()" id="emetteur" type="text" name="emetteur" required #em>
          <option value="3">Validé, crédit en attente</option>
          <option value="5">Compte Izly Crédité</option>
          <option value="9">Supprimé</option>
        </select>
      </div>
    </div>
  </div>

  <table class="table table-striped table-bordered table-hover">
    <thead>
    <tr>
      <th scope="col" sortable="idSaga" (sort)="onSort($event)">Id Saga</th>
      <th scope="col" sortable="nom" (sort)="onSort($event)">Nom</th>
      <th scope="col" sortable="prenom" (sort)="onSort($event)">Prénom</th>
      <th scope="col" sortable="crous.libelle" (sort)="onSort($event)">Crous</th>
      <th scope="col" sortable="ine" (sort)="onSort($event)">Ine</th>
      <th scope="col" sortable="crous.libelle" (sort)="onSort($event)">Date de naissance</th>
      <th scope="col" sortable="crous.libelle" (sort)="onSort($event)">Montant</th>
      <th scope="col" sortable="crous.libelle" (sort)="onSort($event)">Date de crédit</th>
      <th scope="col" sortable="crous.libelle" (sort)="onSort($event)">Valide jusqu'au</th>
      <th scope="col" sortable="crous.libelle" (sort)="onSort($event)">Etat</th>
      <th scope="col">Action</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let aide of aideSaga$ | async | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
      <td><ngb-highlight [result]="aide.idSaga.toString()" [term]="filter.value"></ngb-highlight></td>
      <td><ngb-highlight [result]="aide.nom" [term]="filter.value"></ngb-highlight></td>
      <td><ngb-highlight [result]="aide.prenom" [term]="filter.value"></ngb-highlight></td>
      <td><ngb-highlight [result]="aide.crous.libelle" [term]="filter.value"></ngb-highlight></td>
      <td><ngb-highlight [result]="aide.ine" [term]="filter.value"></ngb-highlight></td>
      <td><ngb-highlight [result]="aide.dateNaissance" [term]="filter.value"></ngb-highlight></td>
      <td><ngb-highlight [result]="aide.montant.toString()" [term]="filter.value"></ngb-highlight></td>
      <td><ngb-highlight [result]="aide.dateCredit | date: 'dd/MM/yyyy hh:mm'" [term]="filter.value"></ngb-highlight></td>
      <td><ngb-highlight [result]="aide.dateForclusion | date: 'dd/MM/yyyy'" [term]="filter.value"></ngb-highlight></td>
      <td><ngb-highlight [result]="aide.aideSagaEtat.libelle" [term]="filter.value"></ngb-highlight></td>
      <td>
        <button value="button" class="btn btn-primary" [routerLink]="[aide.idSaga]">Détails</button>
      </td>
    </tr>
    </tbody>
  </table>
  <div class="align-content-center">
    <ngb-pagination [pageSize]="pageSize" [collectionSize]="(aideSaga$ | async) ? (aideSaga$ | async).length : aideSaga.length" [(page)]="page" aria-label="Default pagination"></ngb-pagination>
  </div>

</div>
