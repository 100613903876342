import {Component, ElementRef, EventEmitter, ViewChild} from '@angular/core';
import * as ExcelJS from 'exceljs';
import {ExcelAd} from "../../../models/excel-ad";
import {ExcelError} from "../../../models/excel-error";
import {Utils} from "../../../tools/Utils";
import {CheckIneService} from "../../../services/check-ine.service";
import * as Joi from 'joi';
import {ImportAyantDroitService} from "../../../services/import-ayant-droit.service";
import {HttpErrorResponse, HttpEventType, HttpHeaders, HttpResponse} from "@angular/common/http";
import {ToastTool} from "../../../tools/toast.tool";
import {Crous} from "../../../models/crous";
import {CrousService} from "../../../services/crous.service";
import {environment} from "../../../../environments/environment";
import {ModalAlertComponent} from "../../../tools/modal-alert/modal-alert.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-import-ayant-droit',
  templateUrl: './import-ayant-droit.component.html',
  styleUrls: ['./import-ayant-droit.component.css']
})
export class ImportAyantDroitComponent {


  loading = false;

  @ViewChild('inputFile')
  inputFile: ElementRef;

  file: File;
  formErreur = '';
  rowQuantity = 0;
  excelError = new Array<ExcelError>;
  excelErrorSize=0;
  excelErrorExtrait  = new Array<ExcelError>;
  excelObjArray = new Array<ExcelAd>
  ineArray = new Array<string>;
  progress: number = 0;
  crousList: Array<Crous>;

  idCrousSelected: number;
  forceTarif = false;
  razRnePrioritaire= false;

  page = 1;
  pageSize = 5;


  constructor(private checkIneService: CheckIneService,
              private crousService: CrousService,
              private importAdService: ImportAyantDroitService, private modalService: NgbModal){
    crousService.listCrousAutorises().subscribe(c => {
      this.crousList = c;
      this.idCrousSelected = c[0].numeroCrous;
    });
  }

  onFileSelected(event: Event) {
    this.formErreur='';
    this.rowQuantity=0;
    this.excelErrorSize=0;
    this.excelError = new Array<ExcelError>;
    this.excelObjArray = new Array<ExcelAd>
    this.ineArray = new Array<string>;
    this.file = event.target['files'][0];
    if (!this.file || this.file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      this.formErreur =  'Le fichier excel doit être de type <b>.xlsx</b>';
      return;
    }

    this.readFile();

  }

  readFile() {
    if (!this.file) {
      this.formErreur = 'Veuillez sélectionner un fichier';
      return;
    }

    this.loading = true;

    const wb = new ExcelJS.Workbook();

    var reader = new FileReader();
    reader.onload = (e) => {

      wb.xlsx.load(e.target.result as ArrayBuffer).then(workbook => {

        const idSheet = workbook.worksheets[0].id;

        const colonnes = workbook.getWorksheet(idSheet).getRow(1);
        const nbColonnes = colonnes.cellCount

        if (nbColonnes <6) {
          console.log(workbook.getWorksheet(idSheet).getRow(1).values)
          this.formErreur = `Le fichier n'est pas au format attendu, il contient seulement ${nbColonnes} colonne${nbColonnes > 1 ? 's' : ''}`;
          this.loading = false;
          return;
        }

        if ("nom" !== colonnes.getCell(1).value.toString().toLowerCase()) {
          console.log(workbook.getWorksheet(idSheet).getRow(1).values)
          this.formErreur = `Le fichier n'est pas au format attendu, la première colonne doit être 'Nom'`;
          this.loading = false;
          return;
        }

        this.testFile(workbook, idSheet);


      })
    };
    reader.readAsArrayBuffer(this.file);
  }

  submit() {

    let headers = new HttpHeaders();
    const formData = new FormData();
    formData.append('file', this.file, this.file.name);
    formData.append('forceTarif', this.forceTarif.toString());
    formData.append('razRnePrioritaire', this.razRnePrioritaire.toString());
    formData.append('idCrous', this.idCrousSelected.toString());

    this.loading = true;

    this.importAdService.upload(formData).subscribe({
      next : event=>{
        if (event.type === HttpEventType.UploadProgress) {
          this.progress = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
          this.resetForm();
          ToastTool.success('Le fichier a correctement été envoyé et va être importé');
        }
        this.loading = false;
      },
      error : err => {
        for (let error of err.error) {
          let description = error.error_description;
          ToastTool.danger(description);
          this.resetForm();
          this.loading = false;
        }
      }
    });
    this.file = undefined;
  }

  testFile(workbook: ExcelJS.Workbook, idSheet: number){
      workbook.getWorksheet(idSheet).eachRow((row, rowIndex) => {
        this.rowQuantity++;
        if (rowIndex > 1) {
          this.testLigne(row, rowIndex);
        }
      });

      if (this.excelObjArray && this.excelObjArray.length>0) {
        const uniqueSchema = Joi.array().unique('email').error(errors => {
          errors.forEach(err => {
            err.message = `${err.local.dupePos+2}-l'email <b>${err.local.value.email}</b> est réutilisé à la ligne ${err.local.pos+2}`
          });
          return errors;
        })
        const { error, value } = uniqueSchema.validate(this.excelObjArray, Utils.joiOptions);
        if (error) {
          const line = error.message.substring(0, error.message.indexOf('-'))
          this.addError(Number(line), error.message.substring(error.message.indexOf('-')+1));
        }
      }

    if (this.ineArray && this.ineArray.length>0) {
      const uniqueSchema = Joi.array().unique().error(errors => {
        for (const err of errors) {
          err.message = `${err.local.dupePos+2}-l'INE <b>${err.local.value}</b> est réutilisé à la ligne ${err.local.pos+2}`
        }
        return errors;
      })
      const { error, value } = uniqueSchema.validate(this.ineArray, Utils.joiOptions);
      if (error) {
        const line = error.message.substring(0, error.message.indexOf('-'))
        this.addError(Number(line), error.message.substring(error.message.indexOf('-')+1));
      }
    }

      this.excelErrorSize=this.excelError.length;
      this.errorNext();
    this.loading = false;
  }


  private testLigne(row : ExcelJS.Row, rowIndex: number) {

    const ad = ExcelAd.excelToObj(row);
    this.excelObjArray.push(ad);
    if (ad.ine) {
      this.ineArray.push(ad.ine);
    } else {
      this.ineArray.push(`${rowIndex}`);
    }


    const emailObj = Joi.object({
      hyperlink:Joi.string(),
      text:Joi.string()});

    const schema = Joi.object({
      nom: Joi.string().required().min(1).max(254),
      prenom: Joi.string().required().min(1).max(254),
      email: Joi.alternatives().conditional('email', {is: emailObj, then:Joi.any(), otherwise: Joi.string().email({ tlds: { allow: false } }) }),
      rneEtab: Joi.string().allow("").allow(null),
      codeSociete: Joi.number().min(1),
      codeTarif: Joi.number().min(1),
      dateNaissance: Joi.custom(this.testDate),
      codeEtudiant: Joi.any(),
      emailPerso: Joi.alternatives().conditional('email', {is: emailObj, then:Joi.any(), otherwise: Joi.string().allow("").allow(null).email({ tlds: { allow: false } })}),
      ine: Joi.when('codeSociete', {
        is: Joi.number().valid(10),
        then:Joi.custom(this.testINE)
      }),
      telephone: Joi.string().allow("").allow(null).regex(/^(0|\+33)(6|7)[0-9]{8}$/).error(errors => {
        errors.forEach(err => {
          err.message = 'Le téléphone n\'a pas le bon format (formats acceptés : 06xxxxxxxx, 07xxxxxxxx, +33xxxxxxxxx)';
        });
        return errors;
      }),
      rneComp: Joi.string().allow("").allow(null).max(254),
      dateApplicationFutur: Joi.custom(this.testDate),
      codeSocieteFutur: Joi.number().allow(null).min(1),
      codeTarifFutur: Joi.number().allow(null).min(1),
      crous: Joi.number().allow(null).valid(86,44,35,20,76,31,38,75,69,51,45,78,13,80,971,25,63,14,94,21,59,87,34,54,6,974,67,5,15,90).error(errors => {
        errors.forEach(err => {
          err.message = 'Le code <b>crous</b> ne correspond a aucun crous';
        });
        return errors;
      }),
    });

    const { error, value } = schema.validate(ad, Utils.joiOptions);
    if (error) {
      this.addError(rowIndex, error.message
        .replace(/"/g,'')
        .replace(/\./g,' |'));
    }

  }




  testINE = (value, helpers) => {
    if (!value || !this.checkIneService.isCorrectIne(value)) {
      return helpers.error("ine.invalid");
    }
    return value;
  };

  testDate =  (value, helpers) => {
    if (value && value instanceof Date) {
      return value;
    }
    if (value && !Utils.isValidDate(value)) {
      return helpers.error("date.strict");
    }
    return value;
  }

  addError(rowIndex, message) {
    const row = this.excelError.find(e => e.rowId === rowIndex);
    if (row) {
      row.errors.push(message)
    } else {
      const e = new ExcelError();
      e.id = this.excelError.length+1;
      e.rowId=rowIndex;
      e.errors.push(message)
      this.excelError.push(e);
    }
  }


  errorNext() {
    this.excelErrorExtrait = this.excelError.map((err, i) => ({ id: i + 1, ...err })).slice(
      (this.page - 1) * this.pageSize,
      (this.page - 1) * this.pageSize + this.pageSize,
    );
  }


  private resetForm() {
    this.file = null;
    this.formErreur = '';
    this.rowQuantity = 0;
    this.excelError = new Array<ExcelError>;
    this.excelErrorSize=0;
    this.excelErrorExtrait  = new Array<ExcelError>;
    this.progress = 0;
    this.inputFile.nativeElement.value='';
    this.forceTarif = false;
    this.razRnePrioritaire= false;
  }

  protected readonly environment = environment;

  alertImportV2() {
    const modal = this.modalService.open(ModalAlertComponent, {backdrop: 'static'})
    modal.componentInstance.title = 'Import V1';
    modal.componentInstance.messageP1 = 'L\'import V1 va bientôt être supprimé, si l\'import actuel n\'a pas fonctionné, merci de créer un ticket sur tickit en joignant le fichier qui pose problème.';
    modal.componentInstance.messageP2 = 'Le fichier doit être un fichier au format .xlsx dans le nouvel import';
    modal.componentInstance.messageP2Bold = true;
    modal.componentInstance.messageP3 = 'Allez quand même sur l\'import V1 ?';
    modal.result.then(
      value => {
        location.href=environment.beforeizlyDomain + '/client/import/import.jsf'
      }
    ).catch(r => {
    });
  }

  getXls() {

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Feuille1');

    worksheet.columns = [
      {header: 'ligne', key: 'rowId'},
      {header: 'erreurs', key: 'erreur'}
    ]

    for (const contrat of this.excelError) {

      const data = {
        rowId: contrat.rowId,
        erreur: this.formatError(contrat.errors)
      };

      worksheet.addRow(data).commit();

    }

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      saveAs(blob, `contrats.xlsx`);
    });
  }

  private formatError(errors: Array<string>) {
      return errors.map(e => {return e.replace("<b>","").replace("</b>","")}).join(", ")
  }
}
