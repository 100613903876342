import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Rightholder} from '../models/rightholder';
import {Card} from '../models/card';
import {TarifCalcul} from '../models/tarif-calcul';
import {HistoriqueRne} from '../models/historique-rne';
import {UntypedFormGroup} from "@angular/forms";
import {Observable} from "rxjs";
import {SmartCard} from "../models/smart-card";


@Injectable()
export class RightholderService {


  constructor(private http: HttpClient) {
  }

  get(identifier: string) : Observable<Rightholder> {
    return this.http.get<Rightholder>(environment.apiB4Url + '/v1/rightholders/' + identifier, { withCredentials: true });
  }

  getWithCard(idZdc: number) : Observable<Rightholder> {
    return this.http.get<Rightholder>(environment.apiB4Url + '/v1/smartcard/' + idZdc + '/rightholders', { withCredentials: true });
  }

  renvoieMail(identifier: string)  : Observable<Rightholder> {
    return this.http.get<Rightholder>(environment.apiB4Url + '/v1/rightholders/' + identifier + '?renvoiActivation=1', { withCredentials: true });
  }

  getHistoriqueRne(identifier: string) {
    return this.http.get<Array<HistoriqueRne>>(environment.apiB4Url + '/v1/ayantdroits/' + identifier + '/historique-rne', { withCredentials: true });
  }

  getSmartCard(identifier: string) {
    return this.http.get<Array<Card>>(environment.apiB4Url + '/v1/rightholders/' + identifier + '/smartcard', { withCredentials: true });
  }

  calculTarif(idEtablissementClient: number, statut: string, indice: number) {

    const payload = '{\"indice\":' + indice + ',\"statut\":\"' + statut + '\",\"idEtablissementClient\":' + idEtablissementClient + '}';
    return this.http.post<TarifCalcul>(
      environment.apiB4Url + '/v1/tarif/search',
      payload,
      { withCredentials: true, headers: new HttpHeaders().set('Content-type', 'application/json') });

  }

  transfertCarte(identifiantSource: string, identifiantCible: string) {
    const payload = '{\"identifiantSource\":\"' + identifiantSource + '\",\"identifiantCible\":\"' + identifiantCible + '\"}';
    return this.http.post(
      environment.apiB4Url + '/v1/rightholders/transfert-cartes',
      payload,
      { withCredentials: true, headers: new HttpHeaders().set('Content-type', 'application/json') });
  }

  post(rightholder: Rightholder) {
    return this.http.post<Rightholder>(environment.apiB4Url + '/v1/rightholders', rightholder, { withCredentials: true });
  }

  deleteClientCloture(idClient: number, searchForm: UntypedFormGroup) {

    const payload = new HttpParams()
      .set('nom', searchForm.get('nom').value)
      .set('prenom', searchForm.get('prenom').value)
      .set('identifiant', searchForm.get('identifiant').value)
      .set('email', searchForm.get('email').value)
      .set('numZdc', searchForm.get('numZdc').value);

    return this.http.post(environment.apiB4Url + '/v1/ayantdroits/cloture/' + idClient, searchForm.getRawValue(), { withCredentials: true });
  }

  delete(identifier: string) {
    return this.http.delete<Rightholder>(environment.apiB4Url + '/v1/ayantdroits/' + identifier, { withCredentials: true });
  }
  put(rightholder: Rightholder) {
    return this.http.put<Rightholder>(environment.apiB4Url + '/v1/rightholders/' + rightholder.identifier,
      rightholder, { withCredentials: true });
  }

  patchCard(rightholder: Rightholder, idzdc: string, uid: string, request) {
    return this.http.patch(environment.apiB4Url + '/v1/rightholders/' + rightholder.identifier + '/smartcard/' + idzdc,
      request, {  withCredentials: true,  headers: new HttpHeaders().append('uid', uid) } );
  }

  resendCard(rightholder: Rightholder, idzdc: string, uid: string, request) {
    return this.http.post(environment.apiB4Url + '/v1/rightholders/' + rightholder.identifier + '/smartcard/' + idzdc,
      request, {  withCredentials: true ,  headers: new HttpHeaders().append('uid', uid) } );
  }

  addCard(identifier: string, smartCard: SmartCard) {
    return this.http.post(environment.apiB4Url + '/v1/rightholders/' + identifier + '/smartcard', smartCard, {  withCredentials: true  } );
  }

}
