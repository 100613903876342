import {AyantDroit} from "./ayant-droit";
import {EtablissementClient} from "./etablissement-client";

export class GetInfoCarteCrous {
  nom: string;
  codeSociete: number;
  numTarif: number;
  courriel: string;
  rneEtablissement: string;
  rneComposante: string;
  codeEtu: string;
  prenom: string;
  dateNaissance: string;
  codeClient: string;
  telPortable: string;
  dateFinValidite: string;
  numeroAffectation: number;
  idIzly: number;

  static toAyantDroit(r: GetInfoCarteCrous) : AyantDroit {
    const ad = new AyantDroit();
    const etab = new EtablissementClient();
    etab.nom = r.rneEtablissement;
    ad.etablissementClient = etab;
    ad.nom = r.nom;
    ad.prenom = r.prenom;
    ad.codesociete = r.codeSociete;
    ad.codetarif = r.numTarif;
    ad.courrielInstitutionnel = r.courriel;
    ad.identifiantClient = r.codeClient;
    ad.origine = 'IMPORTIZLY';
    ad.utiliseSocieteTarifForce=false;
    return ad;
  }
}
