import { Component } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {SearchApiLogsService} from "../search-api-logs.service";

@Component({
  selector: 'app-from-logs-api',
  templateUrl: './from-logs-api.component.html',
  styleUrls: ['./from-logs-api.component.css']
})
export class FromLogsApiComponent {

  searchForm: UntypedFormGroup;
  formSubmited = false;



  constructor(private searchApiLogsService: SearchApiLogsService,private fb: UntypedFormBuilder) {
    this.searchForm = fb.group({
      start: fb.control('0'),
      terme: fb.control(''),
      searchType: fb.control('izly')
    });
  }

  search() {
    this.formSubmited=true;
    if (this.searchForm.valid) {
      this.formSubmited=false;
      this.searchApiLogsService.publishData(this.searchForm);
    }
  }


  get terme() { return this.searchForm.get('terme'); }

}
