<div class="container-fluid mt-3">
  <h4>Demandes d'accès à beforeizly</h4>
  <div class="mb-3 row">
    <form class="col-xs-3 col-sm-auto">
      <div class="filtre-rne mb-3 row">
        <label for="table-filtering-search" class="col-xs-3 col-sm-auto col-form-label">Recherche multi-critères :</label>
        <div class="col-xs-3 col-sm-auto">
          <input id="table-filtering-search" type="text"  class="form-control" [formControl]="filter"/>
        </div>
      </div>
    </form>
  </div>

  <table class="table table-striped table-bordered table-hover">
    <thead>
    <tr>
      <th scope="col" sortable="idUtilisateur" (sort)="onSort($event)">#</th>
      <th scope="col" sortable="nom" (sort)="onSort($event)">Nom</th>
      <th scope="col" sortable="prenom" (sort)="onSort($event)">Prénom</th>
      <th scope="col" sortable="courriel" (sort)="onSort($event)">Courriel</th>
      <th scope="col" sortable="oauthId" (sort)="onSort($event)">ID Oauth</th>
      <th scope="col" sortable="oauthEtab" (sort)="onSort($event)">Établissement</th>
      <th scope="col" sortable="oauthJob" (sort)="onSort($event)">Emploi</th>
      <th scope="col" sortable="oauthSiret" (sort)="onSort($event)">Siret</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of list">
      <td>{{item.idUtilisateur}}</td>
      <td><ngb-highlight [result]="item.nom" [term]="filter.value"></ngb-highlight></td>
      <td><ngb-highlight [result]="item.prenom" [term]="filter.value"></ngb-highlight></td>
      <td><ngb-highlight [result]="item.courriel" [term]="filter.value"></ngb-highlight></td>
      <td><ngb-highlight [result]="item.oauthId" [term]="filter.value"></ngb-highlight></td>
      <td><ngb-highlight [result]="item.oauthEtab" [term]="filter.value"></ngb-highlight></td>
      <td><ngb-highlight [result]="item.oauthJob" [term]="filter.value"></ngb-highlight></td>
      <td><ngb-highlight [result]="item.oauthSiret" [term]="filter.value"></ngb-highlight></td>
      <td style="width: 150px"><a class="btn btn-warning" (click)="modifier(item)"> Activer l'accès </a></td>
      <td style="width: 70px"><a class="btn btn-danger" (click)="supprimer(item)" *ngIf="me && Me.isPossedeDroit(me, 1000)"> Supprimer </a></td>
    </tr>
    </tbody>
  </table>
  <div class="align-content-center">
    <ngb-pagination
                    [collectionSize]="total" [(page)]="page" [pageSize]="pageSize" maxSize="5" (pageChange)="seachUser($event)">
    </ngb-pagination>
  </div>


</div>
