
export class InfoCarteCrous{

  nom: String;
  idCrous: number;
  absentBeforeSeulement = true;
  constructor(nom: String, idCrous: number) {
    this.nom = nom;
    this.idCrous = idCrous;
  }

}
