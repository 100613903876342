<div class="container-fluid mt-3" *ngIf="aide">
  <h4>Détail de l'aide</h4>
  <div class="loading" [hidden]="!loading"></div>


    <div class="row mt-5">
      <div class="col-md-12 form-horizontal">
        <div class="form-group mb-0"><label for="numsaga" class="col-sm-2 control-label">Numéro Saga</label>
          <span id="numsaga" class="col-sm-5 control-label" style="text-align:left">{{aide.idSaga}}</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 form-horizontal">
        <div class="form-group mb-0"><label for="nom" class="col-sm-2 control-label">Nom</label>
          <span id="nom" class="col-sm-5 control-label" style="text-align:left"  [class.text-danger]="ayantDroit && ayantDroit?.lastName != aide.nom">{{aide.nom}}</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 form-horizontal">
        <div class="form-group mb-0"><label for="prenom" class="col-sm-2 control-label">Prénom</label>
          <span id="prenom" class="col-sm-5 control-label" style="text-align:left" [class.text-danger]="ayantDroit && ayantDroit?.firstName != aide.prenom">{{aide.prenom}}</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 form-horizontal">
        <div class="form-group mb-0"><label for="prenom" class="col-sm-2 control-label">Crous</label>
          <span id="crous" class="col-sm-5 control-label" style="text-align:left">{{aide.crous.libelle}}</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 form-horizontal">
        <div class="form-group mb-0"><label for="ine" class="col-sm-2 control-label">INE</label>
          <span id="ine" class="col-sm-5 control-label" style="text-align:left">{{aide.ine}}</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 form-horizontal">
        <div class="form-group mb-0"><label for="dn" class="col-sm-2 control-label">date de Naissance</label>
          <span id="dn" class="col-sm-5 control-label" style="text-align:left">{{aide.dateNaissance}}</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 form-horizontal">
        <div class="form-group mb-0"><label for="montant" class="col-sm-2 control-label">montant</label>
          <span id="montant" class="col-sm-5 control-label" style="text-align:left">{{aide.montant}}</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 form-horizontal">
        <div class="form-group mb-0"><label for="validite" class="col-sm-2 control-label">Valide jusqu'au</label>
          <span id="validite" class="col-sm-5 control-label" style="text-align:left">{{aide.dateForclusion | date: 'dd/MM/yyyy'}}</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 form-horizontal">
        <div class="form-group mb-0"><label for="etat" class="col-sm-2 control-label">Etat</label>
          <span id="etat" class="col-sm-5 control-label" style="text-align:left">{{aide.aideSagaEtat.libelle}}</span>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-2">
        <button type="button" class="btn btn-primary mt-3" *ngIf="aide.aideSagaEtat.codeEtat == 3" (click)="lectureCarte()">Lecture de la carte</button>
      </div>
      <div class="col-1 align-self-end" style="padding-bottom: 5px">ou</div>
      <div class="col-2 align-self-end">
        <input type="text" class="form-control" placeholder="Identifiant Cnous" [(ngModel)]="zdc.identifiantCnous">
      </div>
      <div class="col-2 align-self-end">
        <input type="text" class="form-control" placeholder="Numero applicatif" [(ngModel)]="zdc.uid">
      </div>

    </div>

  <div class="col-2 align-self-end">
    <button type="button" class="btn btn-primary mt-3" *ngIf="aide.aideSagaEtat.codeEtat == 3" (click)="recherche()">Recherche l'étudiant</button>
  </div>

    <div class="row" *ngIf="ayantDroitInconnu">
      <div class="col-md-12 form-horizontal text-danger">
        Cette carte n'est associée à aucun ayant-droit.
      </div>
    </div>

  <div *ngIf="ayantDroit">
  <div class="row">
    <div class="col-md-4">
      <hr>
    </div>
  </div>
    <div class="row mb-3" *ngIf="ayantDroit?.lastName != aide.nom">
      <div class="col-md-12 form-horizontal text-danger">
        Attention, le nom du compte izly ne correspond pas au nom de l'étudiant aidé.
      </div>
    </div>
  <div class="row">
    <div class="col-md-12 form-horizontal">
      <div class="form-group mb-0"><label for="nomAd" class="col-sm-2 control-label">Nom ayant-droit</label>
        <span id="nomAd" class="col-sm-5 control-label" style="text-align:left" [class.text-danger]="ayantDroit?.lastName != aide.nom">{{ayantDroit?.lastName}}</span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 form-horizontal">
      <div class="form-group mb-0"><label for="prenomAd" class="col-sm-2 control-label">Prénom ayant-droit</label>
        <span id="prenomAd" class="col-sm-5 control-label" style="text-align:left" [class.text-danger]="ayantDroit?.firstName != aide.prenom">{{ayantDroit?.firstName}}</span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 form-horizontal">
      <div class="form-group mb-0"><label for="identifiantAd" class="col-sm-2 control-label">identifiant ayant-droit</label>
        <span id="identifiantAd" class="col-sm-5 control-label" style="text-align:left">{{ayantDroit?.identifier}}</span>
      </div>
    </div>
  </div>

  <button type="button" class="btn btn-danger mt-3" *ngIf="aide.aideSagaEtat.codeEtat == 3 && ayantDroit" (click)="creditAide()">Créditer l'aide</button>
  </div>

</div>
