<div class="mt-5 d-flex justify-content-center align-items-center" style="height: 50vh;" *ngIf="me">

  <div class="container">
    <div class="row row-cols-sm-4">
      <div class="col">
        <div class="card" style="min-height:14rem;margin-top: 0.5rem;" *ngIf="Me.isPossedeDroit(me,[1, 2, 7])">
          <div class="card-body" routerLink="/ad/search/list">
            <h4 class="card-title">Rechercher un Ayant-droit</h4>
            <p class="card-text">Accéder à la recherche multi-critères des ayants-droits Izly.</p>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card" style="min-height:14rem;margin-top: 0.5rem;" *ngIf="Me.isPossedeDroit(me,[1, 2])">
          <div class="card-body" routerLink="/ad/search/ad">
            <h4 class="card-title">Ajouter un Ayant-droit</h4>
            <p class="card-text">Ajouter un ayant-droit via formulaire.</p>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card" style="min-height:14rem;margin-top: 0.5rem;" *ngIf="Me.isPossedeDroit(me,[1, 2])">
          <div class="card-body" routerLink="/import-ad">
            <h4 class="card-title">Import Excel</h4>
            <p class="card-text">Importer des Ayants-droit via import excel.</p>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="col">
          <div class="card" style="min-height:14rem;margin-top: 0.5rem;" *ngIf="Me.isPossedeDroit(me,[6])">
            <div class="card-body" routerLink="/saga/aides">
              <h4 class="card-title">Aides Saga</h4>
              <p class="card-text">Créditer une aide saga sur Izly.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="col">
          <div class="card" style="min-height:14rem;margin-top: 0.5rem;" *ngIf="Me.isPossedeDroit(me,[1000,2000])">
            <div class="card-body" routerLink="/utilisateurs/list">
              <h4 class="card-title">Utilisateur BeforeIzly</h4>
              <p class="card-text">Gérer les habilitations à Before-Izly.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>




</div>
