export class EmetteurZdc {
  idEmetteurZdc: number;
  raisonSociale: string;
  adresse: string = '';
  adresse2: string;
  codePostal: string;
  ville: string;
  url: string;
  dateInsertion: Date;
}
