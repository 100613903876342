<div class="row">
<div class="col-md-3 bd-sidebar collapse width show" id="leftmenu" style="width: 320px; padding: 1rem">
  <h5 style="margin-top: 10px">Interroger l'API statut Boursier</h5>
  <div class="row">
      <form class="bd-search" novalidate (ngSubmit)="search()" [formGroup]="form"  *ngIf="form">

        <div class="mb-3">
          <input type="text" class="form-control" id="ine" placeholder="Ine" formControlName="ine" [ngClass]="{'is-invalid': !form.valid  && (ine.dirty || ine.touched || formSubmited)}">
          <div class="form-group type-query-input" *ngIf="!form.valid && (ine.dirty || ine.touched || formSubmited)">
            <small id="ineHelp" class="text-danger">
              11 caractères.
            </small>
          </div>
        </div>



        <div class="form-group mb-5" style="margin-top: 10px">
          <button class="btn btn-primary" type="submit">
            <span class="btn-text">Rechercher</span>
          </button>
        </div>
      </form>
  </div>
  <div class="row">
    <form class="bd-search" novalidate (ngSubmit)="search2()" [formGroup]="form2"  *ngIf="form2">
      <div class="mb-3">
        <input type="text" class="form-control" id="given_name" placeholder="Prénom" formControlName="given_name" [ngClass]="{'is-invalid': !form2.valid  && (given_name.dirty || given_name.touched || formSubmited)}">
        <div class="form-group type-query-input" *ngIf="!form2.valid && (given_name.dirty || given_name.touched || formSubmited)">
          <small id="given_nameHelp" class="text-danger">
            Obligatoire.
          </small>
        </div>
      </div>

      <div class="mb-3">
        <input type="text" class="form-control" id="family_name" placeholder="Nom" formControlName="family_name" [ngClass]="{'is-invalid': !form2.valid  && (family_name.dirty || family_name.touched || formSubmited)}">
        <div class="form-group type-query-input" *ngIf="!form2.valid && (family_name.dirty || family_name.touched || formSubmited)">
          <small id="family_nameHelp" class="text-danger">
            Obligatoire.
          </small>
        </div>
      </div>

      <div class="mb-3">
        <input type="text" class="form-control" id="birthdate" placeholder="Date de naissance" formControlName="birthdate" [ngClass]="{'is-invalid': !form2.valid  && (birthdate.dirty || birthdate.touched || formSubmited)}">
        <div class="form-group type-query-input" *ngIf="!form2.valid && (birthdate.dirty || birthdate.touched || formSubmited)">
          <small id="passwordHelp" class="text-danger">
            Obligatoire.
          </small>
        </div>
      </div>


      <div class="form-group" style="margin-top: 10px">
        <button class="btn btn-primary" type="submit">
          <span class="btn-text">Rechercher</span>
        </button>
      </div>
    </form>
  </div>

</div>
  <div class="col mt-4" *ngIf="result">
    {{result }}
  </div>
  <div class="col mt-4" *ngIf="resultApiBoursier">
    <p>Prénom : {{resultApiBoursier.firstName}}</p>
    <p>Nom : {{resultApiBoursier.lastName}}</p>
    <p>Date de naissance : {{resultApiBoursier.birthDate.substring(0,10)}}</p>
    <p>email : {{resultApiBoursier.email}}</p>
    <p>campaign : {{resultApiBoursier.campaign.year}}</p>
    <p>boursier : {{booleanToFrench(resultApiBoursier.boursier)}}</p>
    <p>grantStatus : {{resultApiBoursier.grantStatus}}</p>
    <p>grantEchelon : {{resultApiBoursier.grantEchelon}}</p>
    <p>grantEchelonAglae : {{resultApiBoursier.grantEchelonAglae}}</p>
    <p>grantLevel : {{resultApiBoursier.grantLevel}}</p>
    <p>paymentDuration : {{resultApiBoursier.paymentDuration}}</p>
    <p>region : {{resultApiBoursier.region.name}}</p>
    <p>schoolName : {{resultApiBoursier.schoolName}}</p>
  </div>
</div>
