<form (submit)="save()" [formGroup]="form" *ngIf="form">
  <div class="modal-header">
    <h4 *ngIf="task.idBatch" class="modal-title" id="modal-modif-title">Modification de la tâche {{ task.description }}</h4>
    <h4 *ngIf="!task.idBatch" class="modal-title" id="modal-ajout-title">Ajout d'une tâche</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('annuler')"></button>
  </div>
  <div class="modal-body mb-3">
      <div class="form-row">
        <label for="batchType">Type de tâche *</label>
          <select class="form-select form-control" formControlName="batchType" id="batchType" name="batchType" [compareWith]="compareBatchType" (change)="changeBatchType()"
                  [class.is-invalid] = "form.get('batchType')?.invalid && (form.get('batchType')?.touched || form.get('batchType')?.dirty)">
             <option [ngValue]=null>Choisir un type</option>
             <option *ngFor="let batchType of batchTypeList" [ngValue]="batchType">{{ batchType.libelle }}</option>
          </select>
        <div class="invalid-feedback" *ngIf="form.get('batchType')?.touched && form.get('batchType')?.hasError('required')">Champs requis</div>
      </div>

      <div class="form-row mt-2">
        <div class="form-group col-md-9">
          <label for="description">Description *</label>
          <input formControlName="description" id="description" type="text" maxlength="255" class="form-control" name="description"
                 [class.is-invalid] = "form.get('description')?.invalid && (form.get('description')?.touched || form.get('description')?.dirty)"/>
          <div class="invalid-feedback" *ngIf="form.get('description')?.touched && form.get('description')?.hasError('required')">Champs requis</div>
        </div>
        <div class="form-group">
         <label for="description">Programmation cron *</label>
         <input formControlName="cronSyntax" id="cronSyntax" type="text" maxlength="255" class="form-control" name="cronSyntax" style="width: 16.8em"
                [class.is-invalid] = "form.get('cronSyntax')?.invalid && (form.get('cronSyntax')?.touched || form.get('cronSyntax')?.dirty)"
                />
          <div class="invalid-feedback" *ngIf="form.get('cronSyntax')?.touched && form.get('cronSyntax')?.hasError('required')">Champs requis</div>
        </div>
      </div>

      <div class="form-row">
        <label for="courrielEnvoiLogReport">Envoyez les rapports à :</label>
        <textarea class="form-control" name="courrielEnvoiLogReport" formControlName="courrielEnvoiLogReport" id="courrielEnvoiLogReport"></textarea>
        <div class="form-text">
          Adresses emails séparées par des points-virgules
        </div>
      </div>

      <div class="form-row mt-2">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="actif" [value]="1" formControlName="actif" name="actif">
          <label class="form-check-label" for="actif">Traitement actif</label>
        </div>

        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="sendLogReport" [value]="1" formControlName="sendLogReport" name="sendLogReport">
          <label class="form-check-label" for="sendLogReport">Envoyer les rapports</label>
        </div>
      </div>

     <div class="mt-4" *ngIf="this.idBatchType == 1">
            <h5 class="mb-3">Transfert de fichier</h5>

         <div class="form-row form-group">
             <label for="batchType">Type de transfert *</label>
             <select class="form-select form-control" formControlName="batchFtpType" id="batchFtpType" name="batchFtpType" [compareWith]="compareBatchFtpType" (change)="changeBatchFTPType()"
                     [class.is-invalid] = "form.get('batchFtpType')?.invalid && (form.get('batchFtpType')?.touched || form.get('batchFtpType')?.dirty)">
               <option [ngValue]=null>Choisir un type</option>
               <option *ngFor="let batchFtpType of batchFtpTypeList" [ngValue]="batchFtpType">{{ batchFtpType.libelle }}</option>
             </select>
           <div class="invalid-feedback" *ngIf="form.get('batchFtpType')?.touched && form.get('batchFtpType')?.hasError('required')">Champs requis</div>
         </div>
       <div>
           <div class="form-row">
             <div class="form-group col-md-9">
                <label for="importAdresseServeur">Adresse Serveur *</label>
                <input formControlName="importAdresseServeur" id="importAdresseServeur" type="text" maxlength="255" class="form-control" name="importAdresseServeur"
                       [class.is-invalid] = "form.get('importAdresseServeur')?.invalid && (form.get('importAdresseServeur')?.touched || form.get('importAdresseServeur')?.dirty)"/>
               <div class="invalid-feedback" *ngIf="form.get('importAdresseServeur')?.touched && form.get('importAdresseServeur')?.hasError('required')">Champs requis</div>
             </div>
             <div class="form-group">
                <label for="importPort">Port *</label>
                <input formControlName="importPort" id="importPort" name="importPort" type="number" class="form-control"  style="width: 16.8em"
                       [class.is-invalid] = "form.get('importPort')?.invalid && (form.get('importPort')?.touched || form.get('importPort')?.dirty)"/>
               <div class="invalid-feedback" *ngIf="form.get('importPort')?.touched && form.get('importPort')?.hasError('required')">Champs requis</div>
             </div>
           </div>

           <div class="form-row">
             <div class="form-group col-md-6">
                <label for="importIdentifiant">Identifiant *</label>
                <input formControlName="importIdentifiant" id="importIdentifiant" name="importIdentifiant" type="text" maxlength="255" class="form-control"
                       [class.is-invalid] = "form.get('importIdentifiant')?.invalid && (form.get('importIdentifiant')?.touched || form.get('importIdentifiant')?.dirty)"/>
               <div class="invalid-feedback" *ngIf="form.get('importIdentifiant')?.touched && form.get('importIdentifiant')?.hasError('required')">Champs requis</div>
             </div>
             <div class="form-group col-md-6">
                <label for="importMotDePasse">Mot de passe *</label>
                <input formControlName="importMotDePasse" id="importMotDePasse" name="importMotDePasse" type="text" maxlength="255" class="form-control"
                       [class.is-invalid] = "form.get('importMotDePasse')?.invalid && (form.get('importMotDePasse')?.touched || form.get('importMotDePasse')?.dirty)"/>
               <div class="invalid-feedback" *ngIf="form.get('importMotDePasse')?.touched && form.get('importMotDePasse')?.hasError('required')">Champs requis</div>
             </div>
           </div>

           <div class="form-row">
             <div class="form-group col-md-6">
                <label for="importChemin">Chemin distant *</label>
                <input formControlName="importChemin" id="importChemin" name="importChemin" type="text" maxlength="255" class="form-control"
                       [class.is-invalid] = "form.get('importChemin')?.invalid && (form.get('importChemin')?.touched || form.get('importChemin')?.dirty)"/>
               <div class="invalid-feedback" *ngIf="form.get('importChemin')?.touched && form.get('importChemin')?.hasError('required')">Champs requis</div>
             </div>
             <div class="form-group col-md-6" *ngIf="idBatchFtpType!=9 && idBatchFtpType!=11 && idBatchFtpType!=13">
                <label for="importFichier">Nom du fichier *</label>
                <input formControlName="importFichier" id="importFichier" name="importFichier" type="text" maxlength="255" class="form-control"
                       [class.is-invalid] = "form.get('importFichier')?.invalid && (form.get('importFichier')?.touched || form.get('importFichier')?.dirty)"/>
               <div class="invalid-feedback" *ngIf="form.get('importFichier')?.touched && form.get('importFichier')?.hasError('required')">Champs requis</div>
             </div>
           </div>

           <div *ngIf="idBatchFtpType==14">
               <label for="crous">Crous *</label>
               <select  class="form-select form-control" formControlName="crous" id="crous" name="crous" required>
                 <option *ngFor="let crous of listeCrous" [value]="toStr(crous)">{{ crous.libelle }}</option>
               </select>
           </div>
           <div *ngIf="idBatchFtpType!=9 && idBatchFtpType!=11 && idBatchFtpType!=13">
            <div class="form-check-inline mt-3" >
              <input class="form-check-input" type="radio" name="typeFTP" value="FTP" id="FTP" formControlName="typeFTP" (change)="selectTypeFTP()">
              <label class="form-check-label" for="FTP">&nbsp;FTP</label>
            </div>
            <div class="form-check-inline">
              <input class="form-check-input" type="radio" name="typeFTP" value="FTPS" id="FTPS" formControlName="typeFTP" (change)="selectTypeFTP()">
              <label class="form-check-label" for="FTPS">&nbsp;FTPS</label>
            </div>
            <div class="form-check-inline">
              <input class="form-check-input" type="radio" name="typeFTP" value="SFTP" id="SFTP" formControlName="typeFTP" (change)="selectTypeFTP()">
              <label class="form-check-label" for="SFTP">&nbsp;SFTP</label>
            </div>
            <div class="form-check-inline" *ngIf="typeFTP=='SFTP'">
              <input class="form-check-input" type="checkbox" id="useSSHKey" [value]="1" formControlName="useSSHKey" name="useSSHKey">
              <label class="form-check-label" for="useSSHKey">&nbsp;Utiliser une clé ssh</label>
            </div>
           </div>
            <div *ngIf="idBatchFtpType===9 || idBatchFtpType===11 || idBatchFtpType===13">
                Transfert minio
            </div>
       </div>
     </div>

    <div class="mt-4" *ngIf="this.idBatchType == 11">
      <h5 class="mb-3">Purge</h5>
      <div class="form-group">
        <label for="nombreMoisConservationAideSaga" class="col-sm-4 control-label">Nb Mois Conservation Aide Saga</label>
        <div class="col-sm-4">
          <input formControlName="nombreMoisConservationAideSaga" id="nombreMoisConservationAideSaga" name="nombreMoisConservationAideSaga" type="number" class="form-control"  style="width: 16.8em"
                 [class.is-invalid] = "form.get('nombreMoisConservationAideSaga')?.invalid && (form.get('nombreMoisConservationAideSaga')?.touched || form.get('nombreMoisConservationAideSaga')?.dirty)"/>
          <div class="invalid-feedback" *ngIf="form.get('nombreMoisConservationAideSaga')?.touched && form.get('nombreMoisConservationAideSaga')?.hasError('required')">Champs requis</div>
        </div>
      </div>

    </div>


    <div class="mt-4" *ngIf="this.idBatchType == 5 || this.idBatchType == 8">
      <h5 class="mb-3">Import Izly</h5>

      <div class="form-row">
        <div class="form-group col-md-3">
          <label for="dateDebutObj" class="col-sm-4 control-label">Date début</label>
            <div class="input-group">
              <input class="form-control" placeholder="yyyy-mm-dd" name="dateDebutObj" id="dateDebutObj" formControlName="dateDebutObj" ngbDatepicker #d="ngbDatepicker"/>
              <button class="btn btn-outline-secondary bi bi-calendar3" (click)="d.toggle()" type="button"></button>
            </div>
            <div class="invalid-feedback" *ngIf="form.get('dateDebutObj')?.touched && form.get('dateDebutObj')?.hasError('required')">Champs requis</div>
        </div>
        <div class="form-group  col-md-3">
          <label for="dateDebutObj" class="col-sm-4 control-label">Date Fin</label>
            <div class="input-group">
              <input class="form-control" placeholder="yyyy-mm-dd" name="dateFinObj" id="dateFinObj" formControlName="dateFinObj" ngbDatepicker #d2="ngbDatepicker"/>
              <button class="btn btn-outline-secondary bi bi-calendar3" (click)="d2.toggle()" type="button"></button>
            </div>
            <div class="invalid-feedback" *ngIf="form.get('dateFinObj')?.touched && form.get('dateFinObj')?.hasError('required')">Champs requis</div>
        </div>
        <div class="form-group  col-md-1">
          &nbsp;
        </div>
        <div class="form-group  col-md-3">
          <label for="importPort">ou Nombre de jour</label>
          <input formControlName="nbJour" id="nbJour" name="nbJour" type="number" class="form-control"  style="width: 13.8em"
                 [class.is-invalid] = "form.get('nbJour')?.invalid && (form.get('nbJour')?.touched || form.get('nbJour')?.dirty)"/>
          <div class="invalid-feedback" *ngIf="form.get('nbJour')?.touched && form.get('nbJour')?.hasError('required')">Champs requis</div>
        </div>
      </div>

    </div>


  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary">Enregistrer</button>
  </div>
  </form>





