<div class="modal-header">
  <h4 class="modal-title">Ayant droit</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <div class="alert alert-danger hidden" role="alert" id="msg" *ngIf="erreur">{{erreur}}</div>
  <div class="row">
    <div class="col-3">
      <label class="control-label" for="nom">Nom</label>
      <p class="form-control-static" id="nom">{{ayantDroit.nom}}</p>
    </div>
    <div class="col-3">
      <label class="control-label" for="prenom">Prénom</label>
      <p class="form-control-static" id="prenom">{{ayantDroit.prenom}}</p>
    </div>
    <div class="col-3" *ngIf="ayantDroit.identifiantClient">
      <label class="control-label" for="identifiantClient">identifiant Client</label>
      <p class="form-control-static" id="identifiantClient">{{ayantDroit.identifiantClient}}</p>
    </div>
  </div>

  <div class="form-group">
    <div class="col-sm-2">
      <input type="button" id="lire" (click)="lectureCarte()" value="Lecture de la carte" class="btn btn-danger">
    </div>
  </div>

  <div *ngIf="zdc">
    <h5 class="mt-5">Données de la carte</h5>
    <div class="row">
      <div class="col-6">
        <label class="control-label" for="pix">pixSS/pixNN</label>
        <input type="text" id="pix" class="form-control" disabled [(ngModel)]="zdc.pixNN">
      </div>
      <div class="col-6">
        <label class="control-label" for="dateCreation">Date de création</label>
        <input type="text" id="dateCreation" class="form-control" disabled [(ngModel)]="zdc.dateCreation">
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <label class="control-label" for="identifiantEmetteur">Code Emetteur</label>
        <input type="text" id="identifiantEmetteur" class="form-control" disabled [(ngModel)]="zdc.identifiantEmetteur">
      </div>
      <div class="col-6">
        <label class="control-label" for="appl">appl</label>
        <input type="text" id="appl" class="form-control" disabled [(ngModel)]="zdc.appl">
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <label class="control-label" for="identifiantMapping">Version mapping</label>
        <input type="text" id="identifiantMapping" class="form-control" disabled [(ngModel)]="zdc.identifiantMapping">
      </div>
      <div class="col-6">
        <label class="control-label" for="uid">uid</label>
        <input type="text" id="uid" class="form-control" disabled [(ngModel)]="zdc.uid">
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <label class="control-label" for="identifiantCnous">Identifiant Cnous</label>
        <input type="text" id="identifiantCnous" class="form-control" disabled [(ngModel)]="zdc.identifiantCnous">
      </div>
      <div class="col-6">
        <label class="control-label" for="numeroApplicatif">Numero applicatif</label>
        <input type="text" id="numeroApplicatif" class="form-control" disabled [(ngModel)]="zdc.numeroApplicatif">
      </div>
    </div>
  </div>
</div>
<div class="modal-footer"  *ngIf="zdc">
  <button type="button" class="btn btn-primary" (click)="activeModal.close(zdc)">{{ buttonLabel }}</button>
</div>
