import { Component } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {SearchAyantDroitService} from '../search-ayant-droit.service';
import {Crous} from "../../../models/crous";
import {CrousService} from "../../../services/crous.service";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-from-izly',
  templateUrl: './from-izly.component.html',
  styleUrls: ['./from-izly.component.css']
})
export class FromIzlyComponent {


  searchForm: UntypedFormGroup;
  formSubmited = false;

  crousList: Array<Crous>;


  constructor(private searchAyantDroitService: SearchAyantDroitService,private fb: UntypedFormBuilder, private crousService: CrousService) {
    this.searchForm = fb.group({
      nom: fb.control('', [Validators.required, Validators.minLength(1)]),
      crous: fb.control('0'),
      searchType: fb.control('izly')
    });
    crousService.listCrousAutorises().subscribe(c => this.crousList = c);
  }

  search() {
    this.formSubmited=true;
    if (this.searchForm.valid) {
      this.formSubmited=false;
      this.searchAyantDroitService.publishData(this.searchForm);
    }
  }

  selectedCrous(event) {
    const crous = this.searchForm.get('crous').value;
  }

  get nom() { return this.searchForm.get('nom'); }
}
