<div class="loading" [hidden]="!loading"></div>
<form novalidate (ngSubmit)="submit()" class="needs-validation">
  <div class="container-fluid mt-3">
    <h4>Changement des identifiants des comptes Izly</h4>
    <div class="row">
      <div class="mb-3 col-md-5">
        <label for="formFile" class="form-label">Sélectionner un fichier <b>xlsx</b> au format attendu</label>
        <input class="form-control" [class.is-invalid]="formErreur" type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" id="formFile" (change)="onFileSelected($event)" #inputFile>
        <div id="validationServer03Feedback" class="invalid-feedback" [innerHTML]="formErreur">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="mb-3 col-md-5">
        <ngb-progressbar type="danger" [value]="progress" [striped]="true" />
      </div>
    </div>

    <div class="row" *ngIf="rowQuantity > 0">
      <div class="mb-3 col-md-5">
        <h6><span class="font-import-ok">{{rowQuantity}}</span> ligne{{rowQuantity>0?'s':''}} dans le fichier (y compris l'entête)</h6>
      </div>
    </div>

    <h5 *ngIf="response && response.nbKos > 0" class="text-danger"><span class="font-import-ko">{{response.nbKos}} erreur{{response.nbKos > 1 ? 's':''}}</span></h5>
    <h5 *ngIf="response && response.nbOk > 0" class="text-success"><span class="font-import-ko">{{response.nbOk}} identifiant{{response.nbOk > 1 ? 's':''}} changé{{response.nbOk > 1 ? 's':''}}</span></h5>


    <div class="row" *ngIf="rowQuantity > 0 && this.errorImport && this.errorImport.length > 0">
      <div class="col-md-5">
        <table class="table table-striped">
          <thead>
          <tr>
            <th scope="col">Erreur</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let error of this.errorImportExtrait">
            <td [innerHTML]="error"></td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="d-flex justify-content-between p-2">
        <ngb-pagination
          [collectionSize]="excelErrorSize"
          [(page)]="page"
          [maxSize]="5"
          [pageSize]="pageSize"
          (pageChange)="errorNext()">
        </ngb-pagination>

      </div>
    </div>
    <div class="row">
      <div class="col-md-5 d-md-flex justify-content-md-end">
        <button type="submit" class="btn btn-primary" [disabled]="!file || formErreur">Envoyer</button>
      </div>
    </div>
  </div>
</form>
