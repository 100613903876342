<div class="loading" [hidden]="!loading"></div>
<form (submit)="save()" [formGroup]="form">
  <div class="modal-header">
    <h4 *ngIf="rneSelectionne?.idRne" class="modal-title" id="modal-modif-title">Modification du RNE {{ rneSelectionne.libelleCourt }}</h4>
    <h4 *ngIf="!rneSelectionne?.idRne" class="modal-title" id="modal-ajout-title">Ajout d'un nouvel RNE</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="mb-3">
      <label for="uairne">UAIRNE *</label>
      <div class="input-group">
        <input formControlName="uairne" id="uairne" type="text" class="form-control" name="uairne" required/>
        <button *ngIf="!rneSelectionne.idRne" class="btn btn-outline-success" (click)="genererUai()" type="button">Générer</button>
      </div>
      <label for="nomUsuel">Nom Usuel *</label>
      <input formControlName="nomUsuel" id="nomUsuel" maxlength="255" type="text" class="form-control" name="nomUsuel" required/>
      <label for="libelleCourt">Libellé Court *</label>
      <input formControlName="libelleCourt" id="libelleCourt" maxlength="45" type="text" class="form-control" name="libelleCourt" required/>
      <label for="localite">Ville *</label>
      <input formControlName="localite" id="localite" maxlength="45" type="text" class="form-control" name="localite" required/>
      <label for="typeahead-focus">Etablissement</label>
      <div class="input-group">
        <input id="typeahead-focus"
               type="text"
               class="form-control"
               formControlName="etablissementClient"
               [ngbTypeahead]="search"
               [resultFormatter]="formatter"
               [inputFormatter]="formatter" #inputElement
        />
        <button type="button" class="btn btn-outline-warning " (click)="resetEtablissement()" >
          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M290.7 57.4L57.4 290.7c-25 25-25 65.5 0 90.5l80 80c12 12 28.3 18.7 45.3 18.7H288h9.4H512c17.7 0 32-14.3 32-32s-14.3-32-32-32H387.9L518.6 285.3c25-25 25-65.5 0-90.5L381.3 57.4c-25-25-65.5-25-90.5 0zM297.4 416H288l-105.4 0-80-80L227.3 211.3 364.7 348.7 297.4 416z"/></svg>
        </button>
      </div>
      </div>
  </div>
  <div class="modal-footer">
    <button [disabled]="!form.valid" type="submit" class="btn btn-primary">Enregistrer</button>
  </div>
</form>
