import { inject } from '@angular/core';
import {CanActivateFn, Router} from '@angular/router';
import {map, take} from 'rxjs/operators';
import {UserService} from "../services/user.service";
import {Me} from "../models/me";


export const isGranted : CanActivateFn = (route, state) => {
  const meService = inject(UserService);
  const router = inject(Router);
  return meService.me().pipe(
    map((res : Me) => {
      const me = res as Me;
      if (!me || !Me.isGranted(me) ||  !Me.isRouteGranted(me, route)) {
        console.log('is-granted.ts => !me || !Me.isGranted(me) ||  !Me.isRouteGranted(me, route)')
        router.navigate(['no-habilitation']);
        return false;
      }
      return true;
    })
  );
};

