import { inject } from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { map, take } from 'rxjs/operators';

export const isAuthenticated = (next: ActivatedRouteSnapshot,
                                state: RouterStateSnapshot) => {
  const oidcSecurityService = inject(OidcSecurityService);
  const router = inject(Router);

  return oidcSecurityService.isAuthenticated$.pipe(
    take(1),
    map(({ isAuthenticated }) => {
      if (!isAuthenticated) {
        console.log(state.url)
        router.navigate(['/connect'], { queryParams: { returnUrl: state.url }});
        return false;
      }
      return true;
    })
  );
};
