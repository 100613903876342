import { Component } from '@angular/core';

@Component({
  selector: 'app-liste-crous',
  templateUrl: './liste-crous.component.html',
  styleUrls: ['./liste-crous.component.css']
})
export class ListeCrousComponent {

}
