import { Injectable } from '@angular/core';
import * as toastr from 'toastr';

@Injectable()
export class ToastTool {

  constructor() { }


  static danger(errorMsg): void {
    toastr.options = {
      iconClass: '',
      positionClass: 'toast-top-right'
    };
    toastr.error(errorMsg);
  }

  static info(errorMsg): void {
    toastr.options = {
      iconClass: '',
      positionClass: 'toast-top-right'
    };
    toastr.info(errorMsg);
  }

  static success(errorMsg): void {
    toastr.options = {
      iconClass: 'fa fa-check',
      positionClass: 'toast-top-right'
    };
    toastr.success(errorMsg);
  }

  static dangerReponseApi(reponse): void {
     this.danger(this.messageFromResponse(reponse));
  }

  static dangerReponseApiAvecMessage(msg, reponse): void {
    this.danger(msg + ' : ' + this.messageFromResponse(reponse) )
  }

  static messageFromResponse(reponse) : string{
    if (!reponse.error) {
      return '';
    }

    if (reponse.error.error_description) {
      return reponse.error.error_description;
    }

    if (reponse.error.length >= 1) {
      if (reponse.error[0].error_description) {
        return reponse.error[0].error_description;
      }
    }
    if (reponse.error.errors && reponse.error.errors.length > 0) {
      return reponse.error.errors[0].fullErrorMessage;
    }
    return '';
  }


}
