<div class="container-fluid mt-3">
  <h4>Etablissements</h4>

  <div class="mb-3 row">
    <form class="col-xs-3 col-sm-auto">
      <div class="filtre-rne mb-3 row">
        <label for="table-filtering-search" class="col-xs-3 col-sm-auto col-form-label">Recherche multi-critères :</label>
        <div class="col-xs-3 col-sm-auto">
          <input id="table-filtering-search" type="text"  class="form-control" [formControl]="filter"/>
        </div>
      </div>
    </form>
  </div>

  <table class="table table-striped table-bordered table-hover">
    <thead>
    <tr>
      <th scope="col" sortable="nom" (sort)="onSort($event)">Nom</th>
      <th scope="col" sortable="crous.libelle" (sort)="onSort($event)">crous</th>
      <th>UAI</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of list$ | async | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
      <td><ngb-highlight [result]="item.nom" [term]="filter.value"></ngb-highlight></td>
      <td><ngb-highlight [result]="item.crous.libelle" [term]="filter.value"></ngb-highlight></td>
      <td><ngb-highlight [result]="item.rneInList" [term]="filter.value"></ngb-highlight></td>
    </tr>
    </tbody>
  </table>
  <div class="align-content-center">
    <ngb-pagination [pageSize]="pageSize" [collectionSize]="(list$ | async) ? (list$ | async).length : list.length" [(page)]="page" aria-label="Default pagination"></ngb-pagination>
  </div>


</div>
