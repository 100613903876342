<div class="col-12 col-md-3 col-xl-2 bd-sidebar collapse width show" id="leftmenu" style="width: 320px; padding: 1rem">
  <h5 style="margin-top: 10px">Rechercher&nbsp;dans&nbsp;izly</h5>
  <form class="bd-search" novalidate (ngSubmit)="search()" [formGroup]="searchForm">



    <div class="mb-3">
      <select class="form-select" id="crous" formControlName="crous" (change)="selectedCrous($event)">
        <option value="0">Tous les crous</option>
        <option  *ngFor="let c of crousList" [ngValue]="c.idCrous">{{c.libelle}}</option>
      </select>
    </div>

    <div class="mb-3">
      <input type="text" class="form-control" id="nom" placeholder="Nom exact" formControlName="nom" [ngClass]="{'is-invalid': !searchForm.valid  && (nom.dirty || nom.touched || formSubmited)}">
      <div class="form-group type-query-input" *ngIf="!searchForm.valid && (nom.dirty || nom.touched || formSubmited)">
        <small id="passwordHelp" class="text-danger">
          2 caractères minimum.
        </small>
      </div>
    </div>



    <div class="form-group" style="margin-top: 10px">
      <button class="btn btn-primary" type="submit">
        <span class="btn-text">Rechercher</span>
      </button>
    </div>
  </form>

</div>
