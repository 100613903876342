import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DialogFormEmetteurComponent} from "../../emetteurs/dialog-form-emetteur/dialog-form-emetteur.component";
import {EmetteurZdc} from "../../../models/emetteur-zdc";
import {EmetteurZdcContratService} from "../../../services/emetteur-zdc-contrat.service";
import {FormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {NgbActiveModal, NgbDateAdapter, NgbDateParserFormatter, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {EmetteurZdcService} from "../../../services/emetteur-zdc.service";
import {EmetteurZdcContrat} from "../../../models/emetteur-zdc-contrat";
import {ToastTool} from "../../../tools/toast.tool";
import {HttpErrorResponse} from "@angular/common/http";
import {DateFormatPipe} from "../../../tools/pipe/date-format.pipe";
import {NgbDateNativeAdapter} from "../../../tools/datepicker-adapter";
import {NgbDateFRParserFormatter} from "../../../tools/ngb-date-fr-parser-formatter";

@Component({
  selector: 'app-dialog-form-contrat',
  templateUrl: './dialog-form-contrat.component.html',
  styleUrls: ['./dialog-form-contrat.component.css'],
  providers: [
    DateFormatPipe,
    {provide: NgbDateAdapter, useClass: NgbDateNativeAdapter},
    {provide: NgbDateParserFormatter, useFactory: () => {
        return new NgbDateFRParserFormatter();
      }}]

})
export class DialogFormContratComponent implements OnInit{

  @Input()  contratSelectionne: EmetteurZdcContrat;
  @Output() contratModifie: EventEmitter<EmetteurZdcContrat> = new EventEmitter<EmetteurZdcContrat>();

  form: UntypedFormGroup;
  listeEmetteurs: EmetteurZdc[] = [];

  constructor(private fb: FormBuilder,
              public activeModal: NgbActiveModal,
              private contratService: EmetteurZdcContratService,
              private modalService: NgbModal,
              private dateFormat: DateFormatPipe,
              private emetteurService: EmetteurZdcService) {
    this.loadListeEmetteurs();
  }

  ngOnInit() {
    this.form = this.fb.group({
      emetteur         : this.fb.control<EmetteurZdc|null>(null, Validators.required),
      libelle          : this.fb.control<string|null>(null, Validators.required),
      crousPrivilegie  : this.fb.control<string|null>(null),
      codeEmetteur     : this.fb.control<string|null>(null, Validators.required),
      limiteInferieure : this.fb.control<string|null>(null, Validators.required),
      limiteSuperieure : this.fb.control<string|null>(null, Validators.required),
      dateDelivrance   : this.fb.control<Date|null>(null, Validators.required),
    });
    this.initFormValues();
  }

  save() {
    if (this.contratSelectionne.idEmetteurZdcContrat) {
      this.modifierContrat();
    } else {
      this.ajouterContrat();
    }
  }

  ajouterContrat() {
    this.setValues();
    this.contratService.ajouterContrat(this.contratSelectionne).subscribe({
      next:() => {
        ToastTool.success('Contrat crée');
        this.contratModifie.emit();
        this.activeModal.close();
      },
      error: (err: HttpErrorResponse) => {
        for (let error of err.error) {
          let description = error.error_description;
          ToastTool.danger(description);
        }
        this.contratModifie.emit();
      }
    });
  }

  modifierContrat() {
    this.setValues();
    this.contratService.modifierContrat(this.contratSelectionne).subscribe({
      next:() => {
        ToastTool.success('Contrat modifié');
        this.contratModifie.emit();
        this.activeModal.close();
      },
      error: (err: HttpErrorResponse) => {
        for (let error of err.error) {
          let description = error.error_description;
          ToastTool.danger(description);
        }
        this.contratModifie.emit();
      }
    })
  }


  toStr(value: EmetteurZdc): string {
    return JSON.stringify(value);
  }

  setValues() {
    this.contratSelectionne.emetteurZdc = JSON.parse(this.form.controls['emetteur'].value);
    this.contratSelectionne.libelle = this.form.controls['libelle'].value;
    this.contratSelectionne.crousPrivilegie = this.form.controls['crousPrivilegie'].value;
    this.contratSelectionne.codeEmetteur = this.form.controls['codeEmetteur'].value;
    this.contratSelectionne.numeroLimiteInferieure = this.form.controls['limiteInferieure'].value;
    this.contratSelectionne.numeroLimiteSuperieure = this.form.controls['limiteSuperieure'].value;
    this.contratSelectionne.dateDebutContrat = this.dateFormat.transform(this.form.controls['dateDelivrance'].value, ['YYYY-MM-DDTHH:mm:ss','YYYY-MM-DDTHH:mm:ss']);
  }



  initFormValues() {
    this.form.controls['emetteur'].setValue(JSON.stringify(this.contratSelectionne.emetteurZdc));
    this.form.controls['libelle'].setValue(this.contratSelectionne.libelle);
    this.form.controls['crousPrivilegie'].setValue(this.contratSelectionne.crousPrivilegie);
    this.form.controls['codeEmetteur'].setValue(this.contratSelectionne.codeEmetteur);
    this.form.controls['limiteInferieure'].setValue(this.contratSelectionne.numeroLimiteInferieure);
    this.form.controls['limiteSuperieure'].setValue(this.contratSelectionne.numeroLimiteSuperieure);
    this.form.controls['dateDelivrance'].setValue(this.contratSelectionne.dateDebutContrat);
  }

  trancheSuivante() {
    this.contratService.trancheSuivante().subscribe({
      next: (data) =>  {
        this.form.controls['limiteInferieure'].setValue(data);
      }
    })
  }

  nouvelEmetteur(){
    const modalRef = this.modalService.open(DialogFormEmetteurComponent);
    modalRef.componentInstance.emetteurSelectionne = new EmetteurZdc();
    modalRef.componentInstance.emetteurModifie.subscribe(() => {
      this.loadListeEmetteurs();
    })
  }

  loadListeEmetteurs() {
    this.emetteurService.getListeEmetteursZdc().subscribe({
      next: (data) => {
        this.listeEmetteurs = data;
      }
    })
  }
}
