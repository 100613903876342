<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <p style="white-space: pre-line;" [class.red]="messageP1Red" [class.bold]="messageP1Bold" *ngIf="messageP1">{{messageP1}}</p>
  <p style="white-space: pre-line;" [class.red]="messageP2Red" [class.bold]="messageP2Bold" *ngIf="messageP2">{{messageP2}}</p>
  <p style="white-space: pre-line;" [class.red]="messageP3Red" [class.bold]="messageP3Bold" *ngIf="messageP3">{{messageP3}}</p>
  <p style="white-space: pre-line;" [class.red]="messageP4Red" [class.bold]="messageP4Bold">{{messageP4}}</p>
</div>
<div class="modal-footer justify-content-between">
  <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Annuler</button>
  <button type="button" class="btn btn-danger" (click)="activeModal.close()">Valider</button>
</div>
