import { Component } from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {AyantDroit} from "../models/ayant-droit";
import {HttpClient} from "@angular/common/http";
import {SpringZdcResponse} from "../models/spring-zdc-response";
import {Zdc} from "../models/zdc";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-lecture-carte',
  templateUrl: './lecture-carte.component.html',
  styleUrls: ['./lecture-carte.component.css']
})
export class LectureCarteComponent {

  ayantDroit: AyantDroit
  zdc: Zdc;
  erreur: string;
  buttonLabel = 'Enregistrer';

  constructor(public activeModal: NgbActiveModal, private spinner: NgxSpinnerService,
              private httpClient: HttpClient) { }


  lectureCarte() {
    this.spinner.show();
    this.httpClient.get<SpringZdcResponse>('http://127.0.0.1:14242/v1/lecture-carte').subscribe({next: value => {
          if (value.code > 0) {
            this.erreur = value.message;
          } else {
            this.zdc = value.zdc;
          }
          this.spinner.hide();
        }, error: error => {
          this.erreur = error.statusText
          this.spinner.hide();
        }
      });
  }

}
