import {RneDto} from "./rne-dto";
import {Crous} from "./crous";
import {Rne} from "./rne";

export class CrousPut {
  libelle: string;
  rne: RneDto;

  constructor(crous: Crous) {
    console.log(this);
    this.libelle = crous.libelle;
    this.rne = new RneDto(new Rne());
    this.rne.uairne = crous.bceEtablissement.numeroUai;
  }
}
