import {Rne} from "./rne";

export class RneDto {
  idRne: number;
  uairne: string;


  constructor(rne: Rne) {
    this.idRne = rne?.idRne;
    this.uairne = rne?.uairne;
  }
}
