<div class="loading" [hidden]="!loading"></div>
<form (submit)="save()" [formGroup]="form">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-modif-title">Modification du Crous {{ crousSelectionne.libelle }}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <ul ngbNav (navChange)="onNavChange($event)" #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
      <li [ngbNavItem]="1">
        <button ngbNavLink>Crous</button>
        <ng-template ngbNavContent>
          <div class="mb-3">
            <label for="numeroCrous">Numero Crous</label>
            <input formControlName="numeroCrous" id="numeroCrous" type="text" maxlength="45" class="form-control" name="numeroCrous" required/>
            <label for="nom">Nom</label>
            <input formControlName="nom" id="nom" maxlength="1000" type="text" class="form-control" name="nom" />
            <label for="rne">RNE *</label>
            <div class="input-group">
              <input
                formControlName="rne"
                [ngbTypeahead]="search"
                [resultFormatter]="formatter"
                [inputFormatter]="formatter" type="text" id="rne" class="form-control" />
              <button type="button" class="btn btn-outline-warning " (click)="resetRne()" >
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M290.7 57.4L57.4 290.7c-25 25-25 65.5 0 90.5l80 80c12 12 28.3 18.7 45.3 18.7H288h9.4H512c17.7 0 32-14.3 32-32s-14.3-32-32-32H387.9L518.6 285.3c25-25 25-65.5 0-90.5L381.3 57.4c-25-25-65.5-25-90.5 0zM297.4 416H288l-105.4 0-80-80L227.3 211.3 364.7 348.7 297.4 416z"/></svg>
              </button>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <button ngbNavLink>Tarifs</button>
        <ng-template ngbNavContent>
          <div>
            <span [hidden]="listeTarif.length !== 0">Pas de tarifs pour cet établissement</span>
            <table class="table" [hidden]="listeTarif.length === 0">
              <thead>
              <tr>
                <th scope="col">Status</th>
                <th scope="col">Indice mini</th>
                <th scope="col">Indice maxi</th>
                <th scope="col">Code société</th>
                <th scope="col">Code tarif</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let tarif of listeTarif | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                <td>{{ tarif.tarificationStatut?.libelle }}</td>
                <td>{{ tarif.indiceMin }}</td>
                <td>{{ tarif.indiceMax }}</td>
                <td>{{ tarif.codeSociete }}</td>
                <td>{{ tarif.codeTarif }}</td>
              </tr>
              </tbody>
            </table>
            <div>
              <div class="d-inline-block">
                <ngb-pagination class="d-flex" [pageSize]="pageSize" [collectionSize]="listeTarif.length" [(page)]="page" aria-label="Default pagination"></ngb-pagination>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>

  </div>
  <div class="modal-footer">
    <div class="d-flex flex-row-reverse">
      <button [disabled]="!form.valid || !form.dirty" type="submit" class="p-2 btn btn-primary">Enregistrer</button>
    </div>
  </div>
</form>
