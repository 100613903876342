import {FormControl, FormGroup} from "@angular/forms";
import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";

export class Utils {

  static validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  static parseJwt (token: string | null) {
    if (!token) {
      return;
    }
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  static completeWithZero(value: number) {
    let valueS = value+'';
    return valueS.length == 1?'0'+valueS:valueS;
  }

  static formatNgDateToUsDate(date: NgbDateStruct | null): string {
    return date ?  date.year + '-' + Utils.completeWithZero(date.month) + '-' + Utils.completeWithZero(date.day) : '';
  }

  static formatDateToNgDate( date){
    if (!date || date.length < 8) {
      return null;
    }
    return {
      day: parseInt(date.substring(8,10)),
      month: parseInt(date.substring(5,7)),
      year: parseInt(date.substring(0,4))
    }
  }

  static isValidDate(laDate) {
    const dateString = laDate.toString()
    const formats = ['yyyyMMdd', 'dd/MM/yyyy'];
    const dateParts = dateString.match(/(\d{4})(\d{2})(\d{2})/) || dateString.match(/(\d{2})\/(\d{2})\/(\d{4})/);

    if (!dateParts) {
      return false;
    }

    const format = formats[dateParts[1].length === 4 ? 0 : 1];
    const year = parseInt(dateParts[format === 'yyyyMMdd' ? 1 : 3], 10);
    const month = parseInt(dateParts[format === 'yyyyMMdd' ? 2 : 2], 10) - 1;
    const day = parseInt(dateParts[format === 'yyyyMMdd' ? 3 : 1], 10);

    const date = new Date(year, month, day);

    return date.getFullYear() === year && date.getMonth() === month && date.getDate() === day;
  }


  public static joiOptions =   {
    errors : {
      language: 'fr'
    },
    abortEarly: false,
    messages: {
      fr: {
        "ine.invalid": "<b>{{#label}}</b> n\'est pas un INE valide",
        "any.unknown": "<b>{{#label}}</b> n'est pas autorisé",
        "any.invalid": "<b>{{#label}}</b> contient une valeur non valide",
        "any.empty": "<b>{{#label}}</b> ne doit pas être vide",
        "any.required": "<b>{{#label}}</b> est obligatoire",
        "any.allowOnly": "<b>{{#label}}</b> doit être l'un des {{#valids}}",
        "any.default": "<b>{{#label}}</b> a généré une erreur lors de l'exécution de la méthode par défaut",
        "alternatives.base": "<b>{{#label}}</b> ne correspond à aucune des alternatives autorisées",
        "array.base": "<b>{{#label}}</b> doit être un tableau",
        "array.includes": "à la position {{#pos}} ne correspond à aucun des types autorisés",
        "array.includesSingle": "la valeur unique \"{{#key}}\" ne correspond à aucun des types autorisés",
        "array.includesOne": "à la position {{#pos}} n'est pas valide car {{#reason}}",
        "array.includesOneSingle": "la valeur unique \"{{#key}}\" n'est pas valide car {{#reason}}",
        "array.includesRequiredUnknowns": "ne contient pas {{#unknownMisses}} valeur(s) obligatoire(s)",
        "array.includesRequiredKnowns": "ne contient pas {{knownMisses}}",
        "array.includesRequiredBoth": "ne contient pas {{knownMisses}} et {{#unknownMisses}} autres valeurs obligatoires",
        "array.excludes": "à la position {{#pos}} contient une valeur exclue",
        "array.excludesSingle": "la valeur unique \"{{#key}}\" contient une valeur exclue",
        "array.min": "doit contenir au moins {{#limit}} éléments",
        "array.max": "doit contenir au maximum {{#limit}} éléments",
        "array.length": "doit contenir {{#limit}} éléments",
        "array.ordered": "à la position {{#pos}} n'est pas valide car {{#reason}}",
        "array.orderedLength": "à la position {{#pos}} n'est pas valide car le tableau ne doit pas contenir plus de {{#limit}} éléments",
        "array.ref": "fait référence à \"{{#ref}}\" qui n'est pas un entier positif",
        "array.sparse": "ne doit pas être un tableau clairsemé",
        "array.unique": "la position {{#pos}} contient une valeur dupliquée",
        "boolean.base": "<b>{{#label}}</b> doit être un booléen",
        "binary.base": "<b>{{#label}}</b> doit être un tampon ou une chaîne de caractères",
        "binary.min": "<b>{{#label}}</b> doit contenir au moins {{#limit}} octets",
        "binary.max": "<b>{{#label}}</b> doit contenir au maximum {{#limit}} octets",
        "binary.length": "<b>{{#label}}</b> doit contenir {{#limit}} octets",
        "date.base": "<b>{{#label}}</b> doit être un nombre de millisecondes ou une chaîne de date valide",
        "date.format": "<b>{{#label}}</b> doit être une chaîne de caractères dans l'un des formats suivants {{format}}",
        "date.strict": "<b>{{#label}}</b> doit être une date valide",
        "date.min": "<b>{{#label}}</b> doit être supérieur ou égal à {{#limit}}",
        "date.max": "<b>{{#label}}</b> doit être inférieur ou égal à {{#limit}}",
        "date.isoDate": "<b>{{#label}}</b> doit être une date ISO 8601 valide",
        "date.timestamp.javascript": "<b>{{#label}}</b> doit être un horodatage valide ou un nombre de millisecondes",
        "date.timestamp.unix": "<b>{{#label}}</b> doit être un horodatage valide ou un nombre de secondes",
        "date.ref": "<b>{{#label}}</b> fait référence à \"{{#ref}}\" qui n'est pas une date",
        "function.base": "<b>{{#label}}</b> doit être une fonction",
        "function.arity": "<b>{{#label}}</b> doit avoir une arité de {{#n}}",
        "function.minArity": "<b>{{#label}}</b> doit avoir une arité supérieure ou égale à {{#n}}",
        "function.maxArity": "<b>{{#label}}</b> doit avoir une arité inférieure ou égale à {{#n}}",
        "function.ref": "<b>{{#label}}</b> doit être une référence Joi",
        "lazy.base": "!!erreur de schéma : le schéma lazy doit être défini",
        "lazy.schema": "!!erreur de schéma : la fonction du schéma lazy doit retourner un schéma",
        "object.base": "<b>{{#label}}</b> doit être un objet",
        "object.child": "!!enfant \"{{#child}}\" n'est pas valide car {{#reason}}",
        "object.min": "<b>{{#label}}</b> doit avoir au moins {{#limit}} enfants",
        "object.max": "<b>{{#label}}</b> doit avoir au maximum {{#limit}} enfants",
        "object.length": "<b>{{#label}}</b> doit avoir {{#limit}} enfants",
        "object.allowUnknown": "!\"{{#child}}\" n'est pas autorisé",
        "object.with": "!!\"{{#mainWithLabel}}\" requiert la présence de \"{{peerWithLabel}}\"",
        "object.without": "!!\"{{#mainWithLabel}}\" est en conflit avec \"{{peerWithLabel}}\"",
        "object.missing": "<b>{{#label}}</b> doit contenir au moins l'un des {{#peersWithLabels}}",
        "object.xor": "<b>{{#label}}</b> contient un conflit entre les pairs exclusifs {{#peersWithLabels}}",
        "object.or": "<b>{{#label}}</b> doit contenir au moins l'un des {{#peersWithLabels}}",
        "object.and": "<b>{{#label}}</b> contient {{presentWithLabels}} sans ses pairs requis {{missingWithLabels}}",
        "object.nand": "!!\"{{#mainWithLabel}}\" ne doit pas exister simultanément avec {{#peersWithLabels}}",
        "object.assert": "!!la validation de \"{{#ref}}\" a échoué car \"{{#ref}}\" a échoué à {{message}}",
        "object.rename.multiple": "ne peut pas renommer l'enfant \"{{#from}}\" car les renommages multiples sont désactivés et qu'une autre clé a déjà été renommée en \"{{#to}}\"",
        "object.rename.override": "ne peut pas renommer l'enfant \"{{#from}}\" car le remplacement est désactivé et que la cible \"{{#to}}\" existe déjà",
        "object.type": "<b>{{#label}}</b> doit être une instance de \"{{type}}\"",
        "object.schema": "<b>{{#label}}</b> doit être une instance Joi",
        "number.base": "<b>{{#label}}</b> doit être un nombre",
        "number.min": "<b>{{#label}}</b> doit être supérieur ou égal à {{#limit}}",
        "number.max": "<b>{{#label}}</b> doit être inférieur ou égal à {{#limit}}",
        "number.less": "doit être inférieur à {{#limit}}",
        "number.greater": "<b>{{#label}}</b> doit être supérieur à {{#limit}}",
        "number.float": "<b>{{#label}}</b> doit être un nombre flottant ou double",
        "number.integer": "<b>{{#label}}</b> doit être un entier",
        "number.negative": "<b>{{#label}}</b> doit être un nombre négatif",
        "number.positive": "<b>{{#label}}</b> doit être un nombre positif",
        "number.precision": "<b>{{#label}}</b> ne doit pas avoir plus de {{#limit}} chiffres après la virgule",
        "number.ref": "<b>{{#label}}</b> fait référence à \"{{#ref}}\" qui n'est pas un nombre",
        "number.multiple": "<b>{{#label}}</b> doit être un multiple de {{multiple}}",
        "string.base": "<b>{{#label}}</b> doit être une chaîne de caractères",
        "string.min": "la longueur de <b>{{#label}}</b> doit être d'au moins {{#limit}} caractères",
        "string.max": "la longueur de <b>{{#label}}</b> doit être inférieure ou égale à {{#limit}} caractères",
        "string.length": "la longueur de <b>{{#label}}</b> doit être de {{#limit}} caractères",
        "string.alphanum": "<b>{{#label}}</b> ne doit contenir que des caractères alphanumériques",
        "string.token": "<b>{{#label}}</b> ne doit contenir que des caractères alphanumériques et des underscores",
        "string.pattern.base": "la valeur \"{{!label}}\" ne correspond pas au modèle requis : {{#pattern}}",
        "string.pattern.name": "la valeur \"{{!label}}\" ne correspond pas au modèle {{#name}}",
        "string.pattern.invert.base": "la valeur \"{{!label}}\" correspond au modèle inversé : {{#pattern}}",
        "string.pattern.invert.name": "la valeur \"{{!label}}\" correspond au modèle inversé {{#name}}",
        "string.email": "<b>{{#label}}</b> doit être une adresse e-mail valide",
        "string.uri": "<b>{{#label}}</b> doit être un URI valide",
        "string.uriRelativeOnly": "<b>{{#label}}</b> doit être un URI relatif valide",
        "string.uriCustomScheme": "<b>{{#label}}</b> doit être un URI valide avec un schéma correspondant au modèle {{scheme}}",
        "string.isoDate": "<b>{{#label}}</b> doit être une date ISO 8601 valide",
        "string.guid": "<b>{{#label}}</b> doit être un GUID valide",
        "string.hex": "<b>{{#label}}</b> ne doit contenir que des caractères hexadécimaux",
        "string.base64": "<b>{{#label}}</b> doit être une chaîne base64 valide",
        "string.hostname": "<b>{{#label}}</b> doit être un nom d'hôte valide",
        "string.lowercase": "<b>{{#label}}</b> ne doit contenir que des caractères minuscules",
        "string.uppercase": "<b>{{#label}}</b> ne doit contenir que des caractères majuscules",
        "string.trim": "<b>{{#label}}</b> ne doit pas contenir d'espaces blancs en début ou fin de chaîne",
        "string.creditCard": "<b>{{#label}}</b> doit être un numéro de carte de crédit valide",
        "string.ref": "<b>{{#label}}</b> fait référence à \"{{#ref}}\" qui n'est pas un nombre",
        "string.ip": "<b>{{#label}}</b> doit être une adresse IP valide avec un CIDR {{#cidr}}",
        "string.ipVersion": "<b>{{#label}}</b> doit être une adresse IP valide dans l'une des versions suivantes {{#version}} avec un CIDR {{#cidr}}",
        'alternatives.types': '<b>{{#label}}</b> doit être une des valeur de {{#types}}',
        'options.cidr': '<b>{{#label}}</b> doit être une des valeur de {{#types}}',
      }}}

}
