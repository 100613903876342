import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Batch} from "../models/batch";
import {environment} from "../../environments/environment";
import {BatchType} from "../models/batch-type";
import {BatchFtpType} from "../models/batch-ftp-type";

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  constructor(private httpClient: HttpClient) { }

  getList(fromScheduler: boolean): Observable<Batch[]> {

    const endpoint = fromScheduler ? 'batchs-scheduled' : 'batchs';

    return this.httpClient.get<Batch[]>(environment.apiB4Batch + '/v1/' + endpoint, { withCredentials: true});
  }

  getTypeList(): Observable<BatchType[]> {
    return this.httpClient.get<BatchType[]>(environment.apiB4Batch + '/v1/batch-types', { withCredentials: true});
  }

  getFtpTypeList(): Observable<BatchFtpType[]> {
    return this.httpClient.get<BatchFtpType[]>(environment.apiB4Batch + '/v1/batch-ftp-types', { withCredentials: true});
  }

  add(task: Batch) {
    return this.httpClient.post<void>(environment.apiB4Batch + '/v1/batchs', task, { withCredentials: true});
  }

  update(task: Batch) {
    return this.httpClient.put<void>(environment.apiB4Batch + '/v1/batchs/' + task.idBatch, task, { withCredentials: true});
  }

  unschedule(task: Batch) {
    return this.httpClient.post<void>(environment.apiB4Batch + '/v1/tasks/' + task.idBatch + '/unschedule', null, { withCredentials: true});
  }

  schedule(task: Batch) {
    return this.httpClient.post<void>(environment.apiB4Batch + '/v1/tasks/' + task.idBatch + '/schedule', null, { withCredentials: true});
  }

  start(task: Batch) {
    return this.httpClient.post<void>(environment.apiB4Batch + '/v1/tasks/' + task.idBatch + '/start', null, { withCredentials: true});
  }

  delete(task: Batch) {
    return this.httpClient.delete<void>(environment.apiB4Batch + '/v1/tasks/' + task.idBatch, { withCredentials: true});
  }

  unscheduleAll() {
    return this.httpClient.post<void>(environment.apiB4Batch + '/v1/tasks/unschedule', null, { withCredentials: true});
  }

  scheduleAll() {
    return this.httpClient.post<void>(environment.apiB4Batch + '/v1/tasks/schedule', null, { withCredentials: true});
  }
}
