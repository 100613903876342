import {Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {EmetteurZdc} from "../../../models/emetteur-zdc";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {NgbModalWindow} from "@ng-bootstrap/ng-bootstrap/modal/modal-window";
import {EmetteurZdcService} from "../../../services/emetteur-zdc.service";
import {ToastTool} from "../../../tools/toast.tool";
import {HttpErrorResponse} from "@angular/common/http";
import {FormBuilder, NgForm, UntypedFormGroup, Validators} from "@angular/forms";
import {error} from "jquery";
import {DialogFormEmetteurComponent} from "../dialog-form-emetteur/dialog-form-emetteur.component";
import {NgbdSortableHeader, SortEvent} from "../../../tools/ngbd-sortable-header.directive";

@Component({
  selector: 'app-tableau-emetteurs',
  templateUrl: './tableau-emetteurs.component.html',
  styleUrls: ['./tableau-emetteurs.component.css']
})
export class TableauEmetteursComponent implements OnInit{

  listeEmetteurs: EmetteurZdc[] = [];
  emetteurs: any[];

  emetteurSelectionne: EmetteurZdc;


  constructor(private modalService: NgbModal, private emetteurService: EmetteurZdcService) {
  }

  ngOnInit(): void {
    this.loadEmetteurs();
  }


  loadEmetteurs() {
    this.emetteurService.getListeEmetteursZdc().subscribe({
      next: (data) => {
        this.listeEmetteurs = data;
      }
    })
  }

  actionBoutonEmetteur(emetteur: EmetteurZdc) {
    this.emetteurSelectionne = emetteur;
    const modalRef = this.modalService.open(DialogFormEmetteurComponent);
    modalRef.componentInstance.emetteurSelectionne = this.emetteurSelectionne;
    modalRef.componentInstance.emetteurModifie.subscribe(() => {
      this.loadEmetteurs();
    })
  }

  nouvelEmetteur() {
    this.emetteurSelectionne = new EmetteurZdc();
    const modalRef = this.modalService.open(DialogFormEmetteurComponent);
    modalRef.componentInstance.emetteurSelectionne = this.emetteurSelectionne;
    modalRef.componentInstance.emetteurModifie.subscribe(() => {
      this.loadEmetteurs();
    })
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    for (const header of this.headers) {
      if (header.sortable !== column) {
        header.direction = '';
      }
    }

    if (direction === '' || column === '') {
      this.loadEmetteurs();
    } else {
      this.listeEmetteurs = [...this.listeEmetteurs].sort((a, b) => {
        const res = NgbdSortableHeader.compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      });
    }
  }


}
