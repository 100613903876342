<div class="loading" [hidden]="!loading"></div>
<div class="row">
  <div class="col">
    <div class="widget">
      <div class="row">
        <div class="col">
          <h4>Liste des Ayants Droit</h4>
        </div>
      </div>
      <div class="row">
        <div *ngIf="!serverResult" style="margin-top: 10em" class="text-center">Veuillez effectuer une recherche ci-contre pour afficher des ayants-droits</div>
        <div class="loading" [hidden]="!loading"></div>
        <div class="col">
          <table class="table table-striped table-bordered table-hover" style="width: 100%" *ngIf="resultatLoaded">
            <thead>
            <tr>
              <th>Etablissement</th>
              <th>Nom</th>
              <th>Prénom</th>
              <th>Email</th>
              <th>Identifiant</th>
              <th>Société</th>
              <th>Tarif</th>
              <th>action</th>
            </tr>
            </thead>
            <tbody>
              <tr *ngFor="let h of serverResult?.data">
                <th>{{ h.etablissementClient.nom }}</th>
                <td>{{ h.nom }}</td>
                <td>{{ h.prenom }}</td>
                <td>{{ h.courrielInstitutionnel }}</td>
                <td>{{ h.identifiantClient }}</td>
                <td>{{ h.utiliseSocieteTarifForce?h.codeSocieteForce+'f':h.codesociete }}</td>
                <td>{{ h.utiliseSocieteTarifForce?h.codeTarifForce+'f':h.codetarif }}</td>
                <td><a class="btn btn-warning" (click)="actionBouton(h)"> {{ libelleBouton(h) }} </a></td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>
      <div class="row"  *ngIf="resultatLoaded && affichePagination" [hidden]="loading">
        <div class="col"><span style="margin-right: 20px">{{serverResult?.recordsTotal | number }} résultat{{serverResult?.recordsTotal>1?'s':''}}</span>
          <a class="btn btn-excel" (click)="getXls()" *ngIf="serverResult?.recordsTotal > 0">Export Excel</a></div>
        <div class="col"><ngb-pagination *ngIf="resultatLoaded" class="d-flex justify-content-end"
                                                     [collectionSize]="total" [(page)]="page" [pageSize]="pageSize" maxSize="5" (pageChange)="seachAD($event)">
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Décloturer un compte</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Etes vous sûr de vouloir décloturer ce compte ? </p>
    <ul>
        <li *ngIf="this.searchForm.get('nom').value">Nom : {{ this.searchForm.get('nom').value }}</li>
        <li *ngIf="this.searchForm.get('prenom').value">Prenom : {{ this.searchForm.get('prenom').value }}</li>
        <li *ngIf="this.searchForm.get('identifiant').value">Identifiant : {{ this.searchForm.get('identifiant').value }}</li>
        <li *ngIf="this.searchForm.get('email').value">Email : {{ this.searchForm.get('email').value }}</li>
        <li *ngIf="this.searchForm.get('numZdc').value">Carte : {{ this.searchForm.get('numZdc').value }}</li>


        <li>Etablissement : {{ compteADecloturer.etablissementClient.nom }}</li>
        <li>Rne Etablissement : {{ compteADecloturer.rneEtablissement }}</li>
        <li>Rne Composante : {{ compteADecloturer.rneComposante }}</li>
        <li>Societe : {{ compteADecloturer.codesociete }}</li>
        <li>Tarif : {{ compteADecloturer.codetarif }}</li>
        <li>Cloturé le : {{ compteADecloturer.dateFinValidite | date:'dd/MM/yyyy'}}</li>
    </ul>
    <p>Attention, décloturer un compte ne signifie pas qu'il sera "recréé" immédiatement. Il faut qu'il soit recréé par un SGC, un import excel ou via le formulaire de Beforeizly. </p>
    <p>Cette opération sera enregistrée pour traçabilité. </p>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">Annuler</button>
    <button type="button" class="btn btn-danger" (click)="modal.close('Ok click')">Décloturer</button>
  </div>
</ng-template>
