<div class="loading" [hidden]="!loading"></div>
<div class="mb-3 row">
  <form class="col-xs-3 col-sm-auto">
    <div class="filtre-rne mb-3 row">
      <label for="table-filtering-search" class="col-xs-3 col-sm-auto col-form-label">Recherche :</label>
      <div class="col-xs-3 col-sm-auto">
        <input id="table-filtering-search" type="text"  class="form-control" [formControl]="filter"/>
      </div>
    </div>
  </form>
</div>

<table class="table table-striped table-bordered table-hover">
  <thead>
  <tr>
    <th scope="col" sortable="libelle" (sort)="onSort($event)" >Nom</th>
    <th scope="col" sortable="numeroCrous" (sort)="onSort($event)">Numéro</th>
    <th scope="col" sortable="rneLight" (sort)="onSort($event)">RNE</th>
    <th scope="col">Action</th>
  </tr>
  </thead>
  <tbody *ngIf="listeCrous">
  <tr *ngFor="let crous of listeCrous$ | async | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
    <td><ngb-highlight [result]="crous.libelle" [term]="filter.value"></ngb-highlight></td>
    <td><ngb-highlight [result]="crous.numeroCrous.toString()" [term]="filter.value"></ngb-highlight></td>
    <td><ngb-highlight [result]="crous.bceEtablissement?.denominationPrincipale" [term]="filter.value"></ngb-highlight></td>
    <td>
      <a class="btn btn-warning" (click)="modifierCrous(crous)"> Modifier </a>
      &nbsp;
    </td>
  </tr>
  </tbody>
</table>
<div class="align-content-center">
  <ngb-pagination [pageSize]="pageSize" [collectionSize]="(listeCrous$ | async) ? (listeCrous$ | async).length : listeCrous.length" [(page)]="page" aria-label="Default pagination"></ngb-pagination>
</div>
