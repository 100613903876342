<div class="loading" [hidden]="!loading"></div>
<div class="mb-3 row">
  <div class="rne-actions col-xs-3 col-sm-auto">
    <button class="btn btn-primary" (click)="nouvelEtablissement()">Ajouter</button>
  </div>

  <form class="col-xs-3 col-sm-auto">
    <div class="filtre-rne mb-3 row">
      <label for="table-filtering-search" class="col-xs-3 col-sm-auto col-form-label">Recherche :</label>
      <div class="col-xs-3 col-sm-auto">
        <input id="table-filtering-search" type="text"  class="form-control" [formControl]="filter"/>
      </div>
    </div>
  </form>
</div>

<table class="table table-striped table-bordered table-hover">
  <thead>
  <tr>
    <th scope="col" sortable="idEtablissementClient" (sort)="onSort($event)">Id</th>
    <th scope="col" sortable="nom" (sort)="onSort($event)">Nom</th>
    <th scope="col" sortable="adresse" (sort)="onSort($event)">Adresse</th>
    <th scope="col" sortable="crous.libelle" (sort)="onSort($event)">Crous</th>
    <th scope="col">Action</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let etablissement of listeEtablissements$ | async | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
    <td><ngb-highlight [result]="etablissement.idEtablissementClient.toString()" [term]="filter.value"></ngb-highlight></td>
    <td><ngb-highlight [result]="etablissement.nom" [term]="filter.value"></ngb-highlight></td>
    <td><ngb-highlight [result]="etablissement.adresse" [term]="filter.value"></ngb-highlight></td>
    <td><ngb-highlight [result]="etablissement.crous.libelle" [term]="filter.value"></ngb-highlight></td>
    <td>
      <a class="btn btn-warning" (click)="modifierEtablissement(etablissement)"> Modifier </a>
      &nbsp;
      <a class="btn btn-danger" (click)="supprimerEtablissement(etablissement)"> Supprimer </a>
    </td>
  </tr>
  </tbody>
</table>
<div class="align-content-center">
  <ngb-pagination [pageSize]="pageSize" [collectionSize]="(listeEtablissements$ | async) ? (listeEtablissements$ | async).length : listeEtablissements.length" [(page)]="page" aria-label="Default pagination"></ngb-pagination>
</div>
