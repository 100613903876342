import { Injectable } from '@angular/core';
import {DatePipe} from "@angular/common";
import {HttpClient, HttpParams} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private httpClient: HttpClient,
              private dateFormat: DatePipe) { }


  buildParams(paramObject: {[key: string]: any}): HttpParams | undefined {
    let params;

    if (paramObject) {
      params = new HttpParams();

      // Pour chaque paramètre
      for (const param in paramObject) {
        // Si le paramètre a une valeur
        if (paramObject[param] !== null && paramObject[param] !== undefined) {
          // Si le paramètre est un objet
          if (typeof paramObject[param] === "object") {
            // Si le paramètre est une date
            if (paramObject[param] instanceof Date) {
              params = params.append(param, ''+this.dateFormat.transform(paramObject[param], 'yyyy-MM-dd'))

              // Si le paramètre est un tableau
            } else if (paramObject[param] instanceof Array) {
              for (const i in paramObject[param]) {
                if (paramObject[param].hasOwnProperty(i)) {
                  params = params.append(param, '' + paramObject[param][i]);
                }
              }

              // Sinon, parcours de l'objet pour ajouter chacun des ses paramètres
            } else {
              for (const subParam in paramObject[param]) {
                if (paramObject[param].hasOwnProperty(subParam) && paramObject[param][subParam] !== null) {
                  params = params.append(subParam, '' + paramObject[param][subParam]);
                }
              }
            }
          } else {
            params = params.append(param, '' + paramObject[param]);
          }
        }
      }
    }

    return params;
  }
}
