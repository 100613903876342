import {Component, OnInit} from '@angular/core';
import {OidcSecurityService} from "angular-auth-oidc-client";
import {ActivatedRoute, Router, RouterStateSnapshot} from "@angular/router";
import {UserService} from "../../services/user.service";
import {NgIf} from "@angular/common";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-connexion',
  standalone: true,
  templateUrl: './connexion.component.html',
  imports: [
    NgIf
  ],
  styleUrls: ['./connexion.component.scss']
})
export class ConnexionComponent implements OnInit {

  constructor(private oidcSecurityService: OidcSecurityService, private router : Router,
              private meService: UserService, private route: ActivatedRoute) {
  }

  loaded = false;


  login() {
    this.oidcSecurityService.authorize(null, { customParams: { returnUrl: this.route.snapshot.queryParams['returnUrl'] }});
  }

  ngOnInit(): void {

    this.meService.me().subscribe({
      next: me => {
        if (me.nom && me.droitList) {
          this.router.navigate(['/home']);
        } else if (me.nom) {
          this.router.navigate(['/no-habilitation']);
        }
        this.loaded = true;
      }
  })
  }

}
