import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CheckIneService {

  constructor() { }

  private isIneFormatBea(ine) {
    if (ine === null) {
      return false;
    }

    ine = ine.toUpperCase();
    if (ine.length !== 11) {
      return false;
    }

    const ineBeaPattern = /^(\d\d)(\d\d)([A-Z\d]{6})([A-Z])$/;
    const sections = ineBeaPattern.exec(ine);

    if (!sections) {
      return false;
    }

    if (sections.length !== 5) {
      return false;
    }

    const academie = sections[1];
    const anneeS = sections[2];
    let numero = sections[3];
    let moduloS = sections[4];

    const provisoire = numero[0] === 'D';

    if (academie === '00' && provisoire) {
      return false;
    }

    let annee;
    try {
      annee = parseInt(anneeS, 10);
    } catch (e) {
      return false;
    }

    if (annee < 90 && annee > new Date().getFullYear() % 100) {
      return false;
    }

    numero = this.changeLetterByIndiceConstanteChiffre(numero);
    moduloS = this.changeLetterByIndiceModulo(moduloS);

    const checkS = academie + anneeS + numero;

    let check;
    let modulo;
    try {
      check = parseInt(checkS, 10) % 23;
      modulo = parseInt(moduloS, 10);
    } catch (e) {
      return false;
    }

    return check === modulo;
  }

  public isCorrectIne(ine: string) {
    return this.isIneFormatBea(ine) || this.isIneFormatEtablissement(ine) || this.isIneFormatInes(ine);
  }


private changeLetterByIndiceConstanteChiffre(value) {
    value = value.toUpperCase();

    const lettres = new Map([
      ["A", "1"],
      ["B", "2"],
      ["C", "3"],
      ["D", "4"],
      ["E", "5"],
      ["F", "6"],
      ["G", "7"],
      ["H", "8"],
      ["J", "9"],
      ["K", "10"],
      ["L", "11"],
      ["M", "12"],
      ["N", "13"],
      ["P", "14"],
      ["R", "15"],
      ["S", "16"],
      ["T", "17"],
      ["U", "18"],
      ["V", "19"],
      ["W", "20"],
      ["X", "21"],
      ["Y", "22"],
      ["Z", "23"],
    ]);

    for (const [key, replacement] of lettres.entries()) {
      value = value.replaceAll(key, replacement);
    }

    return value;
  }

  private changeLetterByIndiceModulo(value) {
    value = value.toUpperCase();

    const lettres = new Map([
      ["A", "0"],
      ["B", "1"],
      ["C", "2"],
      ["D", "3"],
      ["E", "4"],
      ["F", "5"],
      ["G", "6"],
      ["H", "7"],
      ["J", "8"],
      ["K", "9"],
      ["L", "10"],
      ["M", "11"],
      ["N", "12"],
      ["P", "13"],
      ["R", "14"],
      ["S", "15"],
      ["T", "16"],
      ["U", "17"],
      ["V", "18"],
      ["W", "19"],
      ["X", "20"],
      ["Y", "21"],
      ["Z", "22"],
    ]);

    for (const [key, replacement] of lettres.entries()) {
      value = value.replaceAll(key, replacement);
    }

    return value;
  }

  private isIneFormatInes(ine) {
    if (ine === null) {
      return false;
    }

    ine = ine.toUpperCase();
    if (ine.length !== 11) {
      return false;
    }

    const ineESPattern = /^(\d{9})([A-Z]{2})$/;
    const m = ineESPattern.exec(ine);

    return m !== null;
  }

  private isIneFormatEtablissement(ine) {
    if (ine === null) {
      return false;
    }
    if (ine === "00000000000") {
      return false;
    }

    ine = ine.toUpperCase();
    if (ine.length !== 11) {
      return false;
    }

    const ineESPattern = /^[A-Z\d]{10}\d$/;
    const m = ineESPattern.exec(ine);

    if (!m) {
      return false;
    }

    let ine10;
    let modulo;
    try {
      modulo = parseInt(ine.substring(10, 11), 10);
      ine10 = parseInt(ine.substring(0, 10), 36);
    } catch (e) {
      return false;
    }

    const check = ine10 % 10;

    return modulo === check;
  }
}
