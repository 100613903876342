import {EmetteurZdcDto} from "./emetteur-zdc-dto";
import {EmetteurZdcContrat} from "./emetteur-zdc-contrat";

export class EmetteurZdcContratPostPut {
  emetteurZdc: EmetteurZdcDto;
  codeEmetteur: number;
  dateDebutContrat: Date;
  numeroLimiteInferieure: number;
  numeroLimiteSuperieure: number;
  libelle: string;
  crousPrivilegie: string;


  constructor(contrat: EmetteurZdcContrat) {
    this.emetteurZdc = new EmetteurZdcDto(contrat.emetteurZdc);
    this.codeEmetteur = contrat.codeEmetteur;
    this.dateDebutContrat = contrat.dateDebutContrat;
    this.numeroLimiteInferieure = contrat.numeroLimiteInferieure;
    this.numeroLimiteSuperieure = contrat.numeroLimiteSuperieure;
    this.libelle = contrat.libelle ? contrat.libelle : '';
    this.crousPrivilegie = contrat.crousPrivilegie ? contrat.crousPrivilegie : '';
  }
}
