import { Component } from '@angular/core';

@Component({
  selector: 'app-logs-api',
  templateUrl: './logs-api.component.html',
  styleUrls: ['./logs-api.component.css']
})
export class LogsApiComponent {

}
