import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Crous} from "../../../models/crous";
import {Rne} from "../../../models/rne";
import {
  async,
  debounceTime, delay, delayWhen,
  distinctUntilChanged, lastValueFrom,
  map,
  Observable,
  of,
  OperatorFunction,
  Subject,
  takeUntil,
  takeWhile, timer
} from "rxjs";
import {CrousService} from "../../../services/crous.service";
import {RneService} from "../../../services/rne.service";
import {NgbActiveModal, NgbModal, NgbModalConfig, NgbNavChangeEvent} from "@ng-bootstrap/ng-bootstrap";
import {ToastTool} from "../../../tools/toast.tool";
import {HttpErrorResponse} from "@angular/common/http";
import {RneLight} from "../../../models/rne-light";
import {TarificationService} from "../../../services/tarification.service";
import {EtablissementClient} from "../../../models/etablissement-client";
import {Tarification} from "../../../models/tarification";
import {BceService} from "../../../services/bns/bce.service";
import {BceEtablissementSearch} from "../../../models/bce-etablissement-search";
import {BceEtablissement} from "../../../models/bce-etablissement";
import {tap} from "rxjs/operators";

@Component({
  selector: 'app-dialog-form-crous',
  templateUrl: './dialog-form-crous.component.html',
  styleUrls: ['./dialog-form-crous.component.css']
})
export class DialogFormCrousComponent {
  @Input() crousSelectionne: Crous;
  @Output() crousModifie = new EventEmitter<Crous>();
  form: UntypedFormGroup;
  loading: boolean = false;

  page = 5;
  pageSize = 20;

  active = 1;

  listeAllRne: BceEtablissement[] = [];
  listeTarif: Tarification[] = [];

  // Recherche de RNE
  formatter = (result: BceEtablissement) => {
    if (result.numeroUai) {
      return result.denominationPrincipale + ' (' + result.numeroUai + ')'
    }
  };

  search: OperatorFunction<string, readonly BceEtablissement[]> = (text$: Observable<string>) =>
    text$.pipe(
      tap((term) => {
        if (term.length >= 3) {
          this.loading = true;
          this.listeAllRne = [];
          this.loadListAllRne(term);
        }
      }),
      delay(1000),
      debounceTime(200),
      distinctUntilChanged(),
      tap(() => {
        this.loading = false
      }),
      map((term) =>
        term === '' ? [] : this.listeAllRne.filter((v) => v.numeroUai.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
          v.denominationPrincipale?.toLowerCase().indexOf(term.toLowerCase()) > -1 || v.commune?.libelle.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10),
      ),
    );
  // --

  constructor(private crousService: CrousService,
              private rneService: RneService,
              private bceService: BceService,
              private tarificationService: TarificationService,
              private modalService: NgbModal,
              private fb: FormBuilder,
              private config: NgbModalConfig,
              public activeModal: NgbActiveModal) {
    this.config.backdrop = 'static';
  }

  loadListAllRne(text: string) {
    let search: BceEtablissementSearch = new BceEtablissementSearch();
    search.fields = 'numeroUai,denominationPrincipale,commune,appellationOfficielle,sirenSiret';
    search.q = text;
    search.pageSize = 10;
     this.bceService.getListeBce(search).subscribe({
      next: (data) => {
        this.listeAllRne = data;
      },
      error: (err) => {
      }
    });
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      numeroCrous: this.fb.control<string|null>({value: null, disabled: true}),
      nom: this.fb.control<string|null>(null, Validators.required),
      rne: this.fb.control<BceEtablissement|null>(null, Validators.required),
    });

    this.initFormValues();
  }

  initFormValues() {
    this.form.controls['numeroCrous'].setValue(this.crousSelectionne?.numeroCrous);
    this.form.controls['nom'].setValue(this.crousSelectionne?.libelle);
    this.form.controls['rne'].setValue(this.crousSelectionne?.bceEtablissement);
  }


  setValues() {
    this.crousSelectionne.libelle = this.form.controls['nom']?.value;
    this.crousSelectionne.bceEtablissement = this.form.controls['rne'].value;
  }

  save() {
      this.modifierEtablissement();
  }

  modifierEtablissement() {
    this.setValues();
    this.loading = true;
    this.crousService.putCrous(this.crousSelectionne).subscribe({
      next:() => {
        this.loading = false;
        ToastTool.success('Crous modifié');
        this.crousModifie.emit();
      },
      error: (err: HttpErrorResponse) => {
        this.loading = false;
        for (let error of err.error) {
          let description = error.error_description;
          ToastTool.danger(description);
        }
        this.crousModifie.emit();
      }
    })
  }


  onNavChange(changeEvent: NgbNavChangeEvent) {
    if (changeEvent.nextId === 2) {
      this.loadListTarifs();
    } else {
      this.initFormValues();
    }
  }

  loadListTarifs() {
      this.loading = true;
      const fields = "idEsist," +
        "indiceMin," +
        "indiceMax," +
        "codeSociete," +
        "codeTarif," +
        "tarificationStatut.codeStatut," +
        "tarificationStatut.libelle";
      this.crousService.getTarifCrous(fields, this.crousSelectionne).subscribe({
        next: (data) => {
          this.listeTarif = data;
          this.loading = false;
        },
        error: (err) => {
          for (let error of err.error) {
            let description = error.error_description;
            ToastTool.danger(description);
          }
          this.loading = false;
        }
      })
  }

  resetRne() {
    this.form.controls['rne'].reset();
  }
}
