import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {EtablissementClient} from "../models/etablissement-client";
import {Observable} from "rxjs";
import {Tarification} from "../models/tarification";
import {environment} from "../../environments/environment";
import {HttpService} from "./http.service";

@Injectable({
  providedIn: 'root'
})
export class TarificationService {

  constructor(private http: HttpClient,
              private httpService: HttpService) {
  }

  getTarifByEtablissementClient(fields: string, etablissement: EtablissementClient): Observable<Tarification[]> {
    const params = this.httpService.buildParams({fields})
    return this.http.get<Tarification[]>(environment.apiB4Url + '/v1/etablissements/' + etablissement.idEtablissementClient + '/tarifs',{ params, withCredentials: true });
  }
}
