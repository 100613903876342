<div class="text-center" style="margin-top: 10em">
  <h4>Connexion à Beforeizly</h4>
</div>
<div>
  <div class="text-center">
    <p>Pour vous connecter à Beforeizly, veuillez vous identifier sur MonComptePro</p>
    <div>
      <button class="proconnect-button" (click)="login()">
        <span class="proconnect-sr-only">S'identifier avec ProConnect</span>
      </button>
      <p>
        <a
          href="https://www.proconnect.gouv.fr/"
          target="_blank"
          rel="noopener noreferrer"
          title="Qu’est-ce que ProConnect ? - nouvelle fenêtre"
        >
          Qu’est-ce que ProConnect ?
        </a>
      </p>
    </div>
  </div>
</div>
