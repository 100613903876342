<div style="margin-top: 10em">
  <div>
    <div class="text-center">
      <h3>Accès en attente d'habilitation</h3>
      <p>
        Afin d'obtenir votre habilitation sur Beforeizly, veuillez contacter votre correspondant Crous.
      </p>
      <p>
        <button class="btn" (click)="deconnect()"><i class="fa fa-power-off" aria-hidden="true"></i></button>
      </p>
    </div>
  </div>
</div>
