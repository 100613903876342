import { Injectable } from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {UntypedFormGroup} from '@angular/forms';

@Injectable()
export class SearchCorrespondanceErreurInfocartecrousService {

  private formSubmited = new Subject<UntypedFormGroup>();
  formSubmited$ = this.formSubmited.asObservable();
  subscription: Subscription;
  disabledCreateButton = true;
  idLigneInfocartecrous: number;

  searchForm: UntypedFormGroup;

  constructor() {  }

  publishData(form: UntypedFormGroup, idLigneInfocartecrous: number) {
    this.searchForm = form;
    this.idLigneInfocartecrous = idLigneInfocartecrous;
    this.formSubmited.next(form);
    this.disabledCreateButton = true;
  }

}
