import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateHeureFormat'
})
export class DateHeureFormatPipe implements PipeTransform {

  transform(value: any, args: string[]): any {
    if (value) {
      value = value.replace('T', ' ');
      const date = moment(value, 'YYYY-MM-DD HH:mm:ss');
      return date.format('DD/MM/YYYY HH:mm:ss');
    }
    return '';
  }

}
