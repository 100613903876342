import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from "rxjs";
import {SearchLogsApi} from "../models/search-logs-api";
import {UntypedFormGroup} from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class LogsApiService {


  constructor(private http: HttpClient) {

  }

  search(form : UntypedFormGroup) : Observable<SearchLogsApi> {
    const search = form.get('terme').value
    const start = form.get('start').value
    return this.http.get<SearchLogsApi>(environment.apiB4Url + '/v1/logs?start=' + start + '&search='+search,   { withCredentials: true });
  }

}
