import {Component, Input, OnInit} from '@angular/core';
import {Crous} from '../../models/crous';

@Component({
  selector: 'app-search-ayant-droit',
  templateUrl: './search-ayant-droit.component.html',
  styleUrls: ['./search-ayant-droit.component.css']
})
export class SearchAyantDroitComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
