import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ImportAyantDroitService {


  constructor(private https: HttpClient) { }

  upload(formData: FormData){
    let headers = new HttpHeaders();
    headers.set('Content-Type', null);
    headers.set('Accept', "multipart/form-data");

    const req = new HttpRequest('POST', environment.apiB4Batch + '/v1/import-ayant-droit', formData, {
      reportProgress: true,
      responseType: 'json',
      headers:  headers,
      withCredentials: true
    });
    return this.https.request(req);
  }


  uploadChangeIdentifiant(formData: FormData){
    let headers = new HttpHeaders();
    headers.set('Content-Type', null);
    headers.set('Accept', "multipart/form-data");

    const req = new HttpRequest('POST', environment.apiB4Url + '/v1/change-identifiants', formData, {
      reportProgress: true,
      responseType: 'json',
      headers:  headers,
      withCredentials: true
    });
    return this.https.request(req);
  }


}
