import {Injectable} from '@angular/core';
import {NgbDateAdapter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

function padNumber(value: number) {
  if (isNumber(value)) {
    return `0${value}`.slice(-2);
  } else {
    return '';
  }
}

function isNumber(value: any): boolean {
  return !isNaN(toInteger(value));
}

function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}

@Injectable()
export class NgbDateNativeAdapter extends NgbDateAdapter<String> {

  fromModel(date: String): NgbDateStruct {

    if (date === undefined || date == null || date === '') {
      return;
    }

    let year: string;
    let month: string;
    let day: string;

    if (date.search('T') > 0) {
      year = date.substring(0, 4);
      month = date.substring(5, 7);
      day = date.substring(8, 10);
    } else {
      year = date.substring(6);
      month = date.substring(3, 5);
      day = date.substring(0, 2);
    }

    return (date) ? {year: toInteger(year), month: toInteger(month), day: toInteger(day)} : null;
  }

  toModel(date: NgbDateStruct): String {
    return date ? (date.year + '-' + padNumber(date.month) + '-' + padNumber(date.day) + 'T00:00:00.000+01')  : null;
  }
}

