import {Compteur} from './compteur';

export class IzlyParts {


  constructor() {
    this.solde = 0;
  }

  dateDernierServiceMidi: string;
  dateDernierServiceSoir: string;
  idIzly: number;
  identifiantCrous: number;
  numeroAffectation: number;
  solde: number;
  statutBlocage: number;
  statutCompte: number;
  typeClient: number;

  lCompteur: Array<Compteur>;

  tarifIzly: number;
  societeIzly: number;
  gccErrorMessage: number;
  gccErrorCode: string;


}
