import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {EtablissementClient} from '../models/etablissement-client';
import {Observable} from "rxjs";
import {EtablissementClientPostPut} from "../models/etablissement-client-post-put";
import {RneDto} from "../models/rne-dto";
import {Rne} from "../models/rne";

@Injectable()
export class EtablissementService {

  constructor(private http: HttpClient) {
  }

  getListeEtablissementClient(): Observable<EtablissementClient[]> {
    return this.http.get<EtablissementClient[]>(environment.apiB4Url + '/v1/etablissements',{ withCredentials: true });
  }

  listByIdCrous(idCrous: number) {
    return this.http.get<Array<EtablissementClient>>(environment.apiB4Url + '/v1/crous/' + idCrous + '/etablissements',
      { withCredentials: true });
  }

  listEtabByIdCrous(idCrous: number, nom: any) {
    return this.http.get<Array<EtablissementClient>>(environment.apiB4Url + '/v1/crous/' + idCrous + '/etablissements?nom=' + nom,
      { withCredentials: true });
  }


  getEtablissementClient(etablissement: EtablissementClient): Observable<EtablissementClient> {
    return this.http.get<EtablissementClient>(environment.apiB4Url + '/v1/etablissements/' + etablissement.idEtablissementClient, {withCredentials: true})
  }

  postEtablissementClient(etablissement: EtablissementClient): Observable<EtablissementClient> {
    return this.http.post<EtablissementClient>(environment.apiB4Url + '/v1/etablissements', new EtablissementClientPostPut(etablissement), {withCredentials: true});
  }

  putEtablissementClient(etablissement: EtablissementClient): Observable<void> {
    return this.http.put<void>(environment.apiB4Url + '/v1/etablissements/' + etablissement.idEtablissementClient, new EtablissementClientPostPut(etablissement), {withCredentials: true})
  }

  addRneToEtablissementClient(etablissement: EtablissementClient, rne: Rne): Observable<void> {
    return this.http.put<void>(environment.apiB4Url + '/v1/etablissements/' + etablissement.idEtablissementClient + '/addrne', new RneDto(rne), {withCredentials: true})
  }

  deleteRneToEtablissementClient(etablissement: EtablissementClient, rne: Rne): Observable<void> {
    return this.http.put<void>(environment.apiB4Url + '/v1/etablissements/' + etablissement.idEtablissementClient + '/deleterne', new RneDto(rne), {withCredentials: true})
  }

  deleteEtablissementClient(etablissement: EtablissementClient): Observable<void> {
    return this.http.delete<void>(environment.apiB4Url + '/v1/etablissements/' + etablissement.idEtablissementClient, {withCredentials: true});
  }

}
