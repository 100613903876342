import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AyantDroit} from '../../models/ayant-droit';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';
import {UntypedFormGroup} from '@angular/forms';
import {ToastTool} from '../../tools/toast.tool';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { saveAs } from 'file-saver';
import {SearchAyantDroitService} from '../../common/search/search-ayant-droit.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RightholderService} from '../../services/rightholder.service';
import {SearchAyantDroit} from '../../models/search-ayant-droit';
import {AyantDroitSearchService} from '../../services/ayant-droit-search.service';
import {InfoCarteCrous} from "../../models/infocartecrous";

declare var $: any;

@Component({
  selector: 'app-datatable-ayant-droit',
  templateUrl: './datatable-ayant-droit.component.html',
  styleUrls: ['./datatable-ayant-droit.component.css']
})
export class DatatableAyantDroitComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('content', { static: true })
  private modalTemplate: TemplateRef<any>;

  loading = false;
  dtOptions: any = {};
  searchForm: UntypedFormGroup;
  loaded = false;
  compteCloture = false;
  modal: any;
  compteADecloturer: any;

  page = 1;
  pageSize = 10;
  total: number;
  resultatLoaded = false;

  affichePagination = true;

  serverResult: SearchAyantDroit;

  constructor(private router: Router, private searchAyantDroitService: SearchAyantDroitService,
              private http: HttpClient, private modalService: NgbModal,
              private rightholderService: RightholderService, private ayantDroitSearchService: AyantDroitSearchService) {

    if (this.searchAyantDroitService.subscription != null) {
      this.searchAyantDroitService.subscription.unsubscribe();
    }

    this.searchAyantDroitService.subscription = this.searchAyantDroitService.formSubmited$.subscribe(
      form => {
            this.searchForm = form;
            this.compteCloture = (form.get('compteCloture') && form.get('compteCloture').value) || false;
            const formType = form.get('searchType')?form.get('searchType').value:'b4';
            this.seachAD(1, formType);
      });
  }

  getXls() {

    if (this.compteCloture) {
      ToastTool.danger('Pas d\'export des comptes cloturés');
      return;
    }

    if (this.searchForm == null) {
      ToastTool.danger('Formulaire non valide');
      return;
    }

    this.loading = true;
    this.http
      .post(environment.apiB4Url + '/v1/ayantdroits/xlsx', this.searchForm.getRawValue(),
        { headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
          .set('Accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
          , observe: 'response'
          , withCredentials: true
          , responseType: 'blob' })
      .subscribe( res => {
        const blob = new Blob([res.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'export.xlsx');
        this.loading = false;
      }, error => {
        this.loading = false;
      });
  }


  private buildParams(params: any) {
    const urlSearchParams = new URLSearchParams();
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        urlSearchParams.append(key, params[key]);
      }
    }
    return urlSearchParams.toString();
  }

  ngOnInit() {
  }

  private actionBouton(data) {
    if (data.origine && data.origine === 'CLOTURE') {
      this.loading = false;
      this.openModal(data);
    } else if (data.origine && data.origine === 'IMPORTIZLY')   {
      this.loading = true;
      this.rightholderService.get(data.identifiantClient).subscribe({
        next: r => {
          this.loading = false;
          this.gotoAyantDroit(data)
        },
        error: e => {
          const message = e.error.errors[0].message;
          this.loading = false;
          ToastTool.danger("Impossible d'importer l'Ayant Droit \n"+ message);
        }
      });
    } else {
      this.gotoAyantDroit(data);
    }
  }

  libelleBouton(data) {
    if (data.origine && data.origine === 'CLOTURE') {
      return 'Décloturer';
    } else if (data.origine && data.origine === 'IMPORTIZLY')   {
      return 'Importer';
    } else {
      return 'Modifier';
    }
  }


  gotoAyantDroit(data) {
    this.router.navigate(['ad/search/ad/' + data.identifiantClient]);
  }

  ngAfterViewInit(): void {
    this.loaded = true;
  }

  ngOnDestroy(): void {
    this.loaded = false;
  }

  openModal(data) {
    this.compteADecloturer = data;
    this.modal = this.modalService.open(this.modalTemplate);
    this.modal.result.then((result) => {
      this.rightholderService.deleteClientCloture(data.idClient, this.searchForm).subscribe(res => {
          ToastTool.success('Compte décloturé');
      }
      );
    }, (reason) => {
      console.log('Annulation');
    }).catch(r => {
    });
  }


  seachAD(page: any, formType='b4') {
    this.serverResult = {data:[],recordsTotal:0,recordsFiltered:0,draw:0};
    this.loading = true;
    if (formType == 'b4') {
      this.searchForm.get('start').setValue((page - 1) * 10);
      this.ayantDroitSearchService.search(this.searchForm.getRawValue()).subscribe(res => {
        this.serverResult = res;
        this.total = res.recordsTotal;
        this.resultatLoaded = true;
        this.loading = false;
      });
    } else {
      const nom = this.searchForm.get('nom').value;
      const idCrous = this.searchForm.get('crous').value;

      const form = new InfoCarteCrous(nom, idCrous);

      this.ayantDroitSearchService.searchIzly(form).subscribe({
        next: (res) => {
          console.log(res);
          this.serverResult = res;
          this.total = res.recordsTotal;
          this.resultatLoaded = true;
          this.affichePagination = false;
          this.loading = false;
        }, error: (error) => {
          this.loading = false;
        }
      });




    }

  }
}
