import {Zdc} from "./zdc";

export class SmartCard {

  idTransmitter: number;
  idMapping: number;
  idZdc: number;
  zdcCreationDate: string;
  pixSs: string;
  pixNn: string;
  appl: string;
  uid: string; //applicatif
  rid: string; //protoclaire
  reason: string;
  cancelDate: Date;
  revalidationDate: Date;
  deliveryDate: Date;
  origin: string;
  identifier: string;

  static convertToSmartCard(zdc: Zdc): SmartCard {
    const card = new SmartCard();
    card.idTransmitter = parseInt(zdc.identifiantEmetteur, 16);
    card.idMapping = parseInt(zdc.identifiantMapping, 10);
    card.idZdc = parseInt(zdc.identifiantCnous, 10);
    if (zdc.dateCreation) {
      card.zdcCreationDate = `20${zdc.dateCreation.substring(0, 2)}-${zdc.dateCreation.substring(2, 4)}-${zdc.dateCreation.substring(4, 6)}T12:00:00.000Z`;
    }
    card.pixSs = zdc.pixSS;
    card.pixNn = zdc.pixNN;
    card.appl = zdc.appl;
    card.uid = zdc.uid;
    card.rid = zdc.numeroApplicatif;
    return card;
  }
}
