import {EmetteurZdc} from "./emetteur-zdc";

export class EmetteurPostPut {
  raisonSociale: string;
  adresse: string;
  adresse2: string;
  codePostal: string;
  ville: string;
  url: string;


  constructor(emetteur: EmetteurZdc) {
    this.raisonSociale = emetteur.raisonSociale;
    this.adresse = emetteur.adresse;
    this.adresse2 = emetteur.adresse2;
    this.codePostal = emetteur.codePostal;
    this.ville = emetteur.ville;
    this.url = emetteur.url;
  }
}
