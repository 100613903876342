<div class="emetteurs-actions">
  <button class="btn btn-primary" (click)="nouvelEmetteur()">Ajouter</button>
</div>

<table class="table table-striped table-bordered table-hover">
  <thead>
  <tr>
    <th scope="col" sortable="raisonSociale" (sort)="onSort($event)">Raison sociale</th>
    <th scope="col" sortable="adresse" (sort)="onSort($event)">Adresse ligne 1</th>
    <th scope="col" sortable="adresse2" (sort)="onSort($event)">Adresse ligne 2</th>
    <th scope="col" sortable="codePostal" (sort)="onSort($event)">Code Postal</th>
    <th scope="col" sortable="ville" (sort)="onSort($event)">Ville</th>
    <th scope="col">Action</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let emetteur of listeEmetteurs" #em>
    <td>{{ emetteur.raisonSociale }}</td>
    <td>{{ emetteur.adresse }}</td>
    <td>{{ emetteur.adresse2 }}</td>
    <td>{{ emetteur.codePostal }}</td>
    <td>{{ emetteur.ville }}</td>
    <td><a class="btn btn-warning" (click)="actionBoutonEmetteur(emetteur)"> Modifier </a></td>
  </tr>
  </tbody>
</table>
