import {EtablissementClient} from './etablissement-client';

export class AyantDroit {

  nom: string;
  prenom: string;
  courrielInstitutionnel: string;
  etablissementClient: EtablissementClient;
  identifiantClient: string;
  codesociete: number;
  codetarif: number;

  utiliseSocieteTarifForce: boolean
  codeTarifForce: number
  codeSocieteForce: number
  origine: string;
}
