<form (submit)="save()" [formGroup]="form">
  <div class="modal-header">
    <h4 *ngIf="emetteurSelectionne?.idEmetteurZdc" class="modal-title" id="modal-modif-title">Modifiaction de l'émetteur {{ emetteurSelectionne.raisonSociale }}</h4>
    <h4 *ngIf="!emetteurSelectionne?.idEmetteurZdc" class="modal-title" id="modal-ajout-title">Ajout d'un nouvel émetteur</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="mb-3">
      <label for="raisonSociale">Raison sociale *</label>
      <input formControlName="raisonSociale" id="raisonSociale" type="text" class="form-control" name="raisonSociale" required/>
      <label for="adresse">Adresse</label>
      <input formControlName="adresse" id="adresse" type="text" class="form-control" name="adresse"/>
      <label for="adresse2">Adresse 2</label>
      <input formControlName="adresse2" id="adresse2" type="text" class="form-control" name="adresse2"/>
      <label for="codePostal">Code Postal</label>
      <input formControlName="codePostal" id="codePostal" type="text" class="form-control" name="codePostal"/>
      <label for="ville">Ville</label>
      <input formControlName="ville" id="ville" type="text" class="form-control" name="ville"/>
    </div>
  </div>
  <div class="modal-footer">
    <button [disabled]="!form.valid" type="submit" class="btn btn-primary">Enregistrer</button>
  </div>
</form>
