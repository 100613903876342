import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'label'
})
export class LabelPipe implements PipeTransform {


  transform(value: any, args?: any): any {

    switch (value) {

      case 1:
        return 'Info';
      case 2:
        return 'Alerte';
      case 3:
        return 'Maintenance';
      case 4:
        return 'Panne';
      default:
        return value;

    }
  }

}
