import {Component, OnInit} from '@angular/core';
import {UserService} from '../services/user.service';
import packageJson from '../../../package.json';
import {AnnonceService} from "../services/annonce.service";
import {Annonce} from "../models/annonce";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {OidcSecurityService} from "angular-auth-oidc-client";
import {Router} from "@angular/router";
import {Me} from "../models/me";

declare var $: any;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  public version: string = packageJson.version;
  me: Me;

  actions: any = [{
    name : 'Ayant droit',
    profiles: [1, 2, 3, 7],
    id : 1,
    subMenu : [ {
      name : 'Rechercher un ayant droit',
      href : 'ad/search/list',
      profiles: [1, 2, 3, 7]
    }, {
      name : 'Importer depuis Izly',
      href : 'izly/search/list',
      profiles: [1]
    }, {
      name : 'Ajouter un ayant droit',
      href : 'ad/search/ad',
      profiles: [1]
    }, {
      name : 'Importer des ayants droit (.xlsx)',
      href : 'import-ad',
      profiles: [1,2]
    }, {
      name : 'Rechercher une carte',
      href : 'cards',
      profiles: [1,2]
    }, {
      name : 'Visualisation des logs d\'erreurs',
      href : 'logsApi/search',
      profiles: [1]
    }]
  }, {
    name : 'Aides & Bourses',
    profiles: [6],
    id : 4,
    subMenu : [ {
      name : 'Créditer une aide sur Izly',
      href :  '/saga/aides',
      profiles: [6]
    },
      {
        name : 'Interroger l\'api statut boursier',
        href : 'boursiers',
        profiles: [6]
      }]
  }, {
    name : 'Utilisateurs',
    profiles: [1000, 2000],
    id : 5,
    subMenu : [ {
      name : 'Gestion des utilisateurs actifs',
      href : '/utilisateurs/list',
      profiles: [1000, 2000]
    },
    {
      name : 'Gestion des demandes d\'accès',
      href : '/utilisateurs/inactifs',
      profiles: [1000, 2000]
    },
    {
      name : 'Etablissements',
      href : '/utilisateurs/etablissements',
      profiles: [1000, 2000]
    }]
  }, {
    name : 'Paramètres',
    profiles: [1000],
    id : 6,
    subMenu : [{
      name : 'Emission des cartes',
      profiles: [1000],
      href : '#',
      subMenu : [ {
        name : 'Emetteurs',
        href : 'parametres/emetteurSupport',
        profiles: [1000]
      }, {
        name : 'Contrats',
        href : '/parametres/contrats',
        profiles: [1000]
      }]
    }, {
      name : 'Etablissements',
      href : '/parametres/etablissement',
      profiles: [1000]
    }, {
      name : 'Crous',
      href : '/parametres/crous',
      profiles: [1000]
    }, {
      name : 'Tâches',
      href : '/parametres/tache',
      profiles: [1000]
    }, {
      name : 'Exports Izly',
      href : '/parametres/exportizly',
      profiles: [1000]
    }, {
      name : 'Changement d\'identifiant client',
      href : '/parametres/change-identifiants',
      profiles: [1000]
    }, {
      name : 'Publier une annonce',
      href : '/parametres/annonces',
      profiles: [1000]
    }, {
      name : 'Api Xpollens',
      href : '/parametres/xpollens-api',
      profiles: [1000]
    }]
  }];

  annonceList : Array<Annonce> = [];

  constructor(userService: UserService, private oidcSecurityService: OidcSecurityService,
              annonceService: AnnonceService, private modalService: NgbModal, private router: Router) {

    userService.me().subscribe(u => {
      this.filterActions(u.droitList);
      this.me = u;
    });

    annonceService.getActives().subscribe({next: res => {
        this.annonceList = res;
      },
    error: err => {

    }});

  }

  ngOnInit() {
  }

  deconnect() {
    this.oidcSecurityService.logoff().subscribe((result) => console.log(result));
    this.router.navigate(['connexion']);
  }

  isAllowed(action, profiles) {
    if (action.profiles && action.profiles.length) {
      for (let i = 0; i < action.profiles.length; i++) {
        if ($.inArray(action.profiles[i], profiles) >= 0) {
          return true;
        }
      }
      return false;
    }
    return false;
  }

  filterActions(profiles) {
    for (let i = 0; i < this.actions.length; i++) {
      const ac = this.actions[i];
      if (!this.isAllowed(ac, profiles)) {
        this.actions.splice(i, 1);
        i--;
      } else {
        if (ac.subMenu !== undefined && ac.subMenu.length > 0) {
          for (let j = 0; j < ac.subMenu.length; j++) {
            if (!this.isAllowed(ac.subMenu[j], profiles)) {
              ac.subMenu.splice(j, 1);
              j--;
            }
          }
        }
      }
    }
  }

  expiration1mois() {
    const differenceEnMs = new Date(this.me.dateFinValidite).getTime() - new Date().getTime();
    const differenceEnJours = differenceEnMs / (1000 * 60 * 60 * 24);
    return differenceEnJours < 30;
  }
}
