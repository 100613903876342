import {Component, EventEmitter, Input, Output, QueryList, ViewChildren} from '@angular/core';
import {map, Observable, startWith} from "rxjs";
import {FormControl} from "@angular/forms";
import {NgbModal, NgbModalConfig} from "@ng-bootstrap/ng-bootstrap";
import {ToastTool} from "../../../tools/toast.tool";
import {Crous} from "../../../models/crous";
import {CrousService} from "../../../services/crous.service";
import {DialogFormCrousComponent} from "../dialog-form-crous/dialog-form-crous.component";
import {NgbdSortableHeader, SortEvent} from "../../../tools/ngbd-sortable-header.directive";
import {BceService} from "../../../services/bns/bce.service";
import {BceEtablissement} from "../../../models/bce-etablissement";
import {BceEtablissementSearch} from "../../../models/bce-etablissement-search";

@Component({
  selector: 'app-tableau-crous',
  templateUrl: './tableau-crous.component.html',
  styleUrls: ['./tableau-crous.component.css']
})
export class TableauCrousComponent {

  @Input() listeCrous: Crous[] = [];
  @Output() crousModifie = new EventEmitter<Crous>();

// Filtre
  listeCrous$ : Observable<Crous[]>;
  filter = new FormControl('', {nonNullable: true});

  search(text: string): Crous[] {
    return this.listeCrous.filter((crous) => {
      const term = text.toLowerCase();
      return (
        crous.libelle.toLowerCase().includes(term) ||
        crous.bceEtablissement?.denominationPrincipale.toLowerCase().includes(term) ||
        crous.numeroCrous.toString().toLowerCase().includes(term)
      );
    });
  }
  // fin recherche

  pageSize = 20;
  page = 5;

  loading = false;

  constructor(private crousService: CrousService,
              private modalService: NgbModal,
              private bceService: BceService,
              private config: NgbModalConfig) {
    this.loadListeCrous();
  }

  ngOnInit(): void {
  }

  loadListeCrous() {
    this.loading = true;
    const fields = "idCrous," +
      "libelle," +
      "numeroCrous," +
      "dateInsertion," +
      "numeroNouveauCrous," +
      "rneLight.uairne," +
      "rneLight.nomUsuel," +
      "bceEtablissement" +
      "";
    this.crousService.getListeAllCrous(fields).subscribe({
      next: (data) => {
        this.listeCrous = data;

        this.filter.reset();
        this.listeCrous$ = this.filter.valueChanges.pipe(
          startWith(''),
          map((text) => this.search(text)),
        );
        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
        for (let error of err.error) {
          let description = error.error_description;
          ToastTool.danger(description);
        }
      }
    })
  }

  modifierCrous(crous: Crous): void {
    const modalRef = this.modalService.open(DialogFormCrousComponent, {size:"xl"});
    modalRef.componentInstance.crousSelectionne = crous;
    modalRef.componentInstance.crousModifie.subscribe(() => {
      this.loadListeCrous();
    })
  }


  // tri des colonnes
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    for (const header of this.headers) {
      if (header.sortable !== column) {
        header.direction = '';
      }
    }

    if (direction === '' || column === '') {
      this.loadListeCrous();
    } else {
      this.listeCrous$ = this.listeCrous$.pipe(map(arr => arr.sort((a, b) => {
        let res;
        if (column == 'rneLight') {
          res = NgbdSortableHeader.compare(a.rneLight ? a.rneLight.nomUsuel : '', b.rneLight ? b.rneLight.nomUsuel : '');
        } else {
          res = NgbdSortableHeader.compare(a[column], b[column]);
        }
        return direction === 'asc' ? res : -res;
      })));
    }
  }

}
