<form (submit)="save()" [formGroup]="form">
  <div class="modal-header">
    <h4 *ngIf="contratSelectionne.idEmetteurZdcContrat" class="modal-title" id="modal-modif-title">Modification du contrat {{ contratSelectionne.emetteurZdc.raisonSociale }}</h4>
    <h4 *ngIf="!contratSelectionne.idEmetteurZdcContrat" class="modal-title" id="modal-ajout-title">Ajout d'un nouvel émetteur</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('annuler')"></button>
  </div>
  <div class="modal-body">
    <div class="mb-3">
      <label for="emetteur">Emetteur *</label>
      <div class="input-group">
        <select class="form-select form-control" formControlName="emetteur" id="emetteur" type="text" name="emetteur" required #em>
          <option *ngFor="let emetteur of listeEmetteurs" [value]="toStr(emetteur)">{{ emetteur.raisonSociale }}</option>
        </select>
        <button class="btn btn-outline-success" (click)="nouvelEmetteur()" type="button">Créer</button>
      </div>
      <label for="libelle">Libellé *</label>
      <input formControlName="libelle" id="libelle" type="text" maxlength="255" class="form-control" name="libelle"/>
      <label for="crousPrivilegie">Crous privilegié</label>
      <input formControlName="crousPrivilegie" id="crousPrivilegie" type="text" class="form-control" name="crousPrivilegie"/>
      <label for="codeEmetteur">Code émetteur *</label>
      <input formControlName="codeEmetteur" id="codeEmetteur" type="number" class="form-control" name="codeEmetteur"/>
      <label for="limiteInferieure">Numéro Limite Inférieure *</label>
      <div class="input-group">
        <input formControlName="limiteInferieure" id="limiteInferieure" type="text" class="form-control" name="limiteInferieure"/>
        <button class="btn btn-outline-success" (click)="trancheSuivante()" type="button">Tranche suivante</button>
      </div>
      <label for="limiteSuperieure">Numéro Limite Supérieure *</label>
      <input formControlName="limiteSuperieure" id="limiteSuperieure" type="text" class="form-control" name="limiteSuperieure"/>
      <label for="dateDelivrance">date Délivrance *</label>
      <div class="input-group">
        <input formControlName="dateDelivrance" id="dateDelivrance" ngbDatepicker #d="ngbDatepicker" class="form-control" name="dateDelivrance"/>
        <button class="btn btn-outline-secondary bi bi-calendar3" (click)="d.toggle()" type="button"></button>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="activeModal.dismiss('annuler')" class="btn btn-danger">Annuler</button>
    <button [disabled]="!form.valid" type="submit" class="btn btn-primary">Enregistrer</button>
  </div>
</form>
