///<reference path="../../tools/ngb-date-fr-parser-formatter.ts"/>
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RightholderService} from '../../services/rightholder.service';
import {Rightholder} from '../../models/rightholder';
import {CrousService} from '../../services/crous.service';
import {Crous} from '../../models/crous';
import {EtablissementService} from '../../services/etablissement.service';
import {environment} from '../../../environments/environment';
import {RneService} from '../../services/rne.service';
import {Rne} from '../../models/rne';
import {ToastTool} from '../../tools/toast.tool';
import {Card} from '../../models/card';
import {NgbDateAdapter, NgbDateParserFormatter, NgbModal, NgbNav, NgbNavChangeEvent} from '@ng-bootstrap/ng-bootstrap';
import {NgbDateNativeAdapter} from '../../tools/datepicker-adapter';
import {NgbDateFRParserFormatter} from '../../tools/ngb-date-fr-parser-formatter';
import {UntypedFormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';
import {concat, distinctUntilChanged, Observable, of, Subject} from 'rxjs';
import {UserService} from '../../services/user.service';
import * as moment from 'moment';
import {HistoriqueRne} from '../../models/historique-rne';
import {catchError, switchMap, tap} from 'rxjs/operators';
import {RightholderHistorique} from '../../models/rightholder-historique';
import {EtablissementClient} from "../../models/etablissement-client";
import {LectureCarteComponent} from "../../lecture-carte/lecture-carte.component";
import {AyantDroit} from "../../models/ayant-droit";
import {SmartCard} from "../../models/smart-card";


declare var $: any;

@Component({
  selector: 'app-ayant-droit-form',
  templateUrl: './ayant-droit-form.component.html',
  styleUrls: ['./ayant-droit-form.component.css'],
  providers: [
      {provide: NgbDateAdapter, useClass: NgbDateNativeAdapter},
      {provide: NgbDateParserFormatter, useFactory: () => {
        return new NgbDateFRParserFormatter();
      }}]
})
export class AyantDroitFormComponent implements OnInit, OnDestroy, AfterContentChecked {

  identifier: string;
  rightholder = new Rightholder();
  loading = false;
  crousList: Array<Crous>;
  rneList: Array<Rne>;
  rateStatus = '';
  indice: number;
  cardList: Array<Card>;
  verrouillerCompteActif = true;
  etablissementSelected: any;
  oldVestionHref = '/client/ficheclient.jsf';
  public importFromIzly = false;

  hideBottomButton = false;
  active = 0;
  selectedFile: File;

  etablissementList :  Observable<Array<EtablissementClient>>;
  etablissementInput = new Subject<string>();

  lectureSeule = false;

  dtTriggerCard: Subject<any> = new Subject();
  dtTriggerHistorique: Subject<any> = new Subject();

  droitremboursementcvec = false;
  droitOpposition = false;
  droitTransfertCarte = false;

  droitUsageSpringZDC = false;

  droitAdminNational = false;

  historiqueRneList: Array<HistoriqueRne>;

  // PutTransfertCarte
  public ptcIdentifiantClientSource: string;
  public ptcRecuperCarteCloture = false;
  public ptcRightholder: Rightholder;
  public ptcCardList: Array<Card>;

  modal: any;

  @ViewChild('tabs', { static: true })
  private tabs: NgbNav;

  public historicSize = 0;
  public paginateHistoric: Array<RightholderHistorique>;
  public pageH = 1;
  public pageHSize = 3;
  public pageC = 1;
  public pageCSize = 3;

  public cardsSize = 0;
  public cards: Array<Card>;

  constructor(private userService: UserService, private fb: UntypedFormBuilder, private crousService: CrousService,
              private activatedRoute: ActivatedRoute,
              private etablissementService: EtablissementService,
              private rightholderService: RightholderService, private rneService: RneService, private router: Router,
              private modalService: NgbModal, private cdRef: ChangeDetectorRef) {




  }

  ngAfterContentChecked() {
    this.cdRef.detectChanges();
  }


  ngOnDestroy(): void {
    this.dtTriggerCard.unsubscribe();
    this.dtTriggerHistorique.unsubscribe();
  }


  loadSmartCards() {

    if (this.identifier != null && this.identifier.length > 0) {
      this.rightholderService.getSmartCard(this.identifier).subscribe(res => {
        this.cardList = res;
        this.cardsSize = this.getSize(this.cardList);
        this.refreshCard();
      });
    }
  }



  testDroitModification(droitList) {
    if (!droitList.includes(1) && !droitList.includes(2)) {
      this.lectureSeule = true;
    }
  }


  testDroitOpposition(droitList) {
    if (droitList.includes(8)) {
      this.droitOpposition = true;
    }
  }

  testDroitTransfertCarte(droitList) {
    if (droitList.includes(19)) {
      this.droitTransfertCarte = true;
    }
  }

  testDroitUsageSpringZDC(droitList) {
    if (droitList.includes(12)) {
      this.droitUsageSpringZDC = true;
    }
  }


  getSize(list: Array<any>) {
    if (!list) {
      return 0;
    }
    return list.length;

  }

  getPostfixPreprod() {
    if (environment.apiB4Url.includes('-pp')) {
      return '-pp';
    }
    return '';
  }

  loadRightHolder(update: boolean) {

    if (this.identifier != null && this.identifier.length > 0) {
      this.rightholderService.get(this.identifier).subscribe(res => {
        this.rightholder = res;
        this.etablissementSelected =this.rightholder.crousParts.etablissementClient;
        this.verrouillerCompteActif = this.rightholder.izlyParts != null ? this.rightholder.izlyParts.statutCompte > 1 : true;
        this.initRemoteCompleterEtablissement();
        this.historicSize = this.getSize(this.rightholder.historic);
        this.refreshHistory();
        this.oldVestionHref += '?idClient=' + this.rightholder.crousParts.idClient;
        this.loading = false;
        this.rightholderService.getHistoriqueRne(this.identifier).subscribe( result => {
          this.historiqueRneList = result;
        });
        if (this.rightholder.rnePrioritaire === undefined) {
          this.rightholder.rnePrioritaire = null;
        }

      }, res => {
        ToastTool.danger(res.error.error_description);
        this.loading = false;
      });

    } else {
      this.verrouillerCompteActif = false;
      this.rightholder.crousParts.crous = this.crousList[0];
      this.initRemoteCompleterEtablissement();
      this.loading = false;

    }
  }

  loadRne(idEtablissement: number) {
    this.rneService.getRneFromEtablissement(idEtablissement).subscribe( res => {
      this.rneList = res;

      const rneOrgCode =  res.find(x => x.uairne === this.rightholder.rneOrgCode);
      const rneDepCode =  res.find(x => x.uairne === this.rightholder.rneDepCode);

      this.rightholder.rneOrgCode = (rneOrgCode !== undefined ? rneOrgCode.uairne : res[0].uairne);
      this.rightholder.rneDepCode = (rneDepCode !== undefined ? rneDepCode.uairne : res[0].uairne);
    });
  }

  selectCrous(event) {

    this.initRemoteCompleterEtablissement();
    this.selectEtablissement(null);
    this.etablissementSelected = null;
    this.rneList = null;

  }

  initRemoteCompleterEtablissement() {

    let idCrous = 0;
    if (this.rightholder.crousParts.crous != null && this.rightholder.crousParts.crous.idCrous != null) {
      idCrous = this.rightholder.crousParts.crous.idCrous;
    }

    this.etablissementList = concat(
      of([]), // default items
      this.etablissementInput.pipe(
        distinctUntilChanged(),
        switchMap(term => this.etablissementService.listEtabByIdCrous(idCrous, term).pipe(
          catchError(() => of([])), // empty list on error
        ))
      )
    );
  }


  selectEtablissement(event) {
    if (event != null) {
      this.rightholder.crousParts.etablissementClient = event;
      this.etablissementSelected = event;
      this.loadRne(event.idEtablissementClient);
      return;
    } else {
      this.etablissementSelected = null;
      this.rightholder.crousParts.etablissementClient = null;
      this.rightholder.rneOrgCode = '';
      this.rightholder.rneDepCode = '';
    }
  }


  submitForm(form: NgForm) {

    this.loading = true;

    if (this.rightholder.crousParts.idClient > 0) {
        this.updateRightHolder();
    } else {
        this.createRightHolder();
    }

  }

  createRightHolder() {
    this.loading = true;
    this.rightholder.email = this.rightholder.email.trim();

    if (!this.importFromIzly) { // si pas import IZly => on force l'identifiant avec l'INE ou l'email, sinon, c'est l'identifiant d'izly
      if ((+this.rightholder.idCompanyRate === 10 || +this.rightholder.idCompanyRate === 900) && this.rightholder.ine) {
        this.rightholder.identifier = this.rightholder.ine;
      } else {
        this.rightholder.identifier = this.rightholder.email;
      }
    }

    this.rightholderService.post(this.rightholder).subscribe( res => {
      ToastTool.success('Ayant droit créé');
      this.router.navigate(['ad/search/ad/' + this.rightholder.identifier]);
      this.loading = false;
    }, res => {
      ToastTool.danger(res.error.error_description === undefined ? res.error.errors === undefined ?
        'Une erreur est survenue (' + res.status + ')' : res.error.errors[0].message +
        ' : ' + res.error.errors[0].field : res.error.error_description);
      this.loading = false;
    });
  }

  updateRightHolder() {

    if (this.rightholder.izlyParts && this.rightholder.izlyParts.statutBlocage && this.rightholder.izlyParts.statutBlocage >= 3) {
      ToastTool.danger('Impossible de modifier un compte gelé ou en opposition');
      this.active = 7;
      this.loading = false;
      return;
    }

    this.rightholder.email = this.rightholder.email.trim();
    this.rightholderService.put(this.rightholder).subscribe( res => {
      ToastTool.success('Ayant droit modifié');
      this.loadRightHolder(true);
      this.loading = false;
    }, res => {
      ToastTool.danger(res.error.error_description === undefined ? res.error.errors === undefined ?
        'Une erreur est survenue (' + res.status + ')' : res.error.errors[0].message +
        ' : ' + res.error.errors[0].field : res.error.error_description);
      this.loading = false;
    });
  }


  ngOnInit() {

    this.userService.me().subscribe(u => {

      this.droitAdminNational = u.adminNational;

      this.testDroitModification(u.droitList);
      this.testDroitOpposition(u.droitList);
      this.testDroitUsageSpringZDC(u.droitList);
      this.testDroitTransfertCarte(u.droitList);
    });

    this.identifier = this.activatedRoute.snapshot.params.id;

    const importIzly = this.activatedRoute.snapshot.queryParamMap.get('import');

    if (importIzly === '1') {
      this.importFromIzly = true;
      this.rightholder.lastName = this.activatedRoute.snapshot.queryParamMap.get('nom');
      this.rightholder.firstName = this.activatedRoute.snapshot.queryParamMap.get('prenom');
      const birthDate = this.activatedRoute.snapshot.queryParamMap.get('dateNaissance');
      if (birthDate && birthDate.length === 8) {
        this.rightholder.birthDate = birthDate.substr(0, 4) + '-' +
          birthDate.substr(4, 2) + '-' + birthDate.substr(6, 2) + 'T00:00:00.000+01' ;
      }
      this.rightholder.email = this.activatedRoute.snapshot.queryParamMap.get('email');
      this.rightholder.identifier = this.activatedRoute.snapshot.queryParamMap.get('id');
      this.rightholder.rneDepCode = this.activatedRoute.snapshot.queryParamMap.get('rne');
      this.rightholder.idCompanyRate = +this.activatedRoute.snapshot.queryParamMap.get('societe');
      this.rightholder.idRate = +this.activatedRoute.snapshot.queryParamMap.get('tarif');
      const dueDate = this.activatedRoute.snapshot.queryParamMap.get('dateFinValidite');
      if (dueDate && dueDate.length === 8) {
        this.rightholder.birthDate = dueDate.substr(0, 4) + '-' + dueDate.substr(4, 2) + '-' + dueDate.substr(6, 2) + 'T00:00:00.000+01' ;
      }
    }


    this.crousService.listCrousAutorises().subscribe(c => {
      this.loading = true;
      this.crousList = c;
      this.loadRightHolder(false);
      this.loadSmartCards();
    });

  }

  crousEquality(item1: Crous, item2: Crous) {
    return item1.idCrous === item2.idCrous;
  }

  renvoiMailActivation() {
    this.rightholderService.renvoieMail(this.identifier).subscribe({
      next: res => {
        ToastTool.success('Email envoyé');
    }, error: error => {
        ToastTool.danger('Une erreur s\'est produite');
      }});
  }

  smartCardTechnology(appl, pixNn, pixSs) {
    const technoString = pixSs + '-' + pixNn + '-' + appl;
    switch (technoString) {
      case '03-0000-D1':
        return 'Desfire';
      case '03-0001-00':
        return 'Mifare Classic 1K';
      case '03-0002-00':
        return 'Mifare Classic 4K';
      case '03-0003-00':
      case '03-003A-001':
        return 'Ultralight C';
      case '03-0000-00':
        return 'Mifare+';
      case '07-0000-C0':
        return 'Calypso';
      default:
        return technoString;
    }
  }

  calculTarif() {
    if (this.rateStatus === '' || this.indice == null || this.indice <= 0
      || this.rightholder.crousParts.etablissementClient.idEtablissementClient === undefined) {
      ToastTool.danger('Vous devez selectionner un établissement, un statut et saisir un indice');
      return;
    }
    this.loading = true;
    this.rightholderService.calculTarif(this.rightholder.crousParts.etablissementClient.idEtablissementClient,
      this.rateStatus, this.indice).subscribe(res => {
      this.rightholder.idRate = res.codeTarif;
      this.rightholder.idCompanyRate = res.codeSociete;
      this.loading = false;
    }, res => {
      this.loading = false;
      ToastTool.dangerReponseApi(res);
      this.rightholder.idRate = null;
      this.rightholder.idCompanyRate = null;
    });
  }

  sendCardToIzly(card) {

    const jsonString = '{"idZdc":"' +  card.idZdc + '","uid":"' + card.uid + '"}';
    const request = JSON.parse(jsonString);
    this.loading = true;

    this.rightholderService.resendCard(this.rightholder, card.idZdc, card.uid, request).subscribe( res =>  {
      this.loading = false;
      ToastTool.success('La carte a été créé dans izly');
    }, res => {
      this.loading = false;
      ToastTool.dangerReponseApiAvecMessage('Impossible d\'ajouter la carte', res);

    });

  }

  gereOpposition(card) {

    const now = moment(new Date(), moment.ISO_8601).format('YYYY-MM-DDTHH:mm:ss.000Z');

    let cancelDate = '';
    let revalidationDate = '';
    let reason = '';
    if (card.cancelDate == null) {
      cancelDate = now;
      reason = 'DP';
    } else {
      revalidationDate = now;
    }

    const jsonString = '{"cancelDate":"' +  cancelDate + '","reason":"' + reason + '","revalidationDate":"' + revalidationDate + '"}';
    const request = JSON.parse(jsonString);


    this.rightholderService.patchCard(this.rightholder, card.idZdc, card.uid, request).subscribe( res =>  {
      this.rightholderService.getSmartCard(this.identifier).subscribe(res2 => {
        this.cardList = res2;
        this.cardsSize = this.getSize(this.cardList);
        this.refreshCard();
        ToastTool.success('Status de la carte modifier avec succès');
      });
    }, res => {
      this.loading = false;
      ToastTool.danger('Impossible de modifier le status de la carte');
    });
  }

  onFileChanged(event) {
    this.selectedFile = event.target.files[0];
  }

  onTabChange($event: NgbNavChangeEvent) {
    console.log('tab ' + $event.nextId);
    if ($event.nextId === 2 || $event.nextId === 5 || $event.nextId === 6
      || $event.nextId === 7 || $event.nextId === 9 || $event.nextId === 11) {
      this.hideBottomButton = true;
      return;
    }
    this.hideBottomButton = false;
  }

  isCertificateValid(code: string) {
    code = code.replace(/\s|-|_/g, '');
    code = code.toUpperCase();
    if (code.length === 10) {
      return true;
    }
    if (code.length !== 12) {
      return false;
    }

    const key = this.calculateKey(code.substring(0, 10));
    let keyString: string;
    if (key < 10) {
      keyString = '0' + key;
    } else {
      keyString = key.toString();
    }

    return keyString === code.substring(10, 12);

  }

  calculateKey(chars: string) {
    let key = 0;
    for (let i = 0; i < chars.length; i++) {
      key += chars[i].charCodeAt(0) * (i + 1);
    }
    return 97 - (key % 97);
  }


  openPopupCarte() {
    const modal = this.modalService.open(LectureCarteComponent, {size:'xl'});
    const ad = new AyantDroit();
    ad.nom = this.rightholder.lastName;
    ad.prenom = this.rightholder.firstName;
    modal.componentInstance.ayantDroit = ad;
    modal.componentInstance.buttonLabel = 'Ajouter la carte à l\'ayant droit';
    modal.result.then(res =>{
      console.log(res);
      if (!res || !res.identifiantCnous || !res.uid) {
        ToastTool.danger("carte non lue");
        return;
      }
      const zdc = res;
      this.rightholderService.addCard(this.rightholder.identifier, SmartCard.convertToSmartCard(zdc)).subscribe({
        next:res=>{
          ToastTool.success("Carte ajoutée");
          this.loadSmartCards();
        },
        error: err => {
          ToastTool.dangerReponseApi(err);
        }});
    });
  }

  deleteADandCard() {
    this.loading = true;
    this.rightholderService.delete(this.activatedRoute.snapshot.params.id).subscribe( res => {
      this.router.navigate(['/']);
      ToastTool.success('Ayant droit supprimé');
      this.loading = false;
    }, res => {
      ToastTool.danger(res.error.error_description === undefined ? res.error.errors === undefined ?
        'Une erreur est survenue (' + res.status + ')' : res.error.errors[0].message +
        ' : ' + res.error.errors[0].field : res.error.error_description);
      this.loading = false;
    });
  }

  ptcRechercheCompteCible() {
    this.ptcRecuperCarteCloture = false;
    if (this.rightholder.identifier === this.ptcIdentifiantClientSource) {
      ToastTool.danger('Vous ne pouvez pas transférer des cartes sur le même compte');
      return;
    }

    this.loading = true;

    this.rightholderService.get(this.ptcIdentifiantClientSource)
      .pipe(
        switchMap(res => {
        this.ptcRightholder = res;
        return this.rightholderService.getSmartCard(this.ptcIdentifiantClientSource);
      }))
      .subscribe(res2 => {
        this.ptcCardList = res2;
        this.loading = false;
    }, res => {
      ToastTool.dangerReponseApi(res);
      this.loading = false;
    });

  }

  transfertCarte() {
    this.loading = true;
    if (this.ptcRecuperCarteCloture) {
      this.ptcRightholder = new Rightholder();
      this.ptcRightholder.identifier = this.rightholder.identifier;
    }

    this.rightholderService
      .transfertCarte(this.ptcRightholder.identifier, this.rightholder.identifier)
      .pipe(
        switchMap(res => this.rightholderService.getSmartCard(this.rightholder.identifier))
      )
      .subscribe(res => {
        this.cardList = res;
        this.ptcReset();
        ToastTool.success('Carte(s) transférée(s)');
        this.active = 2;
        this.cardsSize = this.getSize(this.cardList);
        this.refreshCard();
        this.loading = false;
      }, res => {
        ToastTool.dangerReponseApiAvecMessage('Impossible de transférer les cartes',res);
        this.loading = false;
      });
  }

  open(content: TemplateRef<any>) {
    this.modal = this.modalService.open(content);
    this.modal.result.then((result) => {
      this.transfertCarte();
    }, (reason) => {
      console.log('Annulation');
    }).catch(r => {
    });
  }


  public getCarteMessage() {
    if (this.ptcCardList.length === 1) {
      return 'la carte';
    } else {
      return 'les ' + this.ptcCardList.length + ' cartes';
    }
  }

  private ptcReset() {
    this.ptcRightholder = null;
    this.ptcRecuperCarteCloture = false;
    this.ptcCardList = null;
    this.ptcIdentifiantClientSource = null;
  }

  decimal(uid: string) {

    const val = parseInt(uid, 16);
    if (val.toString().length > 0) {
      const v = val.toString();
      return v.substr(0, 4) + ' ' +  v.substr(4, 4) + ' ' + v.substr(8, 4) + ' ' + v.substr(12, 4);
    }

  }

  refreshHistory() {
    if (this.rightholder && this.rightholder.historic) {
      this.paginateHistoric = this.rightholder.historic.slice((this.pageH - 1) * this.pageHSize, (this.pageH - 1) * this.pageHSize + this.pageHSize);
    }
  }

  refreshCard() {
    if (this.cardList) {
      console.log((this.pageC - 1) * this.pageCSize)
      this.cards = this.cardList.slice((this.pageC - 1) * this.pageCSize, (this.pageC - 1) * this.pageCSize + this.pageCSize);
    }
  }
}


