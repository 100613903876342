import {Crous} from "./crous";

export class CrousDto {
  idCrous: number;
  numeroCrous: number;
  libelle: string;

  constructor(crous: Crous) {
    this.numeroCrous = crous.numeroCrous;
  }
}
