import {RnePut} from "./rne-put";
import {Rne} from "./rne";

export class RnePost extends RnePut{
  uairne: string;

  constructor(rne: Rne) {
    super(rne);
    this.uairne = rne.uairne;
  }
}
