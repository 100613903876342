import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {HttpService} from "./http.service";
import {Crous} from "../models/crous";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import {DemandeAyantDroit} from "../models/demande-ayant-droit";
import {ADSupport} from "../models/adsupport";

@Injectable({
  providedIn: 'root'
})
export class DemandeAyantDroitService {


  constructor(private http: HttpClient,
              private httpService: HttpService) {
  }

  getListeDemandeAyantDroit(fields: string): Observable<DemandeAyantDroit[]> {
    const params = this.httpService.buildParams({fields: fields})
    return this.http.get<Array<DemandeAyantDroit>>(environment.apiB4Url + '/v1/demandesayantdroit', {params, withCredentials: true});
  }

  getListeADSupportFromDemandeAyantDroit(demande: DemandeAyantDroit, fields?: string): Observable<ADSupport[]> {
    const params = this.httpService.buildParams({fields: fields})
    return this.http.get<Array<ADSupport>>(environment.apiB4Url + '/v1/demandesayantdroit/' + demande.idDemandeAyantDroit + '/lignes', {params, withCredentials: true});
  }
}
