<div class="col-12 col-md-3 col-xl-2 bd-sidebar collapse width show" id="leftmenu" style="width: 320px; padding: 1rem">
  <h5 style="margin-top: 10px">Rechercher&nbsp;dans&nbsp;les logs d'api</h5>
  <form class="bd-search" novalidate (ngSubmit)="search()" [formGroup]="searchForm">


    <div class="mb-3">
      <input type="text" class="form-control" id="terme" formControlName="terme" [ngClass]="{'is-invalid': !searchForm.valid  && (terme.dirty || terme.touched || formSubmited)}">
    </div>



    <div class="form-group" style="margin-top: 10px">
      <button class="btn btn-primary" type="submit">
        <span class="btn-text">Rechercher</span>
      </button>
    </div>
  </form>

</div>
