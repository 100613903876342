export const environment = {
  env:'PPD',
  production: true,
  beforeizlyDomain: 'https://beforeizly-pp.nuonet.fr',
  apiBoursier: 'https://api-pp.nuonet.fr/statut-boursier/v1/boursier',
  apiB4Url: 'https://api-pp.nuonet.fr/beforeizly',
  apiB4Batch: 'https://api-pp.nuonet.fr/beforeizly-batch',
  apiBnsUrl: 'https://api-pp.nuonet.fr/bns',
  authority: 'https://acces-int.nuonet.fr/cnous-int/oidc/.well-known/openid-configuration',
  clientId: '96c698d0-92fa-4e2b-8698-d092fa8e2ba1'
};
