export class ExcelAd {

  nom:string;
  prenom:string;
  email:string;
  rneEtab:string;
  codeSociete:number;
  codeTarif:number;
  dateNaissance:string;
  codeEtudiant:string;
  emailPerso:string;
  ine:string;
  telephone:string;
  rneComp:string;
  dateApplicationFutur:string;
  codeSocieteFutur:number;
  codeTarifFutur:number;
  crous:number;

  public static excelToObj(row) {
    const excelAd = new ExcelAd();
    excelAd.nom = row.getCell('A').value; // 0
    excelAd.prenom = row.getCell('B').value; // 1
    excelAd.email = row.getCell('C').value; // 2
    excelAd.rneEtab = row.getCell('D').value; // 3
    excelAd.codeSociete = row.getCell('E').value; // 4
    excelAd.codeTarif = row.getCell('F').value; // 5
    excelAd.dateNaissance = row.getCell('G').value; // 6
    excelAd.codeEtudiant = row.getCell('H').value; // 7
    excelAd.emailPerso = row.getCell('I').value; // 8
    excelAd.ine = row.getCell('J').value; // 9
    excelAd.telephone = row.getCell('K').value // 10
    excelAd.rneComp = row.getCell('L').value; // 11
    excelAd.dateApplicationFutur = row.getCell('AA').value; // 26
    excelAd.codeSocieteFutur = row.getCell('AB').value; // 27
    excelAd.codeTarifFutur = row.getCell('AC').value; // 28
    excelAd.crous = row.getCell('AD').value; // 29
    return excelAd;
  }


}
