import {EmetteurZdc} from "./emetteur-zdc";
import {EtablissementClient} from "./etablissement-client";

export class EmetteurZdcContrat {
    idEmetteurZdcContrat : number;
    emetteurZdc : EmetteurZdc;
    etablissementclient! : EtablissementClient;
    codeEmetteur : number;
    dateDebutContrat : Date;
    dateFinContrat : string;
    numeroLimiteInferieure : number;
    numeroLimiteSuperieure : number;
    dateInsertion : string;
    libelle : string;
    crousPrivilegie! : string;
}
