import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {AideSaga} from "../models/aide-saga";
import {SmartCard} from "../models/smart-card";

@Injectable({
  providedIn: 'root'
})
export class AideSagaService {

  constructor(private http: HttpClient) {
  }

  getAides(codeEtat = 3){
    return this.http.get<Array<AideSaga>>(environment.apiB4Url + '/v1/saga-aides?e='+codeEtat,{ withCredentials: true });
  }

  getAide(idAide: number){
    return this.http.get<AideSaga>(environment.apiB4Url + '/v1/saga-aides/'+idAide,{ withCredentials: true });
  }


  creditAide(idAide :number, smartCard: SmartCard){
    return this.http.post(environment.apiB4Url + '/v1/saga-aides/'+idAide, smartCard,{ withCredentials: true });

  }

}
