<div class="loading" [hidden]="!loading"></div>
<div class="row" style="margin-top: 20px;margin-left: 20px;">
  <div class="col">
  <form novalidate (ngSubmit)="submitForm(myForm)" #myForm="ngForm">

    <div class="row">
      <div class="col-md">
        <div class="form-group row">
          <label for="crous" class="col-md-3 col-form-label">Crous</label>

          <div class="col-7">
            <select class="form-select" id="crous" name="crous" [(ngModel)]="rightholder?.crousParts.crous" (change)="selectCrous($event)" [compareWith]="crousEquality" required  [disabled]="lectureSeule">
              <option  *ngFor="let c of crousList" [ngValue]="c">{{c.libelle}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-md">
        <div class="form-group row">
          <label class="col-md-3 col-form-label">Etablissement</label>
          <div class="col-7">
            <ng-select [items]="etablissementList | async"
                       bindLabel="nom"
                       [ngClass]="{'ng-invalid': etablissement.valid}"
                       [multiple]="false"
                       [hideSelected]="true"
                       [minTermLength]="2"
                       typeToSearchText="Saisir 2 lettres minimum"
                       [typeahead]="etablissementInput"
                       (change)="selectEtablissement($event)"
                       #etablissement="ngModel"
                       name="etablissement"
                       [(ngModel)]="etablissementSelected"
                       required>
            </ng-select>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md">
        <div class="form-group row" [class.has-danger]="!lastName.valid && !lastName.disabled && !lectureSeule">
          <label for="lastName" class="col-md-3 col-form-label">Nom</label>
          <div class="col-7">
            <input type="text" [class]="(lastName.valid || lastName.disabled) ? 'form-control' : 'col-md-6 form-control form-control-danger'" id="lastName" name="lastName" [disabled]="verrouillerCompteActif || lectureSeule" [(ngModel)]="rightholder.lastName" #lastName="ngModel" required>
          </div>
        </div>
      </div>
      <div class="col-md">
        <div class="form-group row" [class.has-danger]="!firstName.valid && !firstName.disabled && !lectureSeule">
          <label for="firstName" class="col-md-3 col-form-label">Prénom</label>
          <div class="col-7">
          <input type="text" [class]="(firstName.valid || firstName.disabled) ? 'form-control' : 'col-md-6 form-control form-control-danger'" id="firstName" name="firstName" [disabled]="verrouillerCompteActif || lectureSeule" [(ngModel)]="rightholder.firstName" #firstName="ngModel" required>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md">
        <div class="form-group row" [class.has-danger]="!email.valid && !lectureSeule">
          <label for="email" class="col-md-3 col-form-label">Email&nbsp;principal</label>
          <div class="col-7">
          <input type="text" [class]="(email.valid) ? 'form-control' : 'form-control form-control-danger'" id="email" name="email" [(ngModel)]="rightholder.email" #email="ngModel" [disabled]="lectureSeule" required>
          </div>
        </div>
      </div>
      <div class="col-md">
        <div class="form-group row">
          <label for="secondaryEmail" class="col-md-3 col-form-label">Email&nbsp;secondaire</label>
          <div class="col-7">
            <input type="text" class="form-control" id="secondaryEmail" [disabled]="verrouillerCompteActif || lectureSeule" name="secondaryEmail" [(ngModel)]="rightholder.secondaryEmail">
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md">
        <div class="form-group row">
          <label for="identifier" class="col-md-3 col-form-label">Identifiant</label>
          <div class="col-7">
            <input type="text" readonly class="form-control-plaintext" id="identifier" name="identifier" [(ngModel)]="rightholder.identifier" [disabled]="lectureSeule">
          </div>
        </div>
      </div>
      <div class="col-md">
        <div class="form-group row" [class.has-danger]="!dueDate.valid && !lectureSeule">
          <label for="dueDate" class="col-md-3 col-form-label">Date&nbsp;fin&nbsp;validité</label>
          <div class="col-3">
            <div class="input-group ">
            <input type="text" id="dueDate" name="dueDate"  placeholder="jj/mm/aaaa"
                   [class]="(dueDate.valid) ? 'col-md-2 form-control' : 'col-md-2 form-control form-control-danger'"
                   [ngModel]="rightholder.dueDate" (ngModelChange)="rightholder.dueDate = $event" ngbDatepicker #d="ngbDatepicker" #dueDate="ngModel" [disabled]="lectureSeule" required>
              <button class="btn btn-outline-secondary bi bi-calendar3" (click)="d.toggle()" type="button"></button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md">
        <div class="form-group row">
          <label for="cellNumber" class="col-md-3 col-form-label">Tel.&nbsp;Mobile</label>
          <div class="col-7">
          <input type="text" class="form-control" id="cellNumber" [disabled]="verrouillerCompteActif || lectureSeule" name="cellNumber" [(ngModel)]="rightholder.cellNumber" >
          </div>
        </div>
      </div>
      <div class="col-md">
        <div class="form-group row">
          <label for="birthDate" class="col-md-3 col-form-label">Date&nbsp;naissance</label>
          <div class="col-3">
            <div class="input-group ">
              <input type="text" class="col-md-2 form-control" id="birthDate" name="birthDate" placeholder="jj/mm/aaaa"
                     [disabled]="lectureSeule || (rightholder.identifier && rightholder.identifier.length > 0 && !importFromIzly)"
                    [ngModel]="rightholder.birthDate" (ngModelChange)="rightholder.birthDate = $event" ngbDatepicker #a="ngbDatepicker">
                <button class="btn btn-outline-secondary bi bi-calendar3" (click)="a.toggle()" type="button"></button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md">
        <div class="form-group row">
          <label for="ine" class="col-md-3 col-form-label">INE / INES</label>
          <div class="col-3">
          <input type="text" class="form-control" id="ine" name="ine" [(ngModel)]="rightholder.ine" [disabled]="lectureSeule">&nbsp;
          </div>
          <div class="col-3">
          <input type="text" class="form-control" id="ines" name="ines" [(ngModel)]="rightholder?.crousParts.ines" [disabled]="lectureSeule">
          </div>
        </div>
      </div>
      <div class="col-md">
        <div class="form-group row">
          <label for="rneOrgCode" class="col-md-3 col-form-label">Rne&nbsp;Etab.&nbsp;/&nbsp;Composante</label>
          <div class="col-3">
            <input type="text" class="form-control" id="rneOrgCode" name="rneOrgCode" [(ngModel)]="rightholder.rneOrgCode" [disabled]="lectureSeule">&nbsp;
          </div>
          <div class="col-3">
            <input type="text" class="form-control" id="rneDepCode" name="rneDepCode" [(ngModel)]="rightholder.rneDepCode" [disabled]="lectureSeule">
          </div>
        </div>
      </div>
    </div>

    <div class="row tabs">
      <div class="col-md">

        <ul #nav="ngbNav" ngbNav [(activeId)]="active" [destroyOnHide]=false (navChange)="onTabChange($event)" #tabs class="nav-tabs">

          <li [ngbNavItem]="0">
            <a ngbNavLink>Tarif</a>
            <ng-template ngbNavContent>
              <div class="bd-example">
                <div class="row">
                  <div class="col-md">

                      <div class="form-group row">
                        <label for="rateStatus" class="col-md-3 col-form-label">Statut tarifaire</label>
                        <div class="col-7">
                          <select id="rateStatus" class="col-md-5 form-select" [(ngModel)]="rateStatus" name="rateStatus" [disabled]="lectureSeule">
                            <option value="">Choisissez un statut</option>
                            <option value="etd">etudiant</option>
                            <option value="prs">personnel</option>
                            <option value="stg">stagiaire</option>
                            <option value="fct">formation continue</option>
                            <option value="fpa">formation par apprentissage</option>
                            <option value="psg">passager</option>
                            <option value="po">personnel ouvrier</option>
                            <option value="ctr">contractuel</option>
                            <option value="hbg">heberge</option>
                            <option value="hb2">heberge 2</option>
                          </select>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="indice" class="col-md-3 col-form-label">Indice</label>
                        <div class="col-2">
                          <input type="text" class="form-control" id="indice" [(ngModel)]="indice" name="indice" [disabled]="lectureSeule">
                        </div>
                        <div class="col-md-4">
                          <button type="button" (click)="calculTarif()" class="btn btn-secondary" placement="right" [disabled]="lectureSeule"
                                  ngbTooltip="selectionner un etablissement, un statut et saisir un indice">Calculer le tarif ></button>
                        </div>
                      </div>

                  </div>
                  <div class="col-md">

                    <div class="form-group row"  [class.has-danger]="!idCompanyRate.valid && !lectureSeule">
                      <label for="idCompanyRate" class="col-md-3 col-form-label">Société</label>
                      <div class="col-2">
                        <input type="number" [class]="(idCompanyRate.valid) ? 'form-control' : 'form-control form-control-danger'" id="idCompanyRate" [(ngModel)]="rightholder.idCompanyRate" name="idCompanyRate" #idCompanyRate="ngModel" [disabled]="lectureSeule" required>
                      </div>
                    </div>
                    <div class="form-group row"  [class.has-danger]="!idRate.valid && !lectureSeule">
                      <label for="idRate" class="col-md-3 col-form-label">Tarif</label>
                      <div class="col-2">
                        <input type="number" [class]="(idRate.valid) ? 'form-control' : 'form-control form-control-danger'" id="idRate" [(ngModel)]="rightholder.idRate" name="idRate" #idRate="ngModel" [disabled]="lectureSeule" required>
                      </div>
                    </div>

                  </div>


                </div>

                <div class="row">&nbsp;</div>

                <div class="row">
                  <div class="col-md">

                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="" id="applyRateForced" [(ngModel)]="rightholder.applyRateForced" name="applyRateForced" [disabled]="lectureSeule">
                      <label class="form-check-label" for="applyRateForced">
                        Forcer l'application de la tarification ci-contre
                      </label>
                    </div>
                  </div>

                  <div class="col-md">
                    <div class="form-group row">
                      <label for="idCompanyRateForced" class="col-md-3 col-form-label">Société&nbsp;forcée</label>
                      <div class="col-2">
                        <input type="text" class="col-md-2 form-control" id="idCompanyRateForced" [(ngModel)]="rightholder.idCompanyRateForced"  name="idCompanyRateForced" [disabled]="lectureSeule">
                      </div>
                    </div>
                  </div>

                </div>

                <div class="row">
                  <div class="col-md">
                    <div class="form-group row">
                      <label for="origine" class="col-md-3 col-form-label">Origine</label>
                      <div class="col-2">
                        <input type="text" readonly [value]="rightholder.crousParts?.origine" name="origine" id="origine" class="col-md-2 form-control">
                      </div>
                    </div>
                  </div>

                  <div class="col-md">
                    <div class="form-group row">
                      <label for="idRateForced" class="col-md-3 col-form-label">Tarif&nbsp;forcé</label>
                      <div class="col-2">
                        <input type="text" class="col-md-2 form-control" id="idRateForced" [(ngModel)]="rightholder.idRateForced" name="idRateForced" [disabled]="lectureSeule">
                      </div>
                    </div>
                  </div>


                </div>

              </div>

            </ng-template>
          </li>

          <li title="Fin de contrat" [ngbNavItem]="1" id="tab-1">
            <a ngbNavLink>Fin de contrat</a>
            <ng-template ngbNavContent>

              <div class="bd-example">

                <div class="row">
                  <div class="col-md">

                    <div class="form-group row">
                      <label class="col-md-6 col-form-label">Après la date ci-dessous, appliquer les tarifs ci-contre</label>
                    </div>
                    <div class="form-group row">
                      <label for="changeRateDate" class="col-md-3 col-form-label">Date fin contrat</label>
                      <div class="col-2">
                        <div class="input-group">
                        <input type="text" class="col-md-2 form-control" id="changeRateDate" name="changeRateDate"  placeholder="jj/mm/aaaa"
                               [ngModel]="rightholder.changeRateDate" (ngModelChange)="rightholder.changeRateDate = $event" ngbDatepicker #c="ngbDatepicker" [disabled]="lectureSeule">
                          <button class="btn btn-outline-secondary bi bi-calendar3" (click)="c.toggle()" type="button"></button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md">

                    <div class="form-group row">
                      <label for="futurIdCompanyRate" class="col-md-3 col-form-label">Société</label>
                      <div class="col-2">
                        <input type="text" class="form-control" id="futurIdCompanyRate" [(ngModel)]="rightholder.futurIdCompanyRate" name="futurIdCompanyRate" [disabled]="lectureSeule">
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="futureRate" class="col-md-3 col-form-label">Tarif</label>
                      <div class="col-2">
                        <input type="text" class="form-control" id="futureRate" [(ngModel)]="rightholder.futureRate" name="futureRate" [disabled]="lectureSeule">
                      </div>

                    </div>

                  </div>


                </div>

                <div class="row">&nbsp;</div>




              </div>

            </ng-template>
          </li>

          <li title="Cartes" [ngbNavItem]="2" id="tab-2">
            <a ngbNavLink>Cartes</a>
            <ng-template ngbNavContent>
              <div class="row">&nbsp;</div>
              <div class="row">
                <div class="col">
                  <div>
                    <button type="button" (click)="openPopupCarte()" class="btn btn-success mb-1" [hidden]="lectureSeule">Ajouter une carte</button>
                  </div>
                  <table class="table table-hover table-striped table-bordered" style="width: 100%">
                    <thead>
                    <tr>
                      <th>&nbsp;</th>
                      <th>Techno</th>
                      <th data-priority="1">Date création</th>
                      <th>Date distribution</th>
                      <th>id CNOUS</th>
                      <th>uid</th>
                      <th>origine</th>
                      <th>actif</th>
                      <th width="100px" *ngIf="droitOpposition">action</th>
                    </tr>
                    </thead>
                    <tr *ngFor="let c of cards">
                      <th><button type="button" (click)="sendCardToIzly(c)" class="btn" [hidden]="lectureSeule">
                        <img src="assets/share.svg" style="width: 1.2rem; height: 1rem; cursor: pointer;"/>
                      </button></th>
                      <td>{{ smartCardTechnology(c.appl, c.pixNn, c.pixSs) }}</td>
                      <td data-priority="1">{{ c.zdcCreationDate | dateFormat: ["YYYY-MM-DD","DD/MM/YYYY"]  }}</td>
                      <td>{{ c.deliveryDate | dateFormat: ["YYYY-MM-DD","DD/MM/YYYY"]  }}</td>
                      <td>{{ c.idZdc }}</td>
                      <td>{{ c.uid }}</td>
                      <td>{{ c.origin }}</td>
                      <td>{{ c.cancelDate == null ? 'oui' : 'non' }}</td>
                      <th *ngIf="c.cancelDate == null && droitOpposition"><caption><button type="button" (click)="gereOpposition(c)" class="btn btn-danger" [hidden]="lectureSeule">Mettre&nbsp;en&nbsp;opposition</button></caption></th>
                      <th *ngIf="c.cancelDate != null && droitOpposition"><caption><button type="button" (click)="gereOpposition(c)" class="btn btn-success" [hidden]="lectureSeule">lever&nbsp;opposition</button></caption></th>
                    </tr>
                  </table>
                  <div class="d-flex justify-content-between p-2">
                    <ngb-pagination [collectionSize]="cardsSize" [(page)]="pageC" [pageSize]="pageCSize" (pageChange)="refreshCard()"></ngb-pagination>
                  </div>
                </div>
              </div>

            </ng-template>
          </li>

          <li title="Adresse" [ngbNavItem]="3" id="tab-3">
            <a ngbNavLink>Adresse</a>
            <ng-template ngbNavContent>
              <div class="row">&nbsp;</div>
              <div class="row">
                <div class="col-md">
                  <div class="form-group row">
                    <label for="address1" class="col-md-3 col-form-label">Adresse&nbsp;1</label>
                    <div class="col-7">
                      <input type="text" class="form-control" id="address1" [(ngModel)]="rightholder.address1" name="address1" [disabled]="lectureSeule">
                    </div>
                  </div>
                </div>
                <div class="col-md">
                  <div class="form-group row">
                    <label for="zipCode" class="col-md-3 col-form-label">Code&nbsp;postal</label>
                    <div class="col-7">
                      <input type="text" class="form-control" id="zipCode" [(ngModel)]="rightholder.zipCode" name="zipCode" [disabled]="lectureSeule">
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
              <div class="col-md">
                <div class="form-group row">
                  <label for="address2" class="col-md-3 col-form-label">Adresse&nbsp;2</label>
                  <div class="col-7">
                    <input type="text" class="form-control" id="address2" [(ngModel)]="rightholder.address2" name="address2" [disabled]="lectureSeule">
                  </div>
                </div>
              </div>
              <div class="col-md">
                <div class="form-group row">
                  <label for="city" class="col-md-3 col-form-label">Ville</label>
                  <div class="col-7">
                    <input type="text" class="form-control" id="city" [(ngModel)]="rightholder.city" name="city" [disabled]="lectureSeule">
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md">
                <div class="form-group row">
                  <label for="address3" class="col-md-3 col-form-label">Adresse&nbsp;3</label>
                  <div class="col-7">
                    <input type="text" class="form-control" id="address3" [(ngModel)]="rightholder.address3" name="address3" [disabled]="lectureSeule">
                  </div>
                </div>
              </div>
              <div class="col-md">
                <div class="form-group row">
                  <label for="country" class="col-md-3 col-form-label">Pays</label>
                  <div class="col-7">
                    <input type="text" class="form-control" id="country" [(ngModel)]="rightholder.country" name="country" [disabled]="lectureSeule">
                  </div>
                </div>
              </div>
            </div>
            </ng-template>
          </li>

          <li title="Données supplémentaires" [ngbNavItem]="4" id="tab-4">
            <a ngbNavLink>Données supplémentaires</a>
            <ng-template ngbNavContent>
              <div class="row">&nbsp;</div>
              <div class="row">
                <div class="col-md">
                  <div class="form-group row">
                    <label for="other1" class="col-md-3 col-form-label">Perso&nbsp;1</label>
                    <div class="col-7">
                    <input type="text" class="form-control" id="other1" [(ngModel)]="rightholder.other1" name="other1" [disabled]="lectureSeule">
                    </div>
                  </div>
                </div>
                <div class="col-md">
                  <div class="form-group row">
                    <label for="other6" class="col-md-3 col-form-label">Perso&nbsp;6</label>
                    <div class="col-7">
                      <input type="text" class="form-control" id="other6" [(ngModel)]="rightholder.other6" name="other6" [disabled]="lectureSeule">
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md">
                  <div class="form-group row">
                    <label for="other2" class="col-md-3 col-form-label">Perso&nbsp;2</label>
                    <div class="col-7">
                      <input type="text" class="form-control" id="other2" [(ngModel)]="rightholder.other2" name="other2" [disabled]="lectureSeule">
                    </div>
                  </div>
                </div>
                <div class="col-md">
                  <div class="form-group row">
                    <label for="other7" class="col-md-3 col-form-label">Perso&nbsp;7</label>
                    <div class="col-7">
                     <input type="text" class="form-control" id="other7" [(ngModel)]="rightholder.other7" name="other7" [disabled]="lectureSeule">
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md">
                  <div class="form-group row">
                    <label for="other3" class="col-md-3 col-form-label">Perso&nbsp;3</label>
                    <div class="col-7">
                      <input type="text" class="form-control" id="other3" [(ngModel)]="rightholder.other3" name="other3" [disabled]="lectureSeule">
                    </div>
                  </div>
                </div>
                <div class="col-md">
                  <div class="form-group row">
                    <label for="other8" class="col-md-3 col-form-label">Perso&nbsp;8</label>
                    <div class="col-7">
                      <input type="text" class="form-control" id="other8" [(ngModel)]="rightholder.other8" name="other8" [disabled]="lectureSeule">
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md">
                  <div class="form-group row">
                    <label for="other4" class="col-md-3 col-form-label">Perso&nbsp;4</label>
                    <div class="col-7">
                      <input type="text" class="form-control" id="other4" [(ngModel)]="rightholder.other4" name="other4" [disabled]="lectureSeule">
                    </div>
                  </div>
                </div>
                <div class="col-md">
                  <div class="form-group row">
                    <label for="other9" class="col-md-3 col-form-label">Perso&nbsp;9</label>
                    <div class="col-7">
                      <input type="text" disabled class="col-md-6 form-control" id="other9" [(ngModel)]="rightholder.other9" name="other9" [disabled]="lectureSeule">
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md">
                  <div class="form-group row">
                    <label for="other5" class="col-md-3 col-form-label">Perso&nbsp;5</label>
                    <div class="col-7">
                      <input type="text" class="col-md-6 form-control" id="other5" [(ngModel)]="rightholder.other5" name="other5" [disabled]="lectureSeule">
                    </div>
                  </div>
                </div>
                <div class="col-md">
                  <div class="form-group row">
                    <label for="other10" class="col-md-3 col-form-label">Perso&nbsp;10</label>
                    <div class="col-7">
                      <input type="text" disabled class="col-md-6 form-control" id="other10" [(ngModel)]="rightholder.other10" name="other10" [disabled]="lectureSeule">
                    </div>
                  </div>
                </div>
              </div>


              <div class="row">
              <div class="col-md">
                <div class="form-group row">
                  <label for="internalId" class="col-md-3 col-form-label">Numero&nbsp;Interne</label>
                  <div class="col-7">
                    <input type="text" class="form-control" id="internalId" name="internalId" [(ngModel)]="rightholder.internalId" [disabled]="lectureSeule">
                  </div>
                </div>
              </div>
                <div class="col-md">
                </div>
              </div>

            </ng-template>
          </li>
          <li title="Historique" [ngbNavItem]="6" id="tab-6">
            <a ngbNavLink>Historique</a>
            <ng-template ngbNavContent>
              <div class="row">&nbsp;</div>
              <div class="row">
                <div class="col-md">
                  <div class="form-group row">
                    <span>Création le {{rightholder.createdAt | date: 'dd/MM/yyyy'}}</span>
                  </div>
                </div>
                <div class="col-md">
                  <div class="form-group row">
                    <span>Dernière modification le {{rightholder.updatedAt | date: 'dd/MM/yyyy'}}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="table-responsive">

                    <table class="table table-hover table-striped table-bordered" style="width: 100%">
                      <thead>
                      <tr style="font-size: x-small">
                        <th><p class="rotate-90">Date Changement</p></th>
                        <th><p class="rotate-90">Société précédente</p></th>
                        <th><p class="rotate-90">Société</p></th>
                        <th><p class="rotate-90">Tarif précédent</p></th>
                        <th><p class="rotate-90">Tarif</p></th>
                        <th><p class="rotate-90">Nom précédente</p></th>
                        <th><p class="rotate-90">Nom</p></th>
                        <th><p class="rotate-90">Prénom précédente</p></th>
                        <th><p class="rotate-90">Prénom</p></th>
                        <th><p class="rotate-90">INE précédente</p></th>
                        <th><p class="rotate-90">INE</p></th>
                        <th><p class="rotate-90">INES précédente</p></th>
                        <th><p class="rotate-90">INES</p></th>
                        <th><p class="rotate-90">Rne Etab. précédente</p></th>
                        <th><p class="rotate-90">Rne Etab.</p></th>
                        <th><p class="rotate-90">Rne Comp. précédente</p></th>
                        <th><p class="rotate-90">Rne Comp.</p></th>
                        <th><p class="rotate-90">Courriel précédente</p></th>
                        <th><p class="rotate-90">Courriel</p></th>
                        <th><p class="rotate-90">Valid. précédente</p></th>
                        <th><p class="rotate-90">Valid.</p></th>
                        <th><p class="rotate-90">Crous précédente</p></th>
                        <th><p class="rotate-90">Crous</p></th>
                        <th><p class="rotate-90">Id. précédente</p></th>
                        <th><p class="rotate-90">Id.</p></th>
                        <th><p class="rotate-90">Etab. précédente</p></th>
                        <th><p class="rotate-90">Etab.</p></th>
                        <th><p class="rotate-90">Modifié par</p></th>
                      </tr>
                      </thead>
                      <tr *ngFor='let h of paginateHistoric' style="font-size: small">
                        <td> {{ h.dateHistorique | dateHeureFormat }}</td>
                        <td> {{ h.codeSocietePrecedent }}</td>
                        <td> {{ h.codeSociete }}</td>
                        <td> {{ h.codeTarifPrecedent }}</td>
                        <td> {{ h.codeTarif }}</td>
                        <td> {{ h.nomPrecedent }}</td>
                        <td> {{ h.nom }}</td>
                        <td> {{ h.prenomPrecedent }}</td>
                        <td> {{ h.prenom }}</td>
                        <td> {{ h.inePrecedent }}</td>
                        <td> {{ h.ine}}</td>
                        <td> {{ h.inesPrecedent }}</td>
                        <td> {{ h.ines}}</td>
                        <td> {{ h.rneEtablissementPrecedent}}</td>
                        <td> {{ h.rneEtablissement}}</td>
                        <td> {{ h.rneComposantePrecedent }}</td>
                        <td> {{ h.rneComposante }}</td>
                        <td> {{ h.courrielInstitutionnelPrecedent }}</td>
                        <td> {{ h.courrielInstitutionnel }}</td>
                        <td> {{ h.dateFinValiditePrecedent  | dateFormat: ["YYYY-MM-DD","DD/MM/YYYY"] }}</td>
                        <td> {{ h.dateFinValidite  | dateFormat: ["YYYY-MM-DD","DD/MM/YYYY"] }}</td>
                        <td> {{ h.numeroCrousPrecedent }}</td>
                        <td> {{ h.numeroCrous }}</td>
                        <td> {{ h.identifiantClientPrecedent }}</td>
                        <td> {{ h.identifiantClient }}</td>
                        <td> {{ h.etablissementClientPrecedent?.nom }}</td>
                        <td> {{ h.etablissementClient?.nom }}</td>
                        <td> {{ h.origineId }}</td>
                      </tr>
                    </table>
                    <div class="d-flex justify-content-between p-2">
                      <ngb-pagination [collectionSize]="historicSize" [(page)]="pageH" [pageSize]="pageHSize" (pageChange)="refreshHistory()"></ngb-pagination>
                      <pre>page: {{pageH}}</pre>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <p style="font-size: small">Legende : <b>I</b>-xxx : mise à jour par synchro avec données izly | <b>B</b>-xxx : mise à jour depuis infocartecrous | <b>X</b>-xxx : mise à jour depuis excel |
                  Aucun préfixe API (formulaire Before ou SGC) | xxx : utilisateur beforeizly (si xxx est un numérique) ou api (si xxx est une chaine)</p>

              </div>

            </ng-template>
          </li>

          <li title="Données Izly" [ngbNavItem]="7" id="tab-7">
            <a ngbNavLink>Données Izly</a>
            <ng-template ngbNavContent *ngIf="rightholder.izlyParts?.idIzly == undefined || rightholder.izlyParts?.idIzly == null">
              <div class="row">&nbsp;</div>
              <div class="row"> <div class="col-md">Il n'a pas été possible de récupérer les données izly : compte inexistant, cloturé ou izly injoignable</div></div>
              <div class="row" *ngIf="rightholder.izlyParts?.gccErrorMessage != undefined && rightholder.izlyParts?.gccErrorMessage !=null">
                <div class="col-md" >
                  <div class="row">
                    <label for="gccError" class="col-md-3 col-form-label">Erreur Izly</label>
                    <div class="col-7">
                      <input type="text" readonly class="col-md-6 form-control-plaintext" id="gccError" [value]="rightholder.izlyParts?.gccErrorMessage" name="idCrousIzly" [disabled]="lectureSeule">
                    </div>
                  </div>
                </div>
              </div>

                  <div class="row">
                    <div class="col-6">
                    <button type="button" (click)="deleteADandCard()"
                            class="btn btn-danger"
                            [hidden]="!droitAdminNational">Supprimer le compte et ajouter le(s) carte(s) aux cartes clôturées</button>
                    </div>
                    <div class="col-6">
                    <button type="button" (click)="createRightHolder()"
                            class="btn btn-danger"
                            [hidden]="!droitAdminNational">Forcer la recréation</button>
                    </div>
                  </div>

            </ng-template>
            <ng-template ngbNavContent *ngIf="rightholder.izlyParts?.idIzly != undefined && rightholder.izlyParts?.idIzly != undefined" id="7">
              <div class="row">&nbsp;</div>
               <div class="row">
                <div class="col-md">
                  <div class="row">
                    <label for="accountStatus" class="col-md-3 col-form-label">Statut&nbsp;Compte</label>
                    <div class="col-7">
                      <input type="text" readonly class="form-control-plaintext" id="accountStatus" [value]="rightholder.izlyParts?.statutCompte | accountStatus" name="accountStatus" [disabled]="lectureSeule">
                    </div>
                  </div>
                </div>
                <div class="col-md">
                  <div class="row">
                    <label for="freezeStatus" class="col-md-3 col-form-label">Statut&nbsp;Blocage</label>
                    <div class="col-7">
                      <input type="text" readonly class="form-control-plaintext" id="freezeStatus" [value]="rightholder.izlyParts?.statutBlocage | blockingStatus" name="freezeStatus" [disabled]="lectureSeule">
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md">
                  <div class="row">
                    <label for="idIzly" class="col-md-3 col-form-label">Id Izly</label>
                    <div class="col-7">
                      <input type="text" readonly class="form-control-plaintext" id="idIzly" [value]="rightholder.izlyParts?.idIzly" name="idIzly" [disabled]="lectureSeule">
                    </div>
                  </div>
                </div>
                <div class="col-md">
                  <div class="row">
                    <label for="balance" class="col-md-3 col-form-label">Solde</label>
                    <div class="col-7">
                      <input type="text" readonly class="form-control-plaintext" id="balance" [value]="rightholder.izlyParts?.solde/100" name="balance" [disabled]="lectureSeule">
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md">
                  <div class="row">
                    <label for="idCrousIzly" class="col-md-3 col-form-label">Crous Izly</label>
                    <div class="col-7">
                      <input type="text" readonly class="form-control-plaintext" id="idCrousIzly" [value]="rightholder.izlyParts?.identifiantCrous" name="idCrousIzly" [disabled]="lectureSeule">
                    </div>
                  </div>
                </div>
                <div class="col-md">
                  <div class="row">
                    <label for="numAffectation" class="col-md-3 col-form-label">Affectation</label>
                    <div class="col-7">
                    <input type="text" readonly class="form-control-plaintext" id="numAffectation" [value]="rightholder.izlyParts?.numeroAffectation" name="numAffectation" [disabled]="lectureSeule">
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md">
                  <div class="row">
                    <label for="societeIzly" class="col-md-3 col-form-label">Societe</label>
                    <div class="col-7">
                    <input type="text" readonly class="form-control-plaintext" id="societeIzly" [value]="rightholder.izlyParts?.societeIzly" name="idCrousIzly" [disabled]="lectureSeule">
                    </div>
                  </div>
                </div>
                <div class="col-md">
                  <div class="row">
                    <label for="tarifIzly" class="col-md-3 col-form-label">Tarif</label>
                    <div class="col-7">
                    <input type="text" readonly class="form-control-plaintext" id="tarifIzly" [value]="rightholder.izlyParts?.tarifIzly" name="numAffectation" [disabled]="lectureSeule">
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md">
                  <div class="row">
                    <label for="freezeStatus" class="col-md-3 col-form-label">Controle repas Midi</label>
                    <div class="col-7">
                    <input type="text" readonly class="form-control-plaintext" id="dateDernierServiceMidi" [value]="rightholder.izlyParts?.dateDernierServiceMidi | dateFormat: ['YYYY-MM-DD','DD/MM/YYYY']" name="dateDernierServiceMidi" [disabled]="lectureSeule">
                    </div>
                  </div>
                </div>
                <div class="col-md">
                  <div class="row">
                    <label for="freezeStatus" class="col-md-3 col-form-label">Controle repas Soir</label>
                    <div class="col-7">
                    <input type="text" readonly class="form-control-plaintext" id="dateDernierServiceSoir" [value]="rightholder.izlyParts?.dateDernierServiceSoir | dateFormat: ['YYYY-MM-DD','DD/MM/YYYY']"name="dateDernierServiceSoir" [disabled]="lectureSeule">
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="rightholder.izlyParts?.gccErrorMessage != undefined && rightholder.izlyParts?.gccErrorMessage !=null">
                <div class="col-md" >
                  <div class="row">
                    <label for="gccError" class="col-md-3 col-form-label">Erreur Izly</label>
                    <div class="col-7">
                    <input type="text" readonly class="col-md-6 form-control-plaintext" id="gccError" [value]="rightholder.izlyParts?.gccErrorMessage" name="idCrousIzly" [disabled]="lectureSeule">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="rightholder.izlyParts?.idIzly !== undefined">
                <div class="col-md-6" >
                      <a href="https://backoffice{{getPostfixPreprod()}}.izly.fr/IzlyUsers/Users/Details/{{(rightholder.izlyParts?.idIzly-6000000000)}}"
                         class="btn btn-info" target="_blank">Voir sur le Back Office Izly</a>
                </div>
                <div class="col-md-4">
                  <button type="button" (click)="renvoiMailActivation()" class="btn btn-warning" *ngIf="rightholder.izlyParts?.statutCompte == 1">Renvoyez le mail d'activation</button>
                </div>
              </div>
              <div class="row" *ngIf="rightholder.izlyParts?.statutCompte == 3 || rightholder.izlyParts?.statutCompte == 4">
                <div class="col-md">
                  <div class="row">
                    <button type="button" (click)="deleteADandCard()"
                            class="offset-6 btn btn-danger"
                            [hidden]="!droitAdminNational">Supprimer le compte et ajouter le(s) carte(s) aux cartes clôturées</button>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>


          <li title="Compteurs" [ngbNavItem]="8" id="tab-8">
            <a ngbNavLink>Compteurs</a>
            <ng-template ngbNavContent>
              <div class="row">&nbsp;</div>
              <div class="row">
                <div class="col">
                  <table class="table table-hover table-striped table-bordered responsive" style="width: 100%">
                    <thead>
                    <tr>
                      <th data-priority="1">Compteur</th>
                      <th>Solde</th>
                      <th>DateFin</th>
                    </tr>
                    </thead>
                    <tr *ngFor='let c of rightholder.izlyParts?.lCompteur'>
                      <td>{{ c.idCompteur | counter }}</td>
                      <td>{{ (c.idCompteur == 3 ? c.valeur /100 : c.valeur)  | number }}</td>
                      <td>{{ (c && c.ldate)?(c.ldate[0]?.date):'' }}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </ng-template>
          </li>


          <li title="Données MSE" [ngbNavItem]="9" id="tab-9">
            <a ngbNavLink>Données MSE</a>
            <ng-template ngbNavContent>

              <div class="row">&nbsp;</div>
              <div class="row">
                <div class="col-md">
                  <div class="form-group row">
                    <label for="freezeStatus" class="col-md-3 col-form-label">Crous CVEC</label>
                    <div class="col-7">
                    <input type="text" readonly class="form-control-plaintext" id="CCVEC" [value]="rightholder.crousParts?.crousCvec" name="CCVEC" [disabled]="lectureSeule">
                    </div>
                  </div>
                </div>
                <div class="col-md">
                  <div class="form-group row">
                    <label for="freezeStatus" class="col-md-3 col-form-label">numero CVEC</label>
                    <div class="col-7">
                    <input type="text" readonly class="form-control-plaintext" id="CVEC" [value]="rightholder.crousParts?.numCvec" name="CVEC" [disabled]="lectureSeule">
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md">
                  <div class="form-group row">
                    <label for="freezeStatus" class="col-md-3 col-form-label">idPve</label>
                    <div class="col-7">
                    <input type="text" readonly class="form-control-plaintext" id="idPve" [value]="rightholder.crousParts?.idPve" name="idPve" [disabled]="lectureSeule">
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>

          <li title="RNE Prioritaire" [ngbNavItem]="10" id="tab-10">
            <a ngbNavLink>RNE Prioritaire</a>
            <ng-template ngbNavContent>

              <div class="row">&nbsp;</div>
              <div class="row">
                <div class="col-md">
                  <div class="form-group row">
                    <label for="rnePrioritaire" class="col-md-3 col-form-label">Rne Prioritaire</label>
                    <div class="col-7">
                    <select class="col-md-6 form-select" id="rnePrioritaire" name="rnePrioritaire" [(ngModel)]="rightholder.rnePrioritaire"
                            [disabled]="lectureSeule">
                      <option [ngValue]="null">Aucun RNE prioritaire</option>
                      <option  *ngFor="let c of historiqueRneList" [ngValue]="c.rneEtablissement">{{c.rneEtablissement}} - {{c.nomUsuel}}
                        <span *ngIf="c.nomEtablissement != null">({{c.nomEtablissement}})</span></option>
                    </select>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>

          <li title="Transfert Carte" [ngbNavItem]="11" id="tab-11" *ngIf="droitTransfertCarte">
            <a ngbNavLink>Transfert Carte</a>
            <ng-template ngbNavContent>
              <div class="bd-example">

                <div class="row">
                  <div class="col-md">

                    <div class="form-group row">
                      <label for="indice" class="col-form-label" style="padding-right: 10px">Identifiant Client</label>
                      <div class="col-4">
                      <input type="text" class="form-control" id="identifiantClientCible" [(ngModel)]="ptcIdentifiantClientSource" name="identifiantClientCible" [disabled]="lectureSeule || ptcRecuperCarteCloture">
                      </div>
                      <div class="col-md-2">
                        <button type="button" (click)="ptcRechercheCompteCible()" class="btn btn-secondary" placement="right"[disabled]="lectureSeule || ptcRecuperCarteCloture">Rechercher</button>
                      </div>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="" id="ptcRecuperCarteCloture" [(ngModel)]="ptcRecuperCarteCloture"
                             name="ptcRecuperCarteCloture" [disabled]="lectureSeule || ptcRightholder">
                      <label class="form-check-label" for="ptcRecuperCarteCloture">
                        Récupération des cartes liées au compte cloturé avec le même identifiant.
                      </label>
                    </div>
                  </div>
                  <div class="col-md">

                    <div class="form-group row" >
                      <label for="ptcnom" class="col-md-3 col-form-label" *ngIf="ptcRightholder">Nom</label>
                      <div class="col-7">
                      <input type="text" readonly class="form-control-plaintext" id="ptcnom" [value]="ptcRightholder?.lastName" name="ptcnom" [disabled]="lectureSeule">
                      </div>
                    </div>
                    <div class="form-group row" >
                      <label for="ptcprenom" class="col-md-3 col-form-label" *ngIf="ptcRightholder">Prénom</label>
                      <div class="col-7">
                      <input type="text" readonly class="form-control-plaintext" id="ptcprenom" [value]="ptcRightholder?.firstName" name="ptcprenom" [disabled]="lectureSeule">
                      </div>
                    </div>
                    <div class="form-group row" >
                      <label for="ptcstatut" class="col-md-3 col-form-label" *ngIf="ptcRightholder">Statut</label>
                      <div class="col-7">
                      <input type="text" readonly class="form-control-plaintext" id="ptcstatut" [value]="ptcRightholder?.izlyParts.statutCompte | accountStatus" name="ptcstatut" [disabled]="lectureSeule">
                      </div>
                    </div>
                    <div class="form-group row" >
                      <label for="ptcsolde" class="col-md-3 col-form-label" *ngIf="ptcRightholder">Solde</label>
                      <div class="col-7">
                      <input type="text" readonly class="form-control-plaintext" id="ptcsolde" [value]="ptcRightholder?.izlyParts.solde/100" name="ptcsolde" [disabled]="lectureSeule" *ngIf="ptcRightholder">
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="col-md">
                        <table *ngIf="ptcCardList && ptcCardList.length > 0">
                          <thead>
                          <tr>
                            <th style="width: 200px">id Cnous </th>
                            <th>uid</th>
                          <tr>
                          </thead>
                          <tbody>
                          <tr *ngFor="let item of ptcCardList;">
                            <td>{{item.idZdc}}</td>
                            <td>{{item.uid}}</td>
                          <tr>
                          </tbody>
                        </table>
                        <span *ngIf="ptcCardList && ptcCardList.length == 0" style="color: #d9534f">Aucune carte</span>

                      </div>
                    </div>
                    <div class="row" *ngIf="ptcCardList && ptcCardList.length > 0 || ptcRecuperCarteCloture">
                      <div class="col-md-8">
                        <button type="button" (click)="open(content)" class="btn btn-danger" placement="right"[disabled]="lectureSeule">Transférer les cartes</button>
                      </div>
                    </div>

                  </div>
                </div>




              </div>
            </ng-template>
          </li>



        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>



      </div>

    </div>
    <div class="row">&nbsp;</div>
    <div class="row">
      <div class="col">
        <button type="submit" class="offset-9 btn btn-primary" [disabled]="!myForm.valid" [hidden]="lectureSeule || hideBottomButton">Enregistrer</button>
      </div>
    </div>
    <div class="row">&nbsp;</div>

  </form>
  </div>
</div>

<ng-template #content>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Transfert de Cartes</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="ptcCardList && ptcCardList.length > 0">
    <p>Etes vous sur de vouloir transférer {{ getCarteMessage() }}
      du compte <strong>{{ptcRightholder.izlyParts.statutCompte | accountStatus | lowercase }}</strong> de <span class="text-primary">{{ptcRightholder.firstName}} {{ptcRightholder.lastName}}</span>
      vers le compte <strong>{{rightholder.izlyParts.statutCompte | accountStatus | lowercase }}</strong> de <span class="text-primary">{{rightholder.firstName}} {{rightholder.lastName}}</span>
    </p>
    <span class="text-danger">Cette opération ne peut pas être annulée !</span>
  </div>

  <div class="modal-body" *ngIf="ptcRecuperCarteCloture">

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">Annuler</button>
    <button type="button" class="btn btn-danger" (click)="modal.close('Ok click')">Ok</button>
  </div>
</ng-template>
