import {Component, OnInit} from '@angular/core';
import {Rne} from "../../models/rne";
import {RneService} from "../../services/rne.service";
import {ToastTool} from "../../tools/toast.tool";
import {data} from "jquery";

@Component({
  selector: 'app-liste-rne',
  templateUrl: './liste-rne.component.html',
  styleUrls: ['./liste-rne.component.css']
})
export class ListeRneComponent {
}
