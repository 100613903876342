import {Crous} from "./crous";
import {Droit} from "./droit";
import {EtablissementClient} from "./etablissement-client";

export class UtilisateurB4 {
  idUtilisateur: number;
  nom: string;
  prenom: string;
  courriel: string;
  identifiant: string;
  motDePasse: string;
  dateConnexion: Date;
  dateInsertion: Date;
  dateLastConnexion: Date;
  droitCrousList: Crous[];
  crousDelegationList: Crous[];
  droitList: Droit[];
  droitEtablissementClientList: EtablissementClient[];
  droitListInString: string;
  dateFinValidite: Date;
  creePar: number;
  visibleParAdminNational: boolean;
  crous: Crous;
  oauthId: string
  oauthSiret: string
  oauthJob: string
  oauthEtab: string
}
