import { Injectable } from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {UntypedFormGroup} from '@angular/forms';

@Injectable()
export class SearchAyantDroitService {

  private formSubmited = new Subject<UntypedFormGroup>();
  formSubmited$ = this.formSubmited.asObservable();
  subscription: Subscription;

  constructor() {  }

  publishData(form: UntypedFormGroup) {
    this.formSubmited.next(form);
  }

}
