import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'accountStatus'
})
export class AccountStatusPipe implements PipeTransform {


  transform(value: any, args?: any): any {

    switch (value) {

      case 1:
          return 'Non confirmé';
      case 2:
        return 'Actif';
      case 3:
        return 'Clôturé';
      case 4:
        return 'Clôture en cours';
      default:
        return value === undefined ? '' : value;

    }
  }

}
