import {EtablissementClient} from "./etablissement-client";

export class Rne {
  idRne: number;
  uairne: string;
  nomUsuel: string;
  libelleCourt: string;
  localite: string;
  etablissementClient: EtablissementClient;
}
