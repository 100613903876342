import {Component, EventEmitter, Input, Output, QueryList, ViewChildren} from '@angular/core';
import {Rne} from "../../../models/rne";
import {map, Observable, startWith} from "rxjs";
import {FormControl} from "@angular/forms";
import {RneService} from "../../../services/rne.service";
import {NgbModal, NgbModalConfig} from "@ng-bootstrap/ng-bootstrap";
import {ToastTool} from "../../../tools/toast.tool";
import {DialogFormRneComponent} from "../../../rne/dialog-ajout-rne/dialog-form-rne.component";
import {EtablissementClient} from "../../../models/etablissement-client";
import {EtablissementService} from "../../../services/etablissement.service";
import {DialogFormEtablissementComponent} from "../dialog-form-etablissement/dialog-form-etablissement.component";
import {NgbdSortableHeader, SortEvent} from "../../../tools/ngbd-sortable-header.directive";

@Component({
  selector: 'app-tableau-etablissements',
  templateUrl: './tableau-etablissements.component.html',
  styleUrls: ['./tableau-etablissements.component.css']
})
export class TableauEtablissementsComponent {
  @Input() listeEtablissements: EtablissementClient[] = [];
  @Output() etablissementModifie = new EventEmitter<EtablissementClient>();

  listeEtablissements$ : Observable<EtablissementClient[]>;
  filter = new FormControl('', {nonNullable: true});

  pageSize = 20;
  page = 5;

  loading = false;

  constructor(private etablissementService: EtablissementService,
              private modalService: NgbModal,
              private config: NgbModalConfig) {
    this.loadListeEtablissements();
  }

  ngOnInit(): void {
  }

  search(text: string): EtablissementClient[] {
    return this.listeEtablissements.filter((etablissement) => {
      const term = text.toLowerCase();
      return (
        etablissement.idEtablissementClient.toString().includes(term) ||
        etablissement.nom.toLowerCase().includes(term) ||
        etablissement.crous.libelle?.toLowerCase().includes(term) ||
        etablissement.adresse?.toLowerCase().includes(term)
      );
    });
  }

  loadListeEtablissements() {
    this.loading = true;
    this.etablissementService.getListeEtablissementClient().subscribe({
      next: (data) => {
        this.listeEtablissements = data;

        this.filter.reset();
        this.listeEtablissements$ = this.filter.valueChanges.pipe(
          startWith(''),
          map((text) => this.search(text)),
        );
        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
        for (let error of err.error) {
          let description = error.error_description;
          ToastTool.danger(description);
        }
      }
    })
  }

  modifierEtablissement(etablissement: EtablissementClient): void {
    const modalRef = this.modalService.open(DialogFormEtablissementComponent);
    modalRef.componentInstance.etablissementSelectionne = etablissement;
    modalRef.componentInstance.etablissementModifie.subscribe(() => {
      this.loadListeEtablissements();
    })
  }

  supprimerEtablissement(etablissement: EtablissementClient): void {
    if(confirm("Etes-vous sûr de vouloir supprimer cet établissement")) {
      this.etablissementService.deleteEtablissementClient(etablissement).subscribe({
        next: () => {
          ToastTool.success("Etablissement supprimé");
          this.loadListeEtablissements();
        },
        error: (err) => {
          for (let error of err.error) {
            let description = error.error_description;
            ToastTool.danger(description);
          }
        }
      });
    }
  }

  nouvelEtablissement(): void {
    const modalRef = this.modalService.open(DialogFormEtablissementComponent);
    modalRef.componentInstance.etablissementSelectionne = new EtablissementClient();
    modalRef.componentInstance.etablissementModifie.subscribe(() => {
      this.loadListeEtablissements();
    })
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    for (const header of this.headers) {
      if (header.sortable !== column) {
        header.direction = '';
      }
    }

    if (direction === '' || column === '') {
      this.loadListeEtablissements();
    } else {
      this.listeEtablissements$ = this.listeEtablissements$.pipe(map(arr => arr.sort((a, b) => {
        console.log(column)
        let res;
        if (column == 'crous.libelle') {
          res = NgbdSortableHeader.compare(a.crous ? a.crous.libelle : '', b.crous ? b.crous.libelle : '');
        } else {
          res = NgbdSortableHeader.compare(a[column], b[column]);
        }
        return direction === 'asc' ? res : -res;
      })));
    }
  }
}
