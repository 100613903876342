import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Rne} from '../models/rne';
import {Observable} from "rxjs";
import {RnePost} from "../models/rne-post";
import {RnePut} from "../models/rne-put";
import {HttpService} from "./http.service";



@Injectable()
export class RneService {


  constructor(private http: HttpClient,
              private httpService: HttpService) {
  }

  getRneFromEtablissement(idEtablissement: number, fields?: string) {
    const params = this.httpService.buildParams({fields})
    return this.http.get<Array<Rne>>(environment.apiB4Url + '/v1/etablissements/' + idEtablissement + '/rnes', { params, withCredentials: true });
  }

  getListeRne(): Observable<Rne[]> {
    return this.http.get<Rne[]>(environment.apiB4Url + '/v1/rne', { withCredentials: true});
  }

  getRne(rne: Rne): Observable<Rne> {
    return this.http.get<Rne>(environment.apiB4Url + '/v1/rne/' + rne.uairne, {withCredentials: true})
  }

  postRne(rne: Rne): Observable<Rne> {
    return this.http.post<Rne>(environment.apiB4Url + '/v1/rne', new RnePost(rne), {withCredentials: true});
  }

  putRne(rne: Rne): Observable<void> {
    return this.http.put<void>(environment.apiB4Url + '/v1/rne/' + rne.idRne, new RnePut(rne), {withCredentials: true})
  }

  deleteRne(rne: Rne): Observable<void> {
    return this.http.delete<void>(environment.apiB4Url + '/v1/rne/' + rne.idRne, {withCredentials: true});
  }

  gerenerUaiRne(): Observable<string> {
    return this.http.get(environment.apiB4Url + '/v1/genereruairneinterne', {withCredentials: true, responseType: "text"})
  }


}
