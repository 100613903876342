import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'counter'
})
export class CounterPipe implements PipeTransform {


  transform(value: any, args?: any): any {

    switch (value) {

      case 1:
          return 'Restauration sociale';
      case 2:
        return 'Fidelité';
      case 3:
        return 'Crédit Izly';
      default:
        return value;

    }
  }

}
