import {LogLevel, PassedInitialConfig} from 'angular-auth-oidc-client';
import {environment} from "../../environments/environment";


export const authConfig: PassedInitialConfig = {
  config: {
            authority: environment.authority,
            redirectUrl: window.location.origin,
            postLogoutRedirectUri: window.location.origin,
            clientId: environment.clientId,
            scope: 'openid profile email', // 'openid profile ' + your scopes
            responseType: 'code',
            silentRenew: false,
            renewTimeBeforeTokenExpiresInSeconds: 10,
            logLevel: LogLevel.Debug,
        }
}
