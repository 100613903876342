import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {EmetteurZdcContrat} from "../../../models/emetteur-zdc-contrat";
import { FormControl} from "@angular/forms";
import {
  NgbModal,
  NgbModalConfig
} from "@ng-bootstrap/ng-bootstrap";
import {ToastTool} from "../../../tools/toast.tool";
import {HttpErrorResponse} from "@angular/common/http";
import {EmetteurZdcContratService} from "../../../services/emetteur-zdc-contrat.service";
import {DatePipe, DecimalPipe} from "@angular/common";
import {map, Observable, of, startWith} from "rxjs";
import {DialogFormContratComponent} from "../dialog-form-contrat/dialog-form-contrat.component";
import {NgbdSortableHeader, SortEvent} from "../../../tools/ngbd-sortable-header.directive";
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-tableau-contrats',
  templateUrl: './tableau-contrats.component.html',
  styleUrls: ['./tableau-contrats.component.css']
})
export class TableauContratsComponent implements OnInit {

  FIELDS: string = "idEmetteurZdcContrat," +
    "emetteurZdc," +
    "etablissementclient.idEtablissementClient," +
    "etablissementclient.nom," +
    "etablissementclient.adresse," +
    "codeEmetteur," +
    "dateDebutContrat," +
    "dateFinContrat," +
    "numeroLimiteInferieure," +
    "numeroLimiteSuperieure," +
    "dateInsertion," +
    "libelle," +
    "crousPrivilegie";

  pageSize = 10;
  page = 5;
  listeContrats: EmetteurZdcContrat[] = [];


  contrats$ : Observable<EmetteurZdcContrat[]>;
  filter = new FormControl('', {nonNullable: true});

  contratSelectionne: EmetteurZdcContrat;


  constructor(private modalService: NgbModal,
              private contratService: EmetteurZdcContratService,
              private datePipe: DatePipe,
              private config: NgbModalConfig,
              private emetteurContratService: EmetteurZdcContratService
  ) {
    this.config.backdrop = 'static';
    this.loadListeContrats();
  }

  search(text: string): EmetteurZdcContrat[] {
  return this.listeContrats.filter((contrat) => {
    const term = text.toLowerCase();
    return (
      contrat.emetteurZdc.raisonSociale.toLowerCase().includes(term) ||
      contrat.libelle?.toLowerCase().includes(term) ||
      contrat.crousPrivilegie?.toLowerCase().includes(term) ||
      contrat.codeEmetteur.toString().includes(term) ||
      contrat.codeEmetteur.toString(16).includes(term) ||
      contrat.numeroLimiteSuperieure.toString().includes(term) ||
      contrat.numeroLimiteInferieure.toString().includes(term) ||
      this.datePipe.transform(contrat.dateDebutContrat, 'dd/MM/YYYY').includes(term)
      );
    });
  }

  loadListeContrats(): void {
    this.emetteurContratService.getListeContrats(this.FIELDS).subscribe({
      next: (data) => {
        this.listeContrats = data;

        this.contrats$ = this.filter.valueChanges.pipe(
          startWith(''),
          map((text) => this.search(text)),
        );
      }
    });
  }

  ngOnInit(): void {
  }


  nouveauContrat() {
    this.contratSelectionne = new EmetteurZdcContrat();
    const modalRef = this.modalService.open(DialogFormContratComponent);
    modalRef.componentInstance.contratSelectionne = this.contratSelectionne;
    modalRef.componentInstance.contratModifie.subscribe(() => {
      this.loadListeContrats();
    })

  }

  actionBoutonContrat(contrat: EmetteurZdcContrat) {
    this.contratSelectionne = contrat;
    const modalRef = this.modalService.open(DialogFormContratComponent);
    modalRef.componentInstance.contratSelectionne = this.contratSelectionne;
    modalRef.componentInstance.contratModifie.subscribe(() => {
      this.loadListeContrats();
    })

  }

  supprimerContrat(contrat: EmetteurZdcContrat): void {
    if(confirm("Etes-vous sur de vouloir supprimer ce contrat ?")) {
      this.contratService.supprimerContrat(contrat).subscribe(
        {
          next: () => {
            ToastTool.success('Contrat supprimé');
            this.loadListeContrats();
          },
          error: (err: HttpErrorResponse) => {
            for (let error of err.error) {
              let description = error.error_description;
              ToastTool.danger(description);
              this.loadListeContrats();
            }
          }
        }
      );
    }
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    for (const header of this.headers) {
      if (header.sortable !== column) {
        header.direction = '';
      }
    }

    if (direction === '' || column === '') {
      this.loadListeContrats();
    } else {
      this.contrats$ = this.contrats$.pipe(map(arr => arr.sort((a, b) => {
        console.log(column)
        let res;
        if (column == 'emetteurZdc.raisonSociale') {
          res = NgbdSortableHeader.compare(a.emetteurZdc ? a.emetteurZdc.raisonSociale : '', b.emetteurZdc ? b.emetteurZdc.raisonSociale : '');
        } else {
          res = NgbdSortableHeader.compare(a[column], b[column]);
        }
        return direction === 'asc' ? res : -res;
      })));
    }
  }

  getXls() {

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Feuille1');

    worksheet.columns = [
      { header: 'emetteur', key: 'emetteur' },
      { header: 'libelle', key: 'libellee' },
      { header: 'crous', key: 'crous' },
      { header: 'codeEmetteur', key: 'codeEmetteur' },
      { header: 'codeEmetteurEx', key: 'codeEmetteurEx' },
      { header: 'limiteInferieur', key: 'limiteInferieur' },
      { header: 'limiteSuperieur', key: 'limiteSuperieur' },
      { header: 'dateDelivrance', key: 'dateDelivrance' }
    ]

    for (const contrat of this.listeContrats) {

      const data = {
        emetteur:contrat.emetteurZdc.raisonSociale,
        libelle: contrat.libelle,
        crous:contrat.crousPrivilegie,
        codeEmetteur:contrat.codeEmetteur,
        codeEmetteurEx:contrat.codeEmetteur.toString(16),
        limiteInferieur:contrat.numeroLimiteInferieure,
        limiteSuperieur:contrat.numeroLimiteSuperieure,
        dateDelivrance:this.datePipe.transform(contrat.dateDebutContrat, 'dd/MM/YYYY')
      };

      worksheet.addRow(data).commit();

    }

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `contrats.xlsx`);
    });

  }


}

