import {Component, EventEmitter, Input, Output, QueryList, ViewChildren} from '@angular/core';
import {Crous} from "../../../models/crous";
import {map, Observable, startWith} from "rxjs";
import {FormControl} from "@angular/forms";
import {CrousService} from "../../../services/crous.service";
import {NgbModal, NgbModalConfig} from "@ng-bootstrap/ng-bootstrap";
import {ToastTool} from "../../../tools/toast.tool";
import {DialogFormCrousComponent} from "../../crous/dialog-form-crous/dialog-form-crous.component";
import {NgbdSortableHeader, SortEvent} from "../../../tools/ngbd-sortable-header.directive";
import {DemandeAyantDroit} from "../../../models/demande-ayant-droit";
import {DemandeAyantDroitService} from "../../../services/demande-ayant-droit.service";
import {DemandeEtat} from "../../../models/demande-etat";
import {DialogDemandeAyantDroitComponent} from "../dialog-demande-ayant-droit/dialog-demande-ayant-droit.component";

@Component({
  selector: 'app-tableau-demande-ayant-droit',
  templateUrl: './tableau-demande-ayant-droit.component.html',
  styleUrls: ['./tableau-demande-ayant-droit.component.css']
})
export class TableauDemandeAyantDroitComponent {

  @Input() listeDemandeAyantDroit: DemandeAyantDroit[] = [];

// Filtre
  listeDemandeAyantDroit$ : Observable<DemandeAyantDroit[]>;
  filter = new FormControl('', {nonNullable: true});

  search(text: string): DemandeAyantDroit[] {
    return this.listeDemandeAyantDroit.filter((demande) => {
      const term = text.toLowerCase();
      return (
        demande.idDemandeAyantDroit.toString().toLowerCase().includes(term) ||
        demande.etablissementClient.nom?.toLowerCase().includes(term) ||
        demande.dateInsertion.toString().toLowerCase().includes(term) ||
        demande.dateDemande?.toString().toLowerCase().includes(term) ||
        demande.demandeEtat.libelle.toLowerCase().includes(term)
      );
    });
  }
  // fin recherche

  pageSize = 20;
  page = 5;

  loading = false;

  constructor(private demandeAyantDroitService: DemandeAyantDroitService,
              private modalService: NgbModal,
              private config: NgbModalConfig) {
    this.loadListeDemandesAyantDroit();
  }

  ngOnInit(): void {
  }

  loadListeDemandesAyantDroit() {
    this.loading = true;
    const fields = "idDemandeAyantDroit," +
      "etablissementClient.nom," +
      "demandeEtat," +
      "dateDemande," +
      "dateInsertion," +
      "idDemandeAyantDroitEnfant";
    this.demandeAyantDroitService.getListeDemandeAyantDroit(fields).subscribe({
      next: (data) => {
        this.listeDemandeAyantDroit = data;

        this.filter.reset();
        this.listeDemandeAyantDroit$ = this.filter.valueChanges.pipe(
          startWith(''),
          map((text) => this.search(text)),
        );
        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
        for (let error of err.error) {
          let description = error.error_description;
          ToastTool.danger(description);
        }
      }
    })
  }

  voirSupports(demande: DemandeAyantDroit): void {
    const modalRef = this.modalService.open(DialogDemandeAyantDroitComponent, {backdrop: 'static', size: 'lg'});
    modalRef.componentInstance.demandeSelectionnee = demande;
  }


  // tri des colonnes
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    for (const header of this.headers) {
      if (header.sortable !== column) {
        header.direction = '';
      }
    }

    if (direction === '' || column === '') {
      this.loadListeDemandesAyantDroit();
    } else {
      this.listeDemandeAyantDroit$ = this.listeDemandeAyantDroit$.pipe(map(arr => arr.sort((a, b) => {
        let res;
        if (column === 'etablissementClient') {
          res = NgbdSortableHeader.compare(a.etablissementClient ? a.etablissementClient.nom : '', b.etablissementClient ? b.etablissementClient.nom : '');
        } else if (column === 'demandeEtat') {
          res = NgbdSortableHeader.compare(a.demandeEtat ? a.demandeEtat.libelle : '', b.demandeEtat ? b.demandeEtat.libelle : '');
        } else {
          res = NgbdSortableHeader.compare(a[column], b[column]);
        }
        return direction === 'asc' ? res : -res;
      })));
    }
  }

  protected readonly console = console;
}
