import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'blockingStatus'
})
export class BlockingStatusPipe implements PipeTransform {


  transform(value: any, args?: any): any {

    switch (value) {

      case 1:
          return 'Non bloqué';
      case 2:
        return 'Blocage par 3 mots de passe erronés';
      case 3:
        return 'Mise en opposition';
      case 4:
        return 'Compte gelé';
      default:
        return value === undefined ? '' : value;

    }
  }

}
