import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SearchAyantDroit} from "../models/search-ayant-droit";
import {environment} from "../../environments/environment";
import {Card} from "../models/card";
import {CardIzly} from "../models/card-izly";
import {SupportPhysique} from "../models/support-physique";

@Injectable({
  providedIn: 'root'
})
export class CardService {

  constructor(private http: HttpClient) {


  }

  searchIzly(idIzly: number, uid: string) {
    return this.http.get<CardIzly>( environment.apiB4Url +
      `/v1/smartcard/${idIzly}/izly?uid=${uid}`, { withCredentials: true });
  }

  searchBeforeizly(idIzly: number, uid: string) {
    let query = '?a=0';
    if (idIzly) {
      query += '&idZdc=' +idIzly
    }
    if (uid) {
      query += '&uid=' +uid
    }
    return this.http.get<SupportPhysique>( environment.apiB4Url +
      `/v1/smartcard?${query}`, { withCredentials: true });
  }





}
