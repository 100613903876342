import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";
import {SearchAyantDroit} from "../../models/search-ayant-droit";
import {Router} from "@angular/router";
import {SearchAyantDroitService} from "../../common/search/search-ayant-droit.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {RightholderService} from "../../services/rightholder.service";
import {AyantDroitSearchService} from "../../services/ayant-droit-search.service";
import {ToastTool} from "../../tools/toast.tool";
import {environment} from "../../../environments/environment";
import {InfoCarteCrous} from "../../models/infocartecrous";
import {LogsApi} from "../../models/logs-api";
import {SearchApiLogsService} from "../../common/search/search-api-logs.service";
import {LogsApiService} from "../../services/logs-api.service";
import {SearchLogsApi} from "../../models/search-logs-api";

@Component({
  selector: 'app-datatable-logs-api',
  templateUrl: './datatable-logs-api.component.html',
  styleUrls: ['./datatable-logs-api.component.css']
})
export class DatatableLogsApiComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('content', { static: true })
  private modalTemplate: TemplateRef<any>;

  loading = false;
  searchForm: UntypedFormGroup;
  loaded = false;
  page = 1;
  pageSize = 10;
  total: number;
  resultatLoaded = false;

  affichePagination = true;

  serverResult: SearchLogsApi;

  constructor(private router: Router, private searchApiLogsService: SearchApiLogsService,
              private http: HttpClient, private modalService: NgbModal,
              private logsApiService: LogsApiService) {

    if (this.searchApiLogsService.subscription != null) {
      this.searchApiLogsService.subscription.unsubscribe();
    }

    this.searchApiLogsService.subscription = this.searchApiLogsService.formSubmited$.subscribe(
      form => {
        this.searchForm = form;
        this.search(1);
      });
  }



  private buildParams(params: any) {
    const urlSearchParams = new URLSearchParams();
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        urlSearchParams.append(key, params[key]);
      }
    }
    return urlSearchParams.toString();
  }

  ngOnInit() {
  }


  ngAfterViewInit(): void {
    this.loaded = true;
  }

  ngOnDestroy(): void {
    this.loaded = false;
  }

  search(page: any) {
    this.serverResult = {data:[],recordsTotal:0,recordsFiltered:0};
    this.loading = true;

      this.searchForm.get('start').setValue((page - 1) * 10);
      this.logsApiService.search(this.searchForm).subscribe(res => {
        this.serverResult = res;
        this.total = res.recordsTotal;
        this.resultatLoaded = true;
        this.loading = false;
      });


  }
}

