import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ApiBoursier} from "../models/api-boursier";
import {environment} from "../../environments/environment";
import {Annonce} from "../models/annonce";
import {UntypedFormGroup} from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class AnnonceService {

  constructor(private http: HttpClient) {
  }

  getAnnonces() {
    return this.http.get<Array<Annonce>>(environment.apiB4Url + '/v1/annonces', { withCredentials: true });
  }

  get(id: number) {
    return this.http.get<Annonce>(environment.apiB4Url + '/v1/annonces/' + id, { withCredentials: true });
  }

  post(form: UntypedFormGroup) {
    return this.http.post(environment.apiB4Url + '/v1/annonces', form,{ withCredentials: true });
  }

  put(id: number, form: UntypedFormGroup) {
    return this.http.put(environment.apiB4Url + '/v1/annonces/' + id, form,{ withCredentials: true });
  }

  getActives() {
    return this.http.get<Array<Annonce>>(environment.apiB4Url + '/v1/annonces?a=true', { withCredentials: true });
  }
}
