<div class="container-fluid mt-3">
  <h4>{{action | titlecase}} un utilisateur</h4>

  <form (submit)="save()" [formGroup]="form" *ngIf="form" class="mt-4" style="margin-left: 20px; margin-right: 20px">

    <div class="row mb-3">
          <label for="crous" class="col-md-2 col-form-label required">Crous principal de l'utilisateur</label>
          <div class="col-md-3" *ngIf="crousList">
            <select class="form-select" id="crous" name="crous" formControlName="crous" [compareWith]="crousEquality" [class.is-invalid] = "form.get('crous')?.invalid && (form.get('crous')?.touched || form.get('crous')?.dirty)">
              <option  [ngValue]="{idCrous:0}">Choisissez un Crous</option>
              <option  *ngFor="let c of crousList" [ngValue]="c">{{c.libelle}}</option>
            </select>
            <div class="invalid-feedback" *ngIf="form.get('crous')?.touched && form.get('crous')?.hasError('required')">Un crous est requis</div>
          </div>
          <div class="col-md-1">&nbsp;</div>

          <div class="col-md-3 form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="visibleParAdminNational" [value]="1" formControlName="visibleParAdminNational" name="visibleParAdminNational">
            <label for="visibleParAdminNational"  class="form-check-label">Visible seulement par admins nationaux</label>
          </div>
    </div>

    <div class="row mb-3">
          <label for="prenom"  class="col-md-2 col-form-label required">Prénom</label>
          <div class="col-md-3">
            <input type="text" autocomplete="off"  class="form-control" id="prenom" name="prenom"
                   formControlName="prenom" [class.is-invalid] = "form.get('prenom')?.invalid && (form.get('prenom')?.touched || form.get('prenom')?.dirty)">
            <div class="invalid-feedback" *ngIf="form.get('prenom')?.touched && form.get('prenom')?.hasError('required')">Prénom est requis</div>
            <div class="invalid-feedback" *ngIf="form.get('prenom')?.touched && form.get('prenom')?.hasError('maxlength')">Maximum 255 caractères</div>
            <div class="invalid-feedback" *ngIf="form.get('prenom')?.touched && form.get('prenom')?.hasError('minlength')">Minimum 2 caractères</div>
          </div>
          <div class="col-md-1">&nbsp;</div>
          <label for="nom"  class="col-md-2 col-form-label required">Nom</label>
          <div class="col-md-3">
            <input type="text" autocomplete="off"  class="form-control" id="nom" name="nom"
                   formControlName="nom" [class.is-invalid] = "form.get('nom')?.invalid && (form.get('nom')?.touched || form.get('nom')?.dirty)">
            <div class="invalid-feedback" *ngIf="form.get('nom')?.touched && form.get('nom')?.hasError('required')">Nom est requis</div>
            <div class="invalid-feedback" *ngIf="form.get('nom')?.touched && form.get('nom')?.hasError('maxlength')">Maximum 255 caractères</div>
            <div class="invalid-feedback" *ngIf="form.get('nom')?.touched && form.get('nom')?.hasError('minlength')">Minimum 2 caractères</div>
          </div>
    </div>

    <div class="row mb-3">
          <label for="courriel"  class="col-md-2 col-form-label required">Courriel</label>
          <div class="col-md-3">
            <input type="text" autocomplete="off"  class="form-control" id="courriel" name="courriel"
                   formControlName="courriel" [class.is-invalid] = "form.get('courriel')?.invalid && (form.get('courriel')?.touched || form.get('courriel')?.dirty)">
            <div class="invalid-feedback" *ngIf="form.get('courriel')?.touched && form.get('courriel')?.hasError('required')">Courriel est requis</div>
            <div class="invalid-feedback" *ngIf="form.get('courriel')?.touched && form.get('courriel')?.hasError('maxlength')">Maximum 255 caractères</div>
            <div class="invalid-feedback" *ngIf="form.get('courriel')?.touched && form.get('courriel')?.hasError('minlength')">Minimum 2 caractères</div>
          </div>
         <div class="col-1">&nbsp;</div>
          <label for="identifiant"  class="col-md-2 col-form-label required">Identifiant</label>
          <div class="col-md-3">
            <input type="text" autocomplete="off"  class="form-control" id="identifiant" name="identifiant"
                   formControlName="identifiant" [class.is-invalid] = "form.get('identifiant')?.invalid && (form.get('identifiant')?.touched || form.get('identifiant')?.dirty)">
            <div class="invalid-feedback" *ngIf="form.get('identifiant')?.touched && form.get('identifiant')?.hasError('required')">Identifiant est requis</div>
            <div class="invalid-feedback" *ngIf="form.get('identifiant')?.touched && form.get('identifiant')?.hasError('maxlength')">Maximum 255 caractères</div>
            <div class="invalid-feedback" *ngIf="form.get('identifiant')?.touched && form.get('identifiant')?.hasError('minlength')">Minimum 2 caractères</div>
          </div>
    </div>


    <div class="row mb-3">
          <label for="crous" class="col-md-2 col-form-label">Type d'utilisateur</label>
          <div class="col-md-3" *ngIf="crousList">
            <select class="form-select" id="type" name="type" formControlName="typeUser" (change)="resetSelectionLists()">
              <option  [ngValue]="1">Utilisateur Crous (voit tous les AD des Crous sélectionnés ci dessous)</option>
              <option  [ngValue]="2">Utilisateur Établissement (voit tous les AD des établissements sélectionnés ci dessous)</option>
            </select>
          </div>
        <div class="col-1">&nbsp;</div>
          <label for="dateFinValidite" class="col-md-2 col-form-label required">Date&nbsp;fin&nbsp;validité</label>
          <div class="col-md-3">
            <div class="input-group ">
              <input type="text" id="dateFinValidite" name="dateFinValidite"  placeholder="jj/mm/aaaa" class="col-md-2 form-control"
                     formControlName="dateFinValidite" ngbDatepicker #d="ngbDatepicker" [class.is-invalid] = "form.get('dateFinValidite')?.invalid && (form.get('dateFinValidite')?.touched || form.get('dateFinValidite')?.dirty)">
              <button class="btn btn-outline-secondary bi bi-calendar3" (click)="d.toggle()" type="button"></button>
              <div class="invalid-feedback" *ngIf="form.get('dateFinValidite')?.touched && form.get('dateFinValidite')?.hasError('required')">Date de fin de validité est requis</div>
              <div class="invalid-feedback" *ngIf="form.get('dateFinValidite')?.touched && form.get('dateFinValidite')?.hasError('dateInFuture')">Date de fin de validité doit être dans le futur</div>
              <div class="invalid-feedback" *ngIf="form.get('dateFinValidite')?.touched && form.get('dateFinValidite')?.hasError('dateInMoreThenOneYear')">Date de fin de validité doit être dans moins d'un an</div>
            </div>

          </div>

    </div>

    <div class="row">
      <div class="col-md">
        <div class="form-group row">
          <label for="droitList" class="col-md-2 col-form-label required">Droits</label>

          <div class="col-md-9" *ngIf="droitList">
            <ng-multiselect-dropdown  id="droitList" formControlName="droitList" placeholder="Sélectionnez un ou plusieurs droits"
                                      [class.is-invalid] = "form.get('droitList')?.touched && form.get('droitList').hasError('required')"
                                      [settings]="dropdownSettingsDroit" [data]="droitList"></ng-multiselect-dropdown>
            <div class="invalid-feedback" *ngIf="form.get('droitList')?.touched && form.get('droitList').hasError('required')">Sélectionnez au moins un droit</div>

          </div>
        </div>
      </div>
    </div>


    <div class="row" *ngIf="droitCrousList && typeUser == 1">
      <div class="col-md">
        <div class="form-group row">
          <label for="droitCrousList" class="col-md-2 col-form-label required">Gestion des <b>ayant-droits</b> pour le <b>Crous</b> de</label>

          <div class="col-md-9">
            <ng-multiselect-dropdown  id="droitCrousList" formControlName="droitCrousList" placeholder="Sélectionnez un ou plusieurs crous"
                                      [class.is-invalid] = "form.get('droitCrousList')?.touched && form.hasError('requireOneOfTwoList')"
                                      [settings]="dropdownSettingsCrous" [data]="droitCrousList"></ng-multiselect-dropdown>
            <div class="invalid-feedback" *ngIf="form.get('droitCrousList')?.touched && form.hasError('requireOneOfTwoList')">Sélectionnez au moins un Crous</div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="etablissementClientList && typeUser == 2">
      <div class="col-md">
        <div class="form-group row">
          <label for="droitEtablissementClientList" class="col-md-2 col-form-label required">Gestion des <b>ayant-droits</b> pour <b>l'établissement</b></label>

          <div class="col-md-9" *ngIf="droitCrousList">
            <ng-multiselect-dropdown  id="droitEtablissementClientList" formControlName="droitEtablissementClientList" placeholder="Sélectionnez un ou plusieurs crous"
                                      [class.is-invalid] = "form.get('droitEtablissementClientList')?.touched && form.hasError('requireOneOfTwoList')"
                                      [settings]="dropdownSettingsEtablissementclient" [data]="etablissementClientList"></ng-multiselect-dropdown>
            <div class="invalid-feedback" *ngIf="form.get('droitEtablissementClientList')?.touched && form.hasError('requireOneOfTwoList')">Sélectionnez au moins un établissement</div>
          </div>
        </div>
      </div>
    </div>


    <div class="row mt-5" *ngIf="crousDelegationList && typeUser == 1 && droitAdminNational">
      <div class="col-md">
        <div class="form-group row">
          <label for="crousDelegationList" class="col-md-2 col-form-label">Gestion des <b>utilisateurs</b> pour le Crous de</label>

          <div class="col-md-9" *ngIf="crousDelegationList">
            <ng-multiselect-dropdown  id="crousDelegationList" formControlName="crousDelegationList" placeholder="Sélectionnez un ou plusieurs crous"
                                      [settings]="dropdownSettingsCrous" [data]="crousDelegationList"></ng-multiselect-dropdown>
          </div>
        </div>
      </div>
    </div>

    <div class="row">&nbsp;</div>
    <div class="row">
      <div class="col-md-11 d-md-flex justify-content-md-end">
        <button type="submit" class="btn btn-primary">Enregistrer</button>
      </div>
    </div>

  </form>


</div>
