import { Component } from '@angular/core';
import {ExcelError} from "../../models/excel-error";
import {ExcelAd} from "../../models/excel-ad";
import * as ExcelJS from "exceljs";
import {HttpEventType, HttpHeaders, HttpResponse} from "@angular/common/http";
import {ToastTool} from "../../tools/toast.tool";
import {CheckIneService} from "../../services/check-ine.service";
import {CrousService} from "../../services/crous.service";
import {ImportAyantDroitService} from "../../services/import-ayant-droit.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ChangeIdentifiantClient} from "../../models/change-identifiant-client";

@Component({
  selector: 'app-change-identifiant-client',
  templateUrl: './change-identifiant-client.component.html',
  styleUrls: ['./change-identifiant-client.component.css']
})
export class ChangeIdentifiantClientComponent {
  loading = false;
  file: File;
  formErreur = '';
  rowQuantity = 0;
  progress: number = 0;

  errorImport  = new Array<string>;
  errorImportExtrait  = new Array<string>;

  excelErrorSize=0
  page = 1;
  pageSize = 5;

  response: ChangeIdentifiantClient

  constructor(private importAdService: ImportAyantDroitService) {
  }


  onFileSelected(event: Event) {
    this.response = null;
    this.page=1;
    this.errorImport = [];
    this.errorImportExtrait = [];
    this.formErreur='';
    this.rowQuantity=0;
    this.excelErrorSize=0;
    this.file = event.target['files'][0];
    if (!this.file || this.file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      this.formErreur =  'Le fichier excel doit être de type <b>.xlsx</b>';
      return;
    }

    this.readFile();

  }

  readFile() {
    if (!this.file) {
      this.formErreur = 'Veuillez sélectionner un fichier';
      return;
    }

    this.loading = true;

    const wb = new ExcelJS.Workbook();

    var reader = new FileReader();
    reader.onload = (e) => {

      wb.xlsx.load(e.target.result as ArrayBuffer).then(workbook => {

        const idSheet = workbook.worksheets[0].id;

        const colonnes = workbook.getWorksheet(idSheet).getRow(1);
        const nbColonnes = colonnes.cellCount

        if (nbColonnes !=3) {
          this.formErreur = `Le fichier n'est pas au format attendu, il contient seulement ${nbColonnes} colonne${nbColonnes > 1 ? 's' : ''}`;
          this.loading = false;
          return;
        }
        workbook.getWorksheet(idSheet).eachRow((row, rowIndex) => {
          this.rowQuantity++;
        });

        this.loading = false;
      })
    };
    reader.readAsArrayBuffer(this.file);
  }

  errorNext() {
    this.errorImportExtrait = this.errorImport.slice(
      (this.page - 1) * this.pageSize,
      (this.page - 1) * this.pageSize + this.pageSize,
    );
  }



  submit() {

    let headers = new HttpHeaders();
    const formData = new FormData();
    formData.append('file', this.file, this.file.name);

    this.loading = true;

    this.importAdService.uploadChangeIdentifiant(formData).subscribe({
      next : event=>{
        if (event.type === HttpEventType.UploadProgress) {
          this.progress = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
          this.response = event.body as ChangeIdentifiantClient;
          this.errorImport = this.response.kos;
          this.excelErrorSize = this.errorImport.length;
          this.errorNext();
          this.resetForm();
        }
        this.loading = false;
      },
      error : err => {
        for (let error of err.error) {
          let description = error.error_description;
          ToastTool.danger(description);
          this.resetForm();
          this.loading = false;
        }
      }
    });
    this.file = undefined;
  }

  private resetForm() {
    this.file = null;
  }


}
