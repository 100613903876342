import {inject, Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError, of} from 'rxjs';
import {tap, catchError} from 'rxjs/operators';
import {ToastTool} from '../tools/toast.tool';
import {OidcSecurityService} from "angular-auth-oidc-client";
import {Route, Router} from "@angular/router";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(evt => {
        // console.log('evt--->>>', event);
      }),
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse && err.status === 401) {
          this.router.navigate(['connect'])
        }
        return throwError(err);
      }));
  }
}
