import { Injectable } from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";

@Injectable()
export class CustomValidators {

  constructor() { }


  static mailFormat(control: FormControl) {

    const EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
    if (control.value !== '' && (control.value.length <= 5 || !EMAIL_REGEXP.test(control.value))) {
      return {'incorrectMailFormat': true };
    }
    return null;
  }


}

export function requireOneOfTwoList(firstControlName: any, secondControlName: any) {
  return (formGroup: FormGroup) => {
    const firstControl = formGroup.controls[firstControlName];
    const secondControl = formGroup.controls[secondControlName];

    if (firstControl.value.length == 0 && secondControl.value.length==0) {
      return { requireOneOfTwoList: true };
    } else {
      return null;
    }
  };
}

export function dateInFutureButLessThanOneYear(nbAnnee: number) {
  return (control: FormControl) => {
    if (control.value == null || control.value.length == 0) {
      return {required: true}
    }
    const dateOnly = control.value.substring(0, 10);
    const inputDate = new Date(dateOnly);
    const currentDate = new Date();
    const oneYearFromNow = new Date();
    oneYearFromNow.setDate(oneYearFromNow.getDate() + 30);
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + nbAnnee);

    if (inputDate < currentDate) {
      return { dateInFuture: true };
    } else if (inputDate > oneYearFromNow) {
      return { dateInMoreThenOneYear: true };
    } else {
      return null;
    }
  };
}

export function arrayNotEmptyValidator() {
  return (control: FormControl) => {
    const arrayValue = control.value;
    if (Array.isArray(arrayValue) && arrayValue.length > 0) {
      return null;  // Validation réussie, aucune erreur
    } else {
      return { required: true };  // Validation échouée, erreur 'arrayNotEmpty'
    }
  };
}

export function crousValidator() {
  return (control: FormControl) => {
    const crousValue = control.value;
    if (crousValue !== null && crousValue.idCrous > 0) {
      return null;  // Validation réussie, aucune erreur
    } else {
      return { required: true };  // Validation échouée, erreur 'crousInvalid'
    }
  };
}
