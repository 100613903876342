import {Crous} from './crous';
import {EtablissementClient} from './etablissement-client';

export class CrousParts {


  constructor() {
    this.idClient = 0;
    this.crous = new Crous();
    this.etablissementClient = new EtablissementClient();
    this.ines = '';
    this.idPve = 0;
    this.numCvec = '';
    this.crousCvec = '';
    this.origine = '';
  }

  idClient: number;
  crous: Crous;
  etablissementClient: EtablissementClient;
  ines: string;
  idPve: number;
  numCvec: string;
  crousCvec: string;
  origine:string;

}
